<template>
  <div class="setExchangeRate">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>代币设置</el-breadcrumb-item>
      <el-breadcrumb-item>比例设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="150px">
        <el-form-item
          label="代币名称"
          prop="life_name"
          style="margin-left: -12px"
        >
          <el-input
            v-model="basicInfoForm.life_name"
            placeholder="请输入"
            class="ipt"
          ></el-input>
        </el-form-item>
        <h2 class="color-333 mg-b-20" style="margin-left: 66px">兑换设置</h2>
        <el-form-item label="兑换档位一">
          <div class="flex align-center">
            <p>
              消耗<el-input
                v-model="basicInfoForm.consume1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">代币</template></el-input
              >
            </p>
            <p>
              兑换<el-input
                v-model="basicInfoForm.score1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">积分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="兑换档位二">
          <div class="flex align-center">
            <p>
              消耗<el-input
                v-model="basicInfoForm.consume2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">代币</template></el-input
              >
            </p>
            <p>
              兑换<el-input
                v-model="basicInfoForm.score2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">积分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="兑换档位三">
          <div class="flex align-center">
            <p>
              消耗<el-input
                v-model="basicInfoForm.consume3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">代币</template></el-input
              >
            </p>
            <p>
              兑换<el-input
                v-model="basicInfoForm.score3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">积分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="兑换档位四">
          <div class="flex align-center">
            <p>
              消耗<el-input
                v-model="basicInfoForm.consume4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">代币</template></el-input
              >
            </p>
            <p>
              兑换<el-input
                v-model="basicInfoForm.score4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">积分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="兑换档位五">
          <div class="flex align-center">
            <p>
              消耗<el-input
                v-model="basicInfoForm.consume5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">代币</template></el-input
              >
            </p>
            <p>
              兑换<el-input
                v-model="basicInfoForm.score5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">积分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="兑换档位六">
          <div class="flex align-center">
            <p>
              消耗<el-input
                v-model="basicInfoForm.consume6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">代币</template></el-input
              >
            </p>
            <p>
              兑换<el-input
                v-model="basicInfoForm.score6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">积分</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="是否开启兑换渠道">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit">保存</el-button>
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "setExchangeRate",
  data() {
    return {
      imageUrl: "",
      isShow: false,
      basicInfoForm: {
        life_name: "",
        consume1: "",
        consume2: "",
        consume3: "",
        consume4: "",
        consume5: "",
        consume6: "",
        score1: "",
        score2: "",
        score3: "",
        score4: "",
        score5: "",
        score6: "",
        status: 2,
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      data: this.basicInfoForm,
      url: ` https://time.api.boyalife.cn/v1/cms/LifeSetting/1`,
    })
      .then((res) => {
        let a = res.data.result;
        for (let i in a) {
          if (i != "life_name") {
            this.basicInfoForm[i] = Number(a[i]);
          } else {
            this.basicInfoForm[i] = a[i];
          }
        }
        console.log(this.basicInfoForm);
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    submit() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/LifeSetting/1`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.setExchangeRate {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
</style>
