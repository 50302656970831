<template>
  <div class="addCoupon">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/fullReductionActivity' }"
        >满减/满赠活动</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}满减/满赠</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="form" label-width="150px" ref="form" :rules="rules">

    <div class="boxLayout">
      <h3 class="title-t mg-b-20">基础信息</h3>
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="start_time">
          <el-date-picker
                v-model="time"
                type="datetimerange"
                align="right"
                unlink-panels
                @change="getTimes"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
        </el-form-item>
        <el-form-item label="活动状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio label="0">上线</el-radio>
            <el-radio label="1">下线</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="优惠券规则"
          prop="is_loop"
        >
        <el-radio-group v-model="form.is_loop">
            <el-radio label="0">阶梯优惠（例：满1件减10元）</el-radio>
            <el-radio label="1">循环优惠（例：每满1件减10元）</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
        >
        <el-table :data="form.rules" border :key="itemKey">
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="门槛">
              <template slot-scope="scope">
                满<el-input style="width: 50px;margin: 0 10px;" v-model="scope.row.num"></el-input>件
              </template>
            </el-table-column>
            <el-table-column prop="name" label="折扣/减价" width="200px">
              <template slot-scope="scope">
                <div class="flex">
                  <el-radio-group v-model="scope.row.type" style="flex: 1;">
                    <el-radio label="0">折扣</el-radio>
                    <el-radio label="1">减价</el-radio>
                  </el-radio-group>
                  <div>
                    <p><el-input style="width: 50px;margin: 0 10px;" v-model="scope.row.discount"></el-input>
                    <span v-if="scope.row.type == 0">折</span><span v-if="scope.row.type == 1">元</span></p>

                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goods_name" label="送赠品">
              <template slot-scope="scope">
                    <el-tooltip class="item" :content="scope.row.goods_name " placement="top">
                      <p class="oneline">{{ scope.row.goods_name }}</p>  
                    </el-tooltip>  
                <el-button size="mini" type="primary" @click="showCommidity(scope.$index)">选择赠品</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="是否包邮">
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.freight_free" style="flex: 1;">
                  <el-radio label="0">是</el-radio>
                  <el-radio label="1">否</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="送优惠券">
              <template slot-scope="scope">
                  <p>{{ scope.row.coupon_name }}</p>
                <el-button size="mini" type="primary" @click="showCoupon(scope.$index)">选择优惠券</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="送积分">
              <template slot-scope="scope">
                送<el-input style="width: 50px;margin: 0 10px;" v-model="scope.row.score"></el-input>积分
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="scope.$index != 0"
                  @click="form.rules.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-button type="text" @click="addNext" v-if="form.is_loop == 0">添加下一级优惠</el-button>
        </el-form-item>
    </div>
    <div class="boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">商品设置</h3>
      <el-form-item label="活动商品" prop="goods_type">
        <el-radio-group v-model="form.goods_type">
          <el-radio label="0">全部商品参加</el-radio>
          <el-radio label="1">指定商品参加</el-radio>
          <el-radio label="2">指定商品不参加</el-radio>
        </el-radio-group>
        <div>
   
          <el-table :data="commidityList" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="commidityList.splice(scope.$index, 1)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="mg-t-20" v-if="form.goods_type != 0">
          <el-button type="primary" @click="selectCommdityDialogVisible = true">添加商品</el-button>
        </div>
      </el-form-item>
    </div>
    <div class="boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">其他设置</h3>
      <el-form-item label="限购">
       每人限购数量
        <el-input
          v-model="form.buy_limit"
          class="ipt mg-l-20"
          placeholder="请输入"
          ><template slot="prepend">件</template></el-input
        >
        <span style="font-size: 12px;color: #666;">*为空默认不限制，每人每种商品前X件享受折扣，超出后无法通过活动购买</span>
      </el-form-item>
      <el-form-item label="下单同享受">
        <el-radio-group v-model="form.use_other">
            <el-radio label="0">不允许叠加优惠</el-radio>
            <el-radio label="1">允许叠加积分抵现</el-radio>
            <el-radio label="2">允许叠加优惠券</el-radio>
            <!-- <el-radio label="3">允许叠加会员折扣</el-radio> -->
          </el-radio-group>
      </el-form-item>
      <el-form-item label="限购">
        <el-checkbox v-model="form.preheat"
          >提前</el-checkbox
        >
        <el-input
          v-model="form.preheat_num"
          class="ipt mg-l-20"
          placeholder="请输入"
          ><template slot="prepend">小时</template></el-input
        >
      </el-form-item>
      <el-form-item label="客户限制" prop="user_limit">
        <el-radio-group v-model="form.user_limit">
            <el-radio label="0">不限制</el-radio>
            <el-radio label="1">指定客户参加</el-radio>
          </el-radio-group>
          <el-button
            class="mg-l-20"
            @click="selectCustomerDialogVisible = true"
            v-if="form.user_limit == 1"
            type="text"
          >
            + 选择客户</el-button
          >
        </el-form-item>
        <el-form-item label="选择的客户" v-if="customerList.length > 0">
          <el-table :data="customerList" border>
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="nick_name" label="用户名"></el-table-column>
          </el-table>
        </el-form-item>
    </div>
  </el-form>

    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <selectCommdity
      ref="freeCommodity"
      :dialogVisible="selectCommdityDialogVisible2"
      @confirmCommidity="confirmCommidity2"
      @closeDialog="closeDialog2"
      :goods="goodsList"
    />
    <selectCoupon
      :coupons="couponList"
      ref="couponref"
      :dialogVisible="selectCouponDialogVisible"
      @confirmCoupon="confirmCoupon"
      @closeDialog="closeDialogCoupon"
    />
    <selectCustomer
      :dialogVisible="selectCustomerDialogVisible"
      @confirmCustomer="confirmCustomer"
      @cancel="closeSelectCustomer"
      :customer="customerList"
    />
    <selectCommdity
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      @closeDialog="closeDialog"
      :goods="commidityList"
    />

    <el-dialog title="选择规格" :visible.sync="specsDialog">
      <el-table :data="exampleList" border>
        <el-table-column
          v-for="(item, i) in headerList"
          :key="i"
          :prop="item"
          :label="item"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template slot-scope="scope">
            <el-button type="text" @click="choose(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import selectCustomer from "../../../components/selectCustomer.vue";
import selectCommdity from "../../../components/selectCommdity.vue";
import selectCoupon from '../../../components/selectCoupon.vue'
import pagination from "../../../components/pagination.vue";
export default {
  name: "addCoupon",
  components: { selectCustomer, selectCommdity, pagination ,selectCoupon},
  data() {
    return {
      time: [],
      pageType: "",
      goodsList: [],
      exampleList: [],
      labelDialogVisible: false,
      selectCouponDialogVisible: false,
      selectCustomerDialogVisible: false,
      selectCommdityDialogVisible: false,
      selectCommdityDialogVisible2: false,
      dialogTableVisible: false,
      ruleList: [
        {
          num: "",
          type: "",
          discount: "",
          goods_uuid: "",
          spec_uuid: "",
          freight_free: "",
          coupon_uuid: "",
          score: ""
        }
      ],
      headerList: [],
      pickCommdity: [],
      specsDialog: false,
      form: {
        name: "",
        start_time: "",
        end_time: "",
        status: "0",
        is_loop: "0",
        buy_limit: "",
        user_limit: "0",
        use_other: "2",
        goods_type: "0",
        goods: [],
        preheat: "",
        preheat_num: "",
        users: [],
        rules: [{
          num: "",
          type: "",
          discount: "",
          goods_uuid: "",
          goods_name: "",
          spec_uuid: "",
          coupon_name: "",
          freight_free: "",
          coupon_uuid: "",
          score: ""
        }]
      },
      tips: false,
      rules: {
        name: { required: true, message: "请输入", trigger: "blur" },
        status: { required: true, message: "请选择", trigger: "change" },
        rules: { required: true, message: "请选择", trigger: "change" },
        start_time: { required: true, message: "请选择", trigger: "change" },
        is_loop: { required: true, message: "请选择", trigger: "change" }, 
        goods_type: { required: true, message: "请选择", trigger: "change" },
        user_limit: { required: true, message: "请选择", trigger: "change" },
      },
      currentLine: '',
      commidityList: [],
      customerList: [],
      tags: [],
      itemKey: '',
      couponList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    showCommidity (index) {
      this.currentLine = index
      this.$refs.freeCommodity.$refs.multipleTable.clearSelection()

      // let taht = this
      // if (!this.tips) {
      //   this.$alert('因为要选择商品规格，所以一次选一个商品就好', '提示', {
      //     confirmButtonText: '了解',
      //     callback: action => {
            this.selectCommdityDialogVisible2 = true 
            // this.tips = true

      //     }
      //   })
      // } else {
      //   taht.selectCommdityDialogVisible2 = true 
      // }
    },
    showCoupon (index) {
      this.selectCouponDialogVisible = true
      this.$refs.couponref.$refs.multipleTable.clearSelection()

      this.currentLine = index
    },
    addNext () {
      let obj = {
        num: "",
        type: "0",
        discount: "",
        goods_uuid: "",
        goods_name: "",
        spec_uuid: "",
        coupon_name: "",
        freight_free: "",
        coupon_uuid: "",
        score: ""
      }
      this.form.rules.push(obj)
    },
    changeCondition (v) {
      if (v == 1) {
        this.form.condition_num = ''
      } else if (v == 3) {
        this.form.condition_money = ''
      } else {
        this.form.condition_num = ''
        this.form.condition_money = ''
      }
    },
    closeTags(i) {
      this.tags.splice(i, 1);
    },
    selectValidity(v) {
      if (v == 2) {
        this.form.validity_start_date = "";
        this.form.validity_end_date = "";
        this.dataTime = "";
      }
      if (v == 3) {
        this.form.validity_day = "";
      }
    },
    getInfo() {
      console.log(222)

      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.form,
        url: ` https://time.api.boyalife.cn/v1/cms/Manjian/${this.$route.query.uuid}`,
      })
        .then((res) => {

          let params = res.data.result;
          for (let i in params) {
            this.form[i] = JSON.parse(JSON.stringify(params[i]));
          }
         
          this.time = [this.form.start_time, this.form.end_time]
          this.form.preheat = this.form.preheat == 1 ? true : false
          console.log(this.form.goods)
          this.commidityList = this.form.goods
          this.form.goods.map(item => {
            item.uuid = item.goods_uuid
          })
          this.form.rules.map(item => {
            item.goods_name = item.spec.name
            item.coupon_name = item.coupon.name

            // this.goodsList.push(item.spec)
          // this.couponList.push(item.coupon)
          })
       

          // this.goodsList = this.form.rules.spec
          console.log(this.commidityList)
          console.log(this.commidityList)


        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    confirmCoupon(row) {
      console.log(row)
      this.couponList = row;
      this.selectCouponDialogVisible = false;

      this.$set(this.form.rules[this.currentLine], 'coupon_uuid', row[0].uuid)
      this.$set(this.form.rules[this.currentLine], 'coupon_name', row[0].name)
      console.log(this.form.rules)

    },
    closeDialogCoupon() {
      this.selectCouponDialogVisible = false;
    },
    // 选择时间
    getTimes(v) {
      this.form.start_time = v[0];
      this.form.end_time = v[1];
    },
    showPeopleDialog() {
      this.selectPeopleDialogVisible = true;
      console.log(this.selectPeopleDialogVisible);
    },
    confirmPeople(v) {
      console.log(v);
      this.selectPeopleDialogVisible = false;
      this.tags = v;
    },
    closeTagDialog() {
      this.selectPeopleDialogVisible = false;
    },
    closeSelectCustomer(v) {
      this.selectCustomerDialogVisible = v;
    },
    confirmCustomer(v) {
      this.selectCustomerDialogVisible = false;
      this.customerList = v;
    },
    confirmCommidity(v) {
      this.selectCommdityDialogVisible = false;
      this.commidityList = v;
    },
    closeDialog(v) {
      this.selectCommdityDialogVisible = v;
    },
    choose(row) {
      console.log(row)
      this.form.rules[this.currentLine].spec_uuid = row.uuid

      this.specsDialog = false
    },
    confirmCommidity2(v) {
      console.log(v)
      this.exampleList = []
      v[0].specs.map((item) => {
        let obj = {};
        for (let i in item.specs) {
          obj[i] = item.specs[i];
        }
        obj.stock = item.stock;
        obj.uuid = item.uuid;
        this.exampleList.push(obj);
      });
      this.headerList = v[0].attrkey.map((item) => item.name);
      this.selectCommdityDialogVisible2 = false;
      this.$set(this.form.rules[this.currentLine], 'goods_uuid', v[0].uuid)
      this.$set(this.form.rules[this.currentLine], 'goods_name', v[0].name)


      this.specsDialog = true 
      
      this.itemKey = Math.random()
    },
    closeDialog2(v) {
      this.selectCommdityDialogVisible2 = v;
    },
    // handleSizeChange (v) {
    //   this.tableData.per_page = v
    //   this.getCouponList()
    // },
    // handleCurrentChange (v) {
    //   this.tableData.current_page = v
    //   this.getCouponList()
    // },
    submit(formName) {

      // if (this.goodsList.length > 0) {
      //   this.goodsList.map((item,index) => {
      //     this.form.rules[index].goods_uuid = item.uuid
      //     item.specs.map(jtem => {
      //       this.form.rules[index]
      //     })
      //   })
        
      // }
      if (this.form.rules.length > 0) {
        this.form.rules.map(item => {
          if (item.type == 0 ) {
            if (Number(item.discount) == 0 || item.discount == '') {
              item.discount = 10
            }
          }
          delete item.goods_name
          delete item.coupon_name
          delete item.coupon
          delete item.manjian_uuid
          delete item.spec
        })
      }
      if (this.customerList.length > 0) {
        this.form.user = this.customerList.map((item) => item.uuid);
      }
      if (this.tags.length > 0) {
        if (this.form.release_type == 5) {
          this.form.wordgroup = this.tags.map((item) => item.uuid);
        } else {
          this.form.user_group = this.tags.map((item) => item.uuid);
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.is_condition == 1 && this.form.condition_money == "") {
            this.$message.warning("有门槛，订单价格内容必填");
            return false;
          }
          if (this.form.is_condition == 3 && this.form.condition_num == "") {
            this.$message.warning("有门槛，订单数量内容必填");
            return false;
          }
          if (this.form.validity == 2 && this.form.validity_day == "") {
            this.$message.warning("请填写领取后多少天有效");
            return false;
          }
          if (this.form.validity == 3 && this.form.validity_end_date == "") {
            this.$message.warning("请选择有效期的固定时间");
            return false;
          }
          if (this.form.type == 1 && this.form.type_money == "") {
            this.$message.warning("优惠券类型为现金券，请填写可抵扣多少元");
            return false;
          }
          if (this.form.type == 2 && this.form.type_discount == "") {
            this.$message.warning("优惠券类型为折扣券，请填写可享受多少折扣");
            return false;
          }
          if (this.commidityList.length > 0) {
            this.form.goods = this.commidityList.map((item) => item.uuid);
          }
          if (this.form.goods_type != 0) {
            if (this.commidityList.length == 0) {
              this.$message.warning("请选择商品");
              return false;
            }
          }

          if (Object.keys(this.$route.query).length > 0) {
            delete this.form.create_time;
            delete this.form.delete_time;
            delete this.form.update_time;
            delete this.form.uuid;

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://time.api.boyalife.cn/v1/cms/Manjian/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://time.api.boyalife.cn/v1/cms/Manjian`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addCoupon {
  margin-bottom: 80px;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
.el-tabel .el-radio {
  margin-right: 0;
  flex: 1;
}
.oneline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
