// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import ElementUI from 'element-ui'
import { ElMessage } from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css'
import 'element-ui/lib/theme-chalk/index.css'
import VueClipBoard from 'vue-clipboard2'
import { Message } from 'element-ui'
import App from './App'
import router from './router'
import storage from 'store'
import api from '@/api'
import store from './store'
import axios from 'axios'
import SkuForm from 'vue-sku-form'

Vue.prototype.storage = storage
Vue.prototype.axios = axios

Vue.prototype.$api = api
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
let options = {
  fullscreenEl: true, //控制是否显示右上角全屏按钮
  arrowEl: true,
  arrowKeys: true,
  closeEl: true, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: true, //控制是否显示放大缩小按钮
  counterEl: false, //控制是否显示左上角图片数量按钮
  tapToToggleControls: false, //点击应切换控件的可见性
  clickToCloseNonZoomable: true, //点击图片应关闭图库，仅当图像小于视口的大小时
  indexIndicatorSep: ' / '//图片数量的分隔符
}
Vue.use(preview, options)
Vue.use(preview)
Vue.use(SkuForm)


// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.use(VueClipBoard)
Vue.config.productionTip = false

/* eslint-disable no-new */
let that = this
axios.interceptors.response.use(res => {
  console.log(res)
  let code = res.data.code
  if (code == 401) {
    Message.error(error.response.data.error.message)
    storage.clearAll()
    setTimeout(() => {
      this.$router.replace({path: '/login'})
    })
    return false
  }
  return res
}, error => {

  let code = error.response.data.code
  if (code == 401) {
    Message.error(error.response.data.error.message)
    storage.clearAll()
    setTimeout(() => {
      this.$router.replace({path: '/login'})
    })
    return false
  }
  return Promise.reject(error)
})
router.beforeEach((to, from, next) => {
  if(to.meta.title){//判断是否有标题
    document.title = '博雅时光机 - ' + to.meta.title
  }
  // if (whiteRouter.indexOf(this.$route.path) !== -1) {
  //   console.log(to)
  //   window.location.href = to.fullPath
  //   next()
  // } else {
  //   next()
  // }
  if (to.path == '/') return next()
  if (!storage.get('__token')) return next('/')
  next()
})

const vueApp = new Vue({
  router,
  store,
  mounted() {},
  render: h => h(App)
})
vueApp.$mount('#app')
