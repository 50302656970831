<template>
  <div
    class="cellInfo"
    v-if="Object.keys(orderInfo).length > 0"
    v-loading="isLoad"
    element-loading-text="请稍后.."
    element-loading-spinner="el-icon-loading"
  >
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/cellList'">细胞管理</el-breadcrumb-item>
      <el-breadcrumb-item>细胞详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout">
      <div class="top">
        <h2 class="color-333 mg-b-20" v-if="orderInfo.status == 2">
          订单已提交，等待买家签约
        </h2>
        <h2 class="color-333 mg-b-20" v-if="orderInfo.status == 5">
          订单已完成
        </h2>
        <h2 class="color-333 mg-b-20" v-if="orderInfo.status == 7">
          订单已关闭
        </h2>
        <el-steps
          :space="'50%'"
          :active="active"
          simple
          finish-status="success"
          align-center
        >
          <el-step title="提交订单"></el-step>
          <el-step title="待买家签约" v-if="orderInfo.status != 7"></el-step>
          <el-step title="待买家缴费" v-if="orderInfo.status != 7"></el-step>
          <el-step title="已完成" v-if="orderInfo.status != 7"></el-step>
          <el-step title="已关闭" v-if="orderInfo.status == 7"></el-step>
        </el-steps>
        <div v-if="orderInfo.status == 7" class="mg-t-20">
          <p class="font-12 mg-t-10">
            关闭操作人：{{ orderInfo.appoint.close_user }}
          </p>
          <p class="font-12 mg-t-10">
            关闭原因：{{ orderInfo.appoint.close_cause }}
          </p>
          <p
            class="font-12 mg-t-10"
            v-if="
              orderInfo.appoint.close_ext != null ||
              orderInfo.appoint.close_ext != ''
            "
          >
            备注：{{ orderInfo.appoint.close_ext }}
          </p>
        </div>

        <p class="font-12 mg-t-10 mg-b-20" v-if="orderInfo.status == 1">
          如买家未在<span style="color: rgb(234, 79, 79)">72小时</span
          >内签约，订单将按自动关闭。
        </p>

        <div class="flex mg-t-20">
          <p>买家备注：{{ orderInfo.buyer_remark }}</p>
          <p>-</p>
        </div>
        <div class="flex remark">
          <p>卖家备注：{{ orderInfo.seller_remark }}</p>
          <el-button type="text" class="font-14" @click="addRemark">{{
            orderInfo.seller_remark == null || orderInfo.seller_remark == ""
              ? "添加备注"
              : "编辑备注"
          }}</el-button>
        </div>
      </div>
    </div>
    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <div class="flex">
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">账号信息</h4>
          <p>用户昵称: {{ orderInfo.appoint.name }}</p>
          <p>联系电话: {{ orderInfo.appoint.mobile }}</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">个人信息</h4>
          <p>姓名: {{ orderInfo.appoint.name }}</p>
          <p>性别：{{ orderInfo.appoint.sex == 1 ? "男" : "女" }}</p>
          <p>年龄： {{ orderInfo.appoint.age }}</p>
          <p>生日： {{ orderInfo.appoint.birthday }}</p>
          <p>职业：{{ orderInfo.appoint.job }}</p>
          <p>
            城市：
            {{
              orderInfo.appoint.province != null
                ? orderInfo.appoint.province.area_name
                : "-"
            }}
            {{
              orderInfo.appoint.area != null
                ? orderInfo.appoint.area.area_name
                : "-"
            }}
            {{
              orderInfo.appoint.city != null
                ? orderInfo.appoint.city.area_name
                : "-"
            }}
          </p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">订单信息</h4>
          <p>
            订单编号: {{ orderInfo.order_sn }}
            <el-button type="text" @click="onCopy">复制</el-button>
          </p>
          <p>
            下单账号: {{ orderInfo.user_uuid }}
            <el-button
              type="text"
              @click="$router.push('/customerInfo?uuid=' + orderInfo.user_uuid)"
              >客户详情</el-button
            >
          </p>
          <p>下单时间: {{ orderInfo.create_time }}</p>
          <p>
            订单状态:
            <span v-if="orderInfo.status == 2">待签约</span>
            <span v-if="orderInfo.status == 5">已完成</span>
            <span v-if="orderInfo.status == 7">已关闭</span>
          </p>
          <p>订单来源：项目预约</p>
        </div>
      </div>
    </div>
    <div
      class="contractInfo boxLayout mg-t-20"
    >
      <h3 class="title-t mg-b-30">绑定信息</h3>
      <el-form inline                >
        <el-form-item label="销售员">
          <el-input v-model="sale_by" :disabled="orderInfo.sale_by != '-'" placeholder="请输入销售员名字"></el-input>
        </el-form-item>
        <!-- <el-form-item label="选择订单">
          <el-select v-model="order_uuid" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in orderList"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="选择邀请人">
          <el-select v-model="inviter_uuid" :disabled="orderInfo.inviter_uuid != '-'" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in userList"
              :key="item.uuid"
              :label="item.mobile"
              :value="item.uuid">
              <span style="float: left">{{ item.mobile }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.real_name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="orderInfo.sale_by == '-' ||  orderInfo.inviter_uuid == '-'">
          <el-button type="primary" @click="addinviter">绑定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div
      class="contractInfo boxLayout mg-t-20"
      v-if="orderInfo.contract != '-'"
    >
      <h3 class="title-t mg-b-30">合同信息</h3>
      <div class="flex mg-r-30 mg-l-2o">
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">合同信息</h4>
          <p>合同编号：{{ orderInfo.contract.code }}</p>
          <p>签约时间: {{ orderInfo.contract.sign_date }}</p>
          <p>签约套餐：{{ orderInfo.contract.set_type }}</p>
          <p>签约金额：{{ orderInfo.contract.money }}</p>
          <p>签约时长：{{ orderInfo.contract.duration }}</p>
          <p>已存年限：{{ orderInfo.contract.ycnx }}</p>
          <p>剩余年限：{{ orderInfo.contract.synx }}</p>
          <p>
            电子合同：<a
              style="display: inline-block"
              class="font-12 mg-r-10"
              v-if="all_pdf_url != ''"
              :href="all_pdf_url"
              target="_blank"
            >
              点击查看
            </a>
          </p>
        </div>
        <div
          class="mg-r-50 mg-l-20"
          v-if="orderInfo.status == 4 || orderInfo.status == 5"
        >
          <h4 class="mg-b-10 font-16">缴费信息</h4>
          <p v-if="orderInfo.fee.type == 1">
            缴费金额：{{ orderInfo.fee.yjje }}
          </p>
          <p>缴费时间: {{ orderInfo.pay_time }}</p>
          <p v-if="orderInfo.fee.type == 1">缴费方案：全款</p>
          <p v-if="orderInfo.fee.type == 2">
            缴费方案：分期/{{ orderInfo.stages }}期
          </p>

          <p>签约金额：{{ orderInfo.fee.price }}</p>
          <p v-if="orderInfo.fee.type == 2">
            已缴期数：{{ orderInfo.fee.detail.length }}/{{
              orderInfo.stages
            }}
            期
          </p>
          <p v-if="orderInfo.fee.type == 2">
            已缴金额：{{ orderInfo.contract.yjje }}
          </p>
        </div>
      </div>
    </div>

    <div class="boxLayout mg-t-20">
      <el-button type="primary" class="mg-b-20" @click="addRemarkInfo"
        >添加备注</el-button
      >
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="create_time" label="时间" :align="'center'">
        </el-table-column>
        <el-table-column prop="admin_name" label="填写人" :align="'center'">
        </el-table-column>
        <el-table-column prop="content" label="备注内容" :align="'center'">
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <div class="operateBtn">
      <!-- <el-button type="primary" size="mini" v-if="active === 0" @click="$alert('调用素材库')">上传合同</el-button> -->
      <el-button
        type="primary"
        v-if="orderInfo.status"
        size="mini"
        @click="showDialog1"
        >修改合同</el-button
      >
      <el-button
        size="mini"
        v-if="orderInfo.status != 7"
        @click="closeDialogForm = true"
        >关闭订单</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
      <!-- <el-button size="mini" v-if="active !== 0" @click="active --">返回上一状态</el-button> -->
      <!-- <el-button size="mini" v-if="active !== 3" @click="active ++">预览下一状态</el-button> -->
    </div>

    <el-dialog title="关闭细胞" :visible.sync="closeDialogForm" width="30%">
      <el-form ref="iform" :model="form" label-width="100px" :rules="rules2">
        <el-form-item label="关闭原因" prop="close_cause">
          <el-select v-model="form.close_cause" placeholder="请选择">
            <el-option label="客户取消" value="客户取消"></el-option>
            <el-option label="联系不上" value="联系不上"></el-option>
            <el-option label="价格过高" value="价格过高"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理人" prop="close_user">
          <el-input v-model="form.close_user"></el-input>
        </el-form-item>
        <el-form-item label="补充说明">
          <el-input
            type="textarea"
            resize="none"
            rows="5"
            v-model="form.close_ext"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialogForm = false">关闭</el-button>
        <el-button size="mini" type="primary" @click="closeItemAppoint('iform')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "cellInfo",
  components: {
    checkInfoDialog,
    pagination,
  },
  data() {
    return {
      active: 0,
      order_uuid: '',
      sale_by: '',
      orderList: [],
      userList: [],
      inviter_uuid: '',
      orderInfo: {},
      checkInfoDialogVisible: false,
      dialogType: "",
      all_pdf_url: "",
      confirmBtnText: "确定",
      title: "编辑合同信息",
      isLoad: false,
      infoForm: {
        money: "",
        type: "",
        times: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      closeDialogForm: false,
      form: {
        close_cause: "",
        close_user: "",
        close_ext: "",
      },
      rules2: {
        close_cause: { required: true, message: "请选择原因", blur: "change" },
        close_user: { required: true, message: "请填写", blur: "blur" },
      },
      rules: {
        money: { require: true, message: "请输入", trigger: "blur" },
        type: { require: true, message: "请选择", trigger: "change" },
        times: { require: true, message: "请选择", trigger: "change" },
      },
    };
  },
  created() {
    this.getInfo();
    this.getContract();
    this.getRemarkList();
    this.getProjectList()
    this.getUserList()
  },
  methods: {
    // 绑定
    addinviter () {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data: {
          order_uuid: this.$route.query.uuid,
          inviter_uuid: this.inviter_uuid,
          sale_by: this.sale_by
        },
        url: `https://time.api.boyalife.cn/v1/cms/ItemAppoint/addinviter`
      })
        .then(res => {
          this.$message.success('绑定成功！')
          this.getInfo()
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取项目订单列表
    getProjectList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://time.api.boyalife.cn/v1/cms/Item?page_size=99999&page_index=1`
      })
        .then(res => {
          this.orderList = res.data.result.data;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取用户列表
    getUserList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: `https://time.api.boyalife.cn/v1/cms/User?page_size=99999&page_index=1`
      })
        .then(res => {
          this.userList = res.data.result.data;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    //  获取合同
    getContract() {
      this.isLoad = true;
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemOrder/contract/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.all_pdf_url = res.data.result.allPdfUrl;
          this.isLoad = false;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getRemarkList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getRemarkList();
    },
    //  获取备注列表
    getRemarkList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemOrderRemark?order_uuid=${this.$route.query.uuid}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 编辑合同
    showDialog1() {
      this.$prompt("修改合同:", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: this.orderInfo.contract.code,
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入合同编号",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            contract_code: value,
            item_order_uuid: this.orderInfo.contract.order_uuid,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/ItemOrder/sync`,
        })
          .then(() => {
            this.$message.success("操作成功!");
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemOrder/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          this.orderInfo = res.data.result;
          for (let i in this.orderInfo) {
            if (this.orderInfo[i] == null) {
              this.orderInfo[i] = "-";
            }
          }
          if (this.orderInfo.sale_by != '-') {
            this.sale_by = this.orderInfo.sale_by
          }
          if (this.orderInfo.inviter_name != '-') {
            this.inviter_uuid = this.orderInfo.inviter_name
          }
          if (this.orderInfo.status == 2) {
            this.active = 1;
          }
          if (this.orderInfo.status == 5) {
            this.active = 3;
          }
          if (this.orderInfo.status == 7) {
            this.active = 1;
          }
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },

    closeDialog(v) {
      this.checkInfoDialogVisible = v;
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    // 关闭预约
    closeItemAppoint(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://time.api.boyalife.cn/v1/cms/ItemAppoint/close/${this.orderInfo.appoint.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getInfo();
              this.closeDialogForm = false;
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    //  添加备注
    addRemark() {
      this.$prompt("备注信息", "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            remark: value,
            uuid: this.$route.query.uuid,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/ItemAppoint/remark`,
        })
          .then((res) => {
            this.$message.success("操作成功");
            this.getInfo();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    //  添加合同备注
    addRemarkInfo() {
      this.$prompt("备注信息", "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            order_uuid: this.$route.query.uuid,
            content: value,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/ItemOrderRemark`,
        })
          .then((res) => {
            this.$message.success("操作成功");
            this.getRemarkList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
  },
};
</script>
<style scoped>
.cellInfo {
  margin-bottom: 80px;
}
.top .remark .el-button {
  padding: 0 10px;
}
.el-dialog {
  width: 500px;
}
>>> .el-step__title.is-process {
  color: #409eff;
}
>>> .el-step.is-simple .el-step__icon {
  border-color: #409eff;
}
>>> .el-step__head.is-wait .el-step__icon {
  border-color: #c0c4cc;
}
>>> .el-step__head.is-success .el-step__icon {
  border-color: #67c23a;
}
>>> .el-button--text {
  padding: 5px 0px;
}
</style>
