<template>
  <div class="promoterLevelSet">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>推广</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/promoterList' }"
        >推广员列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>推广等级设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">等级设置</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="150px">
        <el-form-item label="LV1推广员" prop="level_name1">
          <div class="flex align-center">
            <p>
              显示名称<el-input
                v-model="basicInfoForm.level_name1"
                class="ipt"
                placeholder="请输入"
              ></el-input>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV2推广员" prop="level_name2">
          <div class="flex align-center">
            <p>
              显示名称<el-input
                v-model="basicInfoForm.level_name2"
                class="ipt"
                placeholder="请输入"
              ></el-input>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV3推广员" prop="level_name3">
          <div class="flex align-center">
            <p>
              显示名称<el-input
                v-model="basicInfoForm.level_name3"
                class="ipt"
                placeholder="请输入"
              ></el-input>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV4推广员" prop="level_name4">
          <div class="flex align-center">
            <p>
              显示名称<el-input
                v-model="basicInfoForm.level_name4"
                class="ipt"
                placeholder="请输入"
              ></el-input>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV5推广员" prop="level_name5">
          <div class="flex align-center">
            <p>
              显示名称<el-input
                v-model="basicInfoForm.level_name5"
                class="ipt"
                placeholder="请输入"
              ></el-input>
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV6推广员" prop="level_name6">
          <div class="flex align-center">
            <p>
              显示名称<el-input
                v-model="basicInfoForm.level_name6"
                class="ipt"
                placeholder="请输入"
              ></el-input>
            </p>
          </div>
        </el-form-item>
        <h3 class="title-t mg-b-30 mg-t-20">升级条件</h3>
        <el-form-item label="LV1推广员" prop="upgrade_money2">
          <div class="flex align-center">
            <p>
              推广佣金达到<el-input
                v-model="basicInfoForm.upgrade_money1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              客户数量达到<el-input
                v-model="basicInfoForm.upgrade_number1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">位</template></el-input
              >
            </p>
            <p>
              注册天数达到<el-input
                v-model="basicInfoForm.upgrade_day1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV2推广员" prop="upgrade_money2">
          <div class="flex align-center">
            <p>
              推广佣金达到<el-input
                v-model="basicInfoForm.upgrade_money2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              客户数量达到<el-input
                v-model="basicInfoForm.upgrade_number2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">位</template></el-input
              >
            </p>
            <p>
              注册天数达到<el-input
                v-model="basicInfoForm.upgrade_day2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV3推广员" prop="upgrade_money3">
          <div class="flex align-center">
            <p>
              推广佣金达到<el-input
                v-model="basicInfoForm.upgrade_money3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              客户数量达到<el-input
                v-model="basicInfoForm.upgrade_number3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">位</template></el-input
              >
            </p>
            <p>
              注册天数达到<el-input
                v-model="basicInfoForm.upgrade_day3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV4推广员" prop="upgrade_money4">
          <div class="flex align-center">
            <p>
              推广佣金达到<el-input
                v-model="basicInfoForm.upgrade_money4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              客户数量达到<el-input
                v-model="basicInfoForm.upgrade_number4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">位</template></el-input
              >
            </p>
            <p>
              注册天数达到<el-input
                v-model="basicInfoForm.upgrade_day4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV5推广员" prop="upgrade_money5">
          <div class="flex align-center">
            <p>
              推广佣金达到<el-input
                v-model="basicInfoForm.upgrade_money5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              客户数量达到<el-input
                v-model="basicInfoForm.upgrade_number5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">位</template></el-input
              >
            </p>
            <p>
              注册天数达到<el-input
                v-model="basicInfoForm.upgrade_day5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV6推广员" prop="upgrade_money6">
          <div class="flex align-center">
            <p>
              推广佣金达到<el-input
                v-model="basicInfoForm.upgrade_money6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">元</template></el-input
              >
            </p>
            <p>
              客户数量达到<el-input
                v-model="basicInfoForm.upgrade_number6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">位</template></el-input
              >
            </p>
            <p>
              注册天数达到<el-input
                v-model="basicInfoForm.upgrade_day6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <h3 class="title-t mg-b-30 mg-t-20">佣金设置</h3>
        <el-form-item label="LV1推广员" prop="commission_rate1">
          <div class="flex align-center">
            <p>
              佣金<el-input
                v-model="basicInfoForm.commission_rate1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
            <p>
              下级佣金<el-input
                v-model="basicInfoForm.below_commission_rate1"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV2推广员" prop="commission_rate2">
          <div class="flex align-center">
            <p>
              佣金<el-input
                v-model="basicInfoForm.commission_rate2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
            <p>
              下级佣金<el-input
                v-model="basicInfoForm.below_commission_rate2"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV3推广员" prop="commission_rate3">
          <div class="flex align-center">
            <p>
              佣金<el-input
                v-model="basicInfoForm.commission_rate3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
            <p>
              下级佣金<el-input
                v-model="basicInfoForm.below_commission_rate3"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV4推广员" prop="commission_rate4">
          <div class="flex align-center">
            <p>
              佣金<el-input
                v-model="basicInfoForm.commission_rate4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
            <p>
              下级佣金<el-input
                v-model="basicInfoForm.below_commission_rate4"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV5推广员" prop="commission_rate5">
          <div class="flex align-center">
            <p>
              佣金<el-input
                v-model="basicInfoForm.commission_rate5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
            <p>
              下级佣金<el-input
                v-model="basicInfoForm.below_commission_rate5"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="LV6推广员" prop="commission_rate5">
          <div class="flex align-center">
            <p>
              佣金<el-input
                v-model="basicInfoForm.commission_rate6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
            <p>
              下级佣金<el-input
                v-model="basicInfoForm.below_commission_rate6"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">%</template></el-input
              >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="是否开启推广员申请渠道" label-width="200px">
          <el-switch
            v-model="basicInfoForm.commission_status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否开启下级分佣" label-width="200px">
          <el-switch
            v-model="basicInfoForm.apply_status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "promoterLevelSet",
  data() {
    return {
      imageUrl: "",
      isShow: false,
      basicInfoForm: {
        level_name1: "",
        level_name2: "",
        level_name3: "",
        level_name4: "",
        level_name5: "",
        level_name6: "",
        upgrade_money1: "",
        upgrade_money2: "",
        upgrade_money3: "",
        upgrade_money4: "",
        upgrade_money5: "",
        upgrade_money6: "",
        upgrade_day1: "",
        upgrade_day2: "",
        upgrade_day3: "",
        upgrade_day4: "",
        upgrade_day5: "",
        upgrade_day6: "",
        upgrade_number1: "",
        upgrade_number2: "",
        upgrade_number3: "",
        upgrade_number4: "",
        upgrade_number5: "",
        upgrade_number6: "",
        commission_rate1: "",
        commission_rate2: "",
        commission_rate3: "",
        commission_rate4: "",
        commission_rate5: "",
        commission_rate6: "",
        below_commission_rate1: "",
        below_commission_rate2: "",
        below_commission_rate3: "",
        below_commission_rate4: "",
        below_commission_rate5: "",
        below_commission_rate6: "",
        apply_status: 1,
        commission_status: 1,
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/ExtendLevelSetting/1`,
    })
      .then((res) => {
        console.log(res);
        for (let i in res.data.result) {
          this.basicInfoForm[i] = res.data.result[i];
        }
        this.basicInfoForm.apply_status = Number(
          this.basicInfoForm.apply_status
        );
        this.basicInfoForm.commission_status = Number(
          this.basicInfoForm.commission_status
        );
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    // 提交表单
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/ExtendLevelSetting/1`,
          })
            .then((res) => {
              this.$message.success("保存成功！");
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.promoterLevelSet {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
</style>
