<template>
  <div class="addGroup">
    <el-form
      ref="form"
      :model="basicInfoForm"
      :rules="rules"
      label-width="120px"
    >
      <div class="basicInfo boxLayout mg-t-20">
        <h3 class="title-t mg-b-30">基础信息</h3>
        <el-form-item label="标签名称" prop="name">
          <el-input
            v-model="basicInfoForm.name"
            placeholder="用于企业内部展示的分组名称"
            :maxlength="8"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="status" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="所属标签组" prop="group_uuid">
          <el-select
            v-model="basicInfoForm.group_uuid"
            placeholder="请选择关联标签组"
          >
            <el-option
              v-for="(item, i) in labelGroupList"
              :key="i"
              :label="item"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打标方式" prop="tag_type">
          <el-radio v-model="basicInfoForm.tag_type" :label="1"
            >自动打标</el-radio
          >
          <el-checkbox v-model="basicInfoForm.delete_previous" class="special"
            ><span class="font-12"
              >当客户不满足自动打标规则时，自动删除之前自动打标上的标签</span
            ></el-checkbox
          >
          <br />
          <el-radio v-model="basicInfoForm.tag_type" :label="2"
            >手动打标</el-radio
          >
        </el-form-item>
        <template v-if="basicInfoForm.tag_type == 1">
          <el-form-item label="满足条件" prop="and_or">
            <el-radio v-model="basicInfoForm.and_or" :label="1"
              >必须满足所有被选中的条件</el-radio
            >
            <el-radio v-model="basicInfoForm.and_or" :label="2"
              >满足任意一个被选中的条件</el-radio
            >
          </el-form-item>
          <el-form-item label="标签更新" prop="update_type">
            <el-radio v-model="basicInfoForm.update_type" :label="1"
              >例行</el-radio
            >
            <div
              class="font-12 color-999"
              style="width: 500px; display: inline-block"
              v-if="basicInfoForm.update_type == 1"
            >
              系统自动每
              <el-input-number
                class="day"
                v-model="basicInfoForm.update_interval"
                :min="1"
              ></el-input-number
              >天更新一次。更新时间为凌晨00:00:00
            </div>
            <br />
            <el-radio v-model="basicInfoForm.update_type" :label="2"
              >手动</el-radio
            >
          </el-form-item>
        </template>
      </div>
      <div class="boxLayout mg-t-20" v-if="basicInfoForm.tag_type == 1">
        <h3 class="title-t mg-b-20">需满足以下特征</h3>
        <el-button class="mg-l-50" type="text" @click="addCondition"
          >+ 过滤条件</el-button
        >
        <div class="filterConditions">
          <el-form-item
            v-for="(item, index) in basicInfoForm.label_condition"
            :key="item.key"
          >
            <!-- 用户属性 -->
            <el-select
              v-model="item.condition_uuid"
              placeholder="选择用户属性"
              @change="hanlderAttribute2(item, index)"
            >
              <el-option
                v-for="item in attributeOptions"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
            <!-- 属性类型 -->
            <el-select
              class="mg-l-20"
              v-if="item.condition_uuid !== ''"
              v-model="item.judgment"
              placeholder="选择判断类型"
              @change="hanlderType(item, index)"
            >
              <el-option
                v-for="j in item.typeOption"
                :key="j.uuid"
                :label="j.judgment"
                :value="j.uuid"
              >
              </el-option>
            </el-select>
            <!-- 属性值 -->
            <template>
              <!-- 会员等级 -->
              <el-select
                collapse-tags
                :multiple="item.judgment != 7 && item.judgment != 8"
                @change="getValue($event, index)"
                v-if="
                  item.option_type == 4 &&
                  item.judgment != 11 &&
                  item.judgment != 12
                "
                class="mg-l-20"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(jtem, i) in levelList"
                  :key="i"
                  :label="jtem"
                  :value="i"
                >
                </el-option>
              </el-select>
              <!-- 消费金额 -->
              <rangeInput
                class="mg-l-20"
                :minValue="item.value[0]"
                :maxValue="item.value[1]"
                v-if="
                  item.option_type == 2 &&
                  item.judgment == 13 &&
                  item.judgment != 18 &&
                  item.judgment != 19
                "
                v-model="item.value"
                @input="rangeNumber"
                :placeholderMin="'最小值'"
                :placeholderMax="'最大值'"
                :disabled="item.attribute === ''"
              />
              <el-input
                class="mg-l-20"
                style="width: 220px"
                placeholder="请输入"
                v-if="
                  (item.option_type == 2 && item.judgment == 14) ||
                  (item.option_type == 2 && item.judgment == 15) ||
                  (item.option_type == 2 && item.judgment == 16) ||
                  (item.option_type == 2 &&
                    item.judgment == 17 &&
                    item.judgment != 18 &&
                    item.judgment != 19)
                "
                v-model="item.value"
              >
              </el-input>
              <!-- 品类偏好 -->
              <el-select
                collapse-tags
                :multiple="item.judgment != 7 && item.judgment != 8"
                @change="getValue2($event, index)"
                v-if="
                  item.option_type == 13 &&
                  item.judgment != 11 &&
                  item.judgment != 12
                "
                class="mg-l-20"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(jtem, i) in goodsList"
                  :key="i"
                  :label="jtem"
                  :value="i"
                >
                </el-option>
              </el-select>
              <!-- 会员人群  -->
              <el-select
                collapse-tags
                :multiple="item.judgment != 7 && item.judgment != 8"
                @change="getValue3($event, index)"
                v-if="
                  item.option_type == 8 &&
                  item.judgment != 11 &&
                  item.judgment != 12
                "
                class="mg-l-20"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(jtem, i) in userGroupList"
                  :key="i"
                  :label="jtem"
                  :value="i + jtem"
                >
                </el-option>
              </el-select>
              <!-- 性别 -->
              <el-select
                collapse-tags
                :multiple="item.judgment != 7 && item.judgment != 8"
                @change="getValue4($event, index)"
                v-if="
                  item.option_type == 5 &&
                  item.judgment != 11 &&
                  item.judgment != 12
                "
                class="mg-l-20"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="jtem in sexList"
                  :key="jtem.uuid"
                  :label="jtem.name"
                  :value="jtem.name"
                >
                </el-option>
              </el-select>
              <!-- 销售 -->
              <el-select
                collapse-tags
                :multiple="item.judgment != 7 && item.judgment != 8"
                @change="getValue5($event, index)"
                v-if="
                  item.option_type == 9 &&
                  item.judgment != 11 &&
                  item.judgment != 12
                "
                class="mg-l-20"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(jtem, i) in salesList"
                  :key="i"
                  :label="jtem"
                  :value="i"
                >
                </el-option>
              </el-select>
              <!-- 身份 -->
              <el-select
                collapse-tags
                :multiple="item.judgment != 7 && item.judgment != 8"
                @change="getValue6($event, index)"
                v-if="
                  item.option_type == 10 &&
                  item.judgment != 11 &&
                  item.judgment != 12
                "
                class="mg-l-20"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(jtem, i) in sfList"
                  :key="i"
                  :label="jtem"
                  :value="i"
                >
                </el-option>
              </el-select>
              <!-- 注销状态 -->
              <el-select
                collapse-tags
                :multiple="item.judgment != 7 && item.judgment != 8"
                @change="getValue7($event, index)"
                v-if="
                  item.option_type == 11 &&
                  item.judgment != 11 &&
                  item.judgment != 12
                "
                class="mg-l-20"
                v-model="item.value"
                placeholder="请选择"
              >
                <el-option
                  v-for="(jtem, i) in loginStatusList"
                  :key="i"
                  :label="jtem"
                  :value="i"
                >
                </el-option>
              </el-select>
              <!-- 地区 -->
              <el-select
                class="mg-l-20"
                :multiple="item.judgment != 1 && item.judgment != 2"
                v-if="
                  item.option_type == 6 &&
                  item.judgment != 5 &&
                  item.judgment != 6
                "
                v-model="item.value"
                filterable
                collapse-tags
                @change="getValue8($event, index)"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.area_id"
                  :label="item.area_name"
                  :value="item.area_id"
                >
                </el-option>
              </el-select>
              <!-- 项目信息 -->
              <el-select
                class="mg-l-20"
                :multiple="item.judgment != 7 && item.judgment != 8"
                v-if="
                  item.option_type == 14 &&
                  item.condition_uuid == 25 &&
                  item.judgment != 11 &&
                  item.condition_uuid == 25 &&
                  item.judgment != 12
                "
                v-model="item.value"
                filterable
                collapse-tags
                @change="getValue9($event, index)"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in itemList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-select>
              <!-- <el-cascader
                v-if="item.option_type == 6&&item.judgment != 5 && item.judgment != 6"
                class="mg-l-20"
                :options="cityList"
                @change="getCityValue"
                :props="props"
                collapse-tags
                clearable></el-cascader> -->
              <!-- 时间 -->
              <!-- item.option_type == 6&&item.judgment == 26 ||  -->
              <el-date-picker
                class="mg-l-20"
                v-if="item.judgment == 26 && item.condition_uuid != 4"
                v-model="item.value"
                type="daterange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
                @change="selectDate($event, index)"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
              <el-date-picker
                class="mg-l-20"
                v-if="
                  (item.condition_uuid == 5 && item.judgment == 27) ||
                  (item.condition_uuid == 23 && item.judgment == 27) ||
                  (item.condition_uuid == 24 && item.judgment == 27)
                "
                v-model="item.value"
                type="date"
                value-format="yyyy/MM/dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <el-date-picker
                class="mg-l-20"
                v-if="
                  ((item.judgment == 27 || item.judgment == 28) &&
                    item.condition_uuid != 23 &&
                    item.condition_uuid != 24 &&
                    item.condition_uuid != 5) ||
                  (item.condition_uuid == 5 && item.judgment == 28) ||
                  (item.condition_uuid == 24 && item.judgment == 28) ||
                  (item.condition_uuid == 23 && item.judgment == 28)
                "
                v-model="item.value"
                type="year"
                value-format="yyyy"
                placeholder="选择年"
              >
              </el-date-picker>
              <template v-if="item.condition_uuid == 4 && item.judgment == 26">
                <el-date-picker
                  class="mg-l-20"
                  type="year"
                  v-model="startYear"
                  @change="getStartYear($event, index)"
                  value-format="yyyy"
                  placeholder="选择开始年份"
                >
                </el-date-picker>
                ~
                <el-date-picker
                  class="mg-l-20"
                  type="year"
                  v-model="endYear"
                  @change="getEndYear($event, index)"
                  value-format="yyyy"
                  placeholder="选择结束年份"
                >
                </el-date-picker>
              </template>
            </template>
            <el-button
              class="mg-l-20"
              type="text"
              size="mini"
              @click="deleted(index)"
              >删除</el-button
            >
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="submitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import rangeInput from "../../../components/rangeInput.vue";
export default {
  name: "",
  components: {
    rangeInput,
  },
  data() {
    return {
      isAdd: false,
      isShow: true,
      type: "",
      labelGroupList: [],
      startYear: "",
      endYear: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      basicInfoForm: {
        name: "",
        group_uuid: "",
        tag_type: 1,
        update_type: 1,
        and_or: 1,
        update_interval: 1,
        label_condition: [],
        delete_previous: false,
      },
      rules: {
        name: { required: true, message: "请输入标签名", trigger: "blur" },
        group_uuid: {
          required: true,
          message: "请选择标签组",
          trigger: "change",
        },
      },
      props: {
        multiple: true,
        label: "area_name",
        value: "area_id",
      },
      levelList: [],
      goodsList: [],
      salesList: [],
      cityList: [],
      sfList: [],
      userGroupList: [],
      sexList: { 1: "男", 2: "女", 3: "未知" },
      loginStatusList: { 1: "已注销", 2: "未注销" },
      attributeOptions: [],
      typeOption: [],
      isComplete: false,
      itemList: [],
    };
  },
  created() {
    this.getCondition();
    this.getLabelGroupList();
    this.getCity();
    this.getLevelList();
    this.getGoodsList();
    this.getUserGroup();
    this.getSalesList();
    this.getSfList();
    this.getSex();
    this.getItemList();
    setTimeout(() => {
      if (Object.keys(this.$route.query).length !== 0) {
        this.pageType = "编辑";
        this.axios({
          method: "get", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/UserLabel/${this.$route.query.uuid}`,
        })
          .then((res) => {
            let params = res.data.result;
            for (let i in params) {
              this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
            }
            this.basicInfoForm.tag_type = Number(this.basicInfoForm.tag_type);
            this.basicInfoForm.and_or = Number(this.basicInfoForm.and_or);
            this.basicInfoForm.status = Number(this.basicInfoForm.status);
            this.basicInfoForm.update_type = Number(
              this.basicInfoForm.update_type
            );
            if (Number(this.basicInfoForm.delete_previous) == 0) {
              this.basicInfoForm.delete_previous = 2;
              this.basicInfoForm.delete_previous =
                Number(this.basicInfoForm.delete_previous) == 1 ? true : false;
            } else {
              this.basicInfoForm.delete_previous =
                Number(this.basicInfoForm.delete_previous) == 1 ? true : false;
            }

            this.basicInfoForm.label_condition = [];

            this.basicInfoForm.condition.map((item, index) => {
              console.log(item);
              if (
                item.condition_uuid == 3 &&
                item.judgment != 1 &&
                item.judgment != 2
              ) {
                item.value_name.value = item.value_name.value.split(",");
                // item.value = item.value + ''
              }
              if (typeof item.value == "number") {
                item.value = item.value + "";
              }
              if (item.condition_uuid == 4 && item.judgment.uuid == 26) {
                this.startYear = item.value[0];
                this.endYear = item.value[1];
              }
              // if(item.condition_uuid)
              //
              let obj = {};
              obj.condition_uuid = item.condition_uuid;
              obj.judgment = item.judgment.uuid;
              obj.value_name = item.value_name;
              obj.condition_name = item.condition_name;
              obj.judgment_name = item.judgment_name;
              if (!item.value.value) {
                obj.value = item.value;
              } else {
                obj.value = "";
              }
              if (item.value.min) {
                obj.value = [item.value.min, item.value.max];
              }
              this.attributeOptions.map((jtem) => {
                if (item.condition_uuid == jtem.uuid) {
                  obj.option_type = jtem.option_type;
                  obj.typeOption = jtem.judgment;
                  this.hanlderAttribute(obj, index);
                }
              });

              this.basicInfoForm.label_condition.push(obj);
            });
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      } else {
        this.pageType = "新增";
      }
    }, 50);
  },
  methods: {
    // 获取项目列表
    getItemList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/Item?search_status=1&page_size=99999`,
      }).then((res) => {
        this.itemList = res.data.result.data;
      });
    },
    getStartYear(v, i) {
      this.basicInfoForm.label_condition[i].value = [];
      this.basicInfoForm.label_condition[i].value[0] = v;
    },
    getEndYear(v, i) {
      this.basicInfoForm.label_condition[i].value[1] = v;
    },
    getSex() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/UserProfile/ade2d2b666f75232466890e075e26616`,
      }).then((res) => {
        let params = res.data.result;
        this.sexList = params.option;
      });
    },
    hanlderAttribute(v, i) {
      this.attributeOptions.filter((item, index) => {
        if (item.uuid === v.condition_uuid) {
          this.typeOption = item.judgment;
          this.type = item.option_type;
        }
      });
    },
    hanlderAttribute2(v, i) {
      this.basicInfoForm.label_condition[i].judgment = "";
      this.basicInfoForm.label_condition[i].value = "";
      this.basicInfoForm.label_condition[i].value_name.value = null;

      this.attributeOptions.filter((item, index) => {
        if (item.uuid === v.condition_uuid) {
          this.basicInfoForm.label_condition[i].typeOption = item.judgment;
          this.basicInfoForm.label_condition[i].condition_name = item.name;
          this.typeOption = item.judgment;
          this.type = item.option_type;
          v.judgment = "";
          v.value = "";
          this.basicInfoForm.label_condition[i].option_type = item.option_type;
        }
      });
    },
    selectDate(e, index) {},
    getValue(e, i) {
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        for (let o in this.levelList) {
          e.map((item) => {
            if (o == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                this.levelList[o]
              );
            }
          });
        }
      } else {
        // this.basicInfoForm.label_condition[i].value_name.value = e
        for (let o in this.levelList) {
          if (o == e) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              this.levelList[o]
            );
          }
        }
        console.log(this.basicInfoForm.label_condition[i]);
      }
    },
    getValue2(e, i) {
      if (typeof e == "object") {
        this.basicInfoForm.label_condition[i].value_name.value = [];
        for (let o in this.goodsList) {
          e.map((item) => {
            if (o == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                this.goodsList[o]
              );
            }
          });
        }
      } else {
        // this.basicInfoForm.label_condition[i].value_name.value = e
        for (let o in this.levelList) {
          if (o == e) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              this.levelList[o]
            );
          }
        }
      }
    },
    getValue3(e, i) {
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        for (let o in this.userGroupList) {
          e.map((item) => {
            if (o == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                this.userGroupList[o]
              );
            }
          });
        }
      } else {
        for (let o in this.userGroupList) {
          if (o == e) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              this.userGroupList[o]
            );
          }
        }
      }
    },
    getValue4(e, i) {
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        this.sexList.map((jtem) => {
          e.map((item) => {
            if (jtem.name == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(item);
            }
          });
        });
      } else {
        this.sexList.map((item) => {
          if (e == item.name) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              item.name
            );
          }
        });
      }
    },
    getValue9(e, i) {
      console.log(e);
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        this.itemList.map((jtem) => {
          e.map((item) => {
            if (jtem.uuid == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                jtem.name
              );
            }
          });
        });
      } else {
        this.itemList.map((item) => {
          if (e == item.uuid) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              item.name
            );
          }
        });
      }
    },
    getValue5(e, i) {
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        for (let o in this.salesList) {
          e.map((item) => {
            if (o == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                this.salesList[o]
              );
            }
          });
        }
      } else {
        for (let o in this.salesList) {
          if (o == e) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              this.salesList[o]
            );
          }
        }
      }
    },
    getValue6(e, i) {
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        for (let o in this.sfList) {
          e.map((item) => {
            if (o == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                this.sfList[o]
              );
            }
          });
        }
      } else {
        for (let o in this.sfList) {
          if (o == e) {
            this.basicInfoForm.label_condition[i].value = this.sfList[o];
            this.basicInfoForm.label_condition[i].value_name.value.push(
              this.sfList[o]
            );
          }
        }
      }
    },
    getValue7(e, i) {
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        for (let o in this.loginStatusList) {
          e.map((item) => {
            if (o == item) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                this.loginStatusList[o]
              );
            }
          });
        }
      } else {
        for (let o in this.loginStatusList) {
          if (o == e) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              this.loginStatusList[o]
            );
          }
        }
      }
    },
    getValue8(e, i) {
      this.basicInfoForm.label_condition[i].value_name.value = [];
      if (typeof e == "object") {
        this.cityList.map((item) => {
          e.map((jtem) => {
            if (jtem == item.area_id) {
              this.basicInfoForm.label_condition[i].value_name.value.push(
                item.area_name
              );
            }
          });
        });
      } else {
        this.cityList.map((item) => {
          if (e == item.area_id) {
            this.basicInfoForm.label_condition[i].value_name.value.push(
              item.area_name
            );
          }
        });
      }
    },
    hanlderType(item, i) {
      console.log(item);
      this.basicInfoForm.label_condition[i].value = "";
      this.basicInfoForm.label_condition[i].value_name.value = "";
      this.basicInfoForm.label_condition[i].typeOption.map((jtem) => {
        if (item.judgment == jtem.uuid) {
          this.basicInfoForm.label_condition[i].judgment_name = jtem.judgment;
        }
      });
      console.log(this.basicInfoForm.label_condition[i]);
      if (this.type == 4) {
        this.getLevelList();
      } else if (this.type == 13) {
        this.getGoodsList();
      } else if (this.type == 8) {
        this.getUserGroup();
      } else if (this.type == 9) {
        this.getSalesList();
      } else if (this.type == 10) {
        this.getSfList();
      } else if (this.type == 6) {
        this.getCity();
      }
      if (item.judgment == 9) {
        this.basicInfoForm.label_condition[i].value = [];
        this.basicInfoForm.label_condition[i].value_name.value = [];
      }
    },
    rangeNumber(v) {
      console.log(v);
    },
    addCondition() {
      this.isAdd = true;
      let obj = {
        condition_uuid: "",
        condition_name: "",
        option_type: "",
        judgment: "",
        judgment_name: "",
        value: [],
        value_name: {
          value: null,
        },
      };
      this.basicInfoForm.label_condition.push(obj);
    },
    deleted(i) {
      this.basicInfoForm.label_condition.splice(i, 1);
    },
    submitForm(formName) {
      console.log(this.basicInfoForm.label_condition);

      let form = JSON.parse(JSON.stringify(this.basicInfoForm));

      delete form.admin;
      delete form.admin_uuid;
      delete form.create_time;
      delete form.delete_time;
      delete form.labelgroup;
      delete form.update_time;
      delete form.condition;
      form.delete_previous = form.delete_previous == true ? 1 : 2;
      form.label_condition.map((item) => {
        console.log(item);

        if (
          item.value_name.value == null ||
          typeof item.value == "string" ||
          item.judgment_name == "区间" ||
          item.judgment_name == "时间区间"
        ) {
          console.log(item.value_name);

          // 地区
          if (
            item.condition_uuid != 3 &&
            item.condition_uuid != 1 &&
            item.condition_uuid != 17 &&
            item.condition_uuid != 19 &&
            item.condition_uuid != 22 &&
            item.condition_uuid != 25
          ) {
            item.value_name.value = item.value;
          } else {
            if (item.judgment_name != "时间区间") {
              item.value_name.value = item.value_name.value[0];
            }
            if (item.judgment_name == "有值" || item.judgment_name == "无值") {
              item.value_name.value = "";
            }
          }
          if (
            item.judgment_name == "时间区间" ||
            item.judgment_name == "积分区间"
          ) {
            item.value_name.value = item.value.join(",");
          }
        } else {
          item.value_name.value = item.value_name.value.join(",");
        }

        if (item.value.min) {
          let arr = [];
          arr[0] = item.value.min;
          arr[1] = item.value.max;
          item.value = arr;
          let arr2 = JSON.parse(JSON.stringify(arr));
          item.value_name.value = arr2.join(",");
        }
        delete item.option_type;
        delete item.typeOption;
      });

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (form.tag_type == 1 && form.label_condition.length == 0) {
            this.$message.warning("自动打标必须设置筛选条件！");
            return false;
          }
          if (this.basicInfoForm.tag_type == 2) {
            form.label_condition = [];
          }
          console.log(this.basicInfoForm.label_condition);
          console.log(form);

          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: form,
              url: ` https://time.api.boyalife.cn/v1/cms/UserLabel/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: form,
              url: ` https://time.api.boyalife.cn/v1/cms/UserLabel`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    // 获取特征值列表
    getCondition() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Condition`,
      })
        .then((res) => {
          this.attributeOptions = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 标签组
    getLabelGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabelGroupSelect`,
      })
        .then((res) => {
          this.labelGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 等级列表   https://time.api.boyalife.cn/v1/cms/UserLevelSelect
    getLevelList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 商城类别
    getGoodsList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategorySelect`,
      })
        .then((res) => {
          this.goodsList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 会员人群
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`,
      })
        .then((res) => {
          this.userGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 推广下拉
    getSalesList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ExtendSelect`,
      })
        .then((res) => {
          this.salesList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 身份下拉
    getSfList() {
      this.sfList = {
        1: "游客",
        2: "会员",
        3: "推广员",
      };
      // this.axios({
      //   method: 'get',//请求方法
      //   headers: {
      //     "Content-Type": "text/html;charset=UTF-8",
      //     "X-Access-Token": this.storage.get('__token'),
      //     "Authorization": `Bearer ${this.storage.get('__token')}`
      //   },
      //   url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`,
      // }).then(res => {
      //   this.sfList = res.data.result
      // }).catch(err=> {
      //   this.$message.error(err.response.data.error.message)
      // })
    },
    // 地区
    getCity() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Region`,
      })
        .then((res) => {
          this.cityList = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
  },
};
</script>
<style scoped>
.addGroup {
  margin-bottom: 80px;
}
.special >>> .el-radio__inner {
  border-radius: 0;
  border-color: #999;
}
.inputBox {
  width: 210px;
  display: inline-block;
  margin-left: 20px;
}
.inputBox >>> .el-input {
  width: 100px;
}
</style>
