<template>
  <div class="virtualGoodsOrder">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportCommidyOrder">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="item"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          @click="addRemark('batch')"
          :disabled="uuids.length == 0"
          >批量备注</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        tooltip-effect="dark"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="订单信息"
          :align="'center'"
          width="500"
          prop="goods.name"
        >
          <template slot-scope="scope">
            <div class="product flex space-between">
              <div class="flex space-around">
                <el-image :src="scope.row.goods[0].image"></el-image>
                <div style="width: 80%" class="text-left">
                  <p class="ellipsis">
                    {{ scope.row.goods[0].name }}
                  </p>
                  <p class="font-12">
                    <span
                      v-for="(item, index) in JSON.parse(
                        scope.row.goods[0].specs
                      )"
                      :key="index"
                      >{{ item }}</span
                    >
                  </p>
                </div>
              </div>
              <div class="font-12 mg-l-20" style="width: 150px">
                <p>{{ scope.row.goods.retail_price }}</p>
                <p>{{ scope.row.goods.number }}</p>
              </div>
            </div>
            <div class="orderInfo">
              <div class="flex space-between align-center">
                <div class="flex">
                  <p class="mg-r-10 font-12">
                    下单时间: {{ scope.row.create_time }}
                  </p>
                  <p class="font-12">订单编号： {{ scope.row.order_sn }}</p>
                </div>
                <div>
                  <p class="font-12">
                    共{{ scope.row.goods.length }}件商品，合计:
                    {{ scope.row.price }}（含运费: ¥0.00 ）
                  </p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="收货信息">
          <template slot-scope="scope">
            <p>
              <span>买家：{{ scope.row.name }}</span>
            </p>
            <p>
              <span>收货地址：{{ scope.row.address }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">等待买家付款</span>
            <span v-else-if="scope.row.status == 2">等待卖家发货</span>
            <span v-else-if="scope.row.status == 3">已发货</span>
            <span v-else-if="scope.row.status == 4" style="color: red"
              >退款售后</span
            >
            <span v-else-if="scope.row.status == 5" style="color: green"
              >已完成</span
            >
            <span v-else>已关闭</span>
          </template>
        </el-table-column>
        <el-table-column label="配送方式">
          <template slot-scope="scope"> 快递配送 </template>
        </el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="$router.push('/virtualOrderInfo?uuid=' + scope.row.uuid)"
              >订单详情</el-button
            >
            <el-button size="mini" type="text" v-if="currentClass === 1"
              >关闭订单</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="addRemark('single', scope.row)"
              >备注</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="评价详情" :visible.sync="dialogVisible" width="30%">
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "virtualGoodsOrder",
  components: { pagination },
  data() {
    return {
      tabs: ["全部", "待付款", " 已完成", " 已关闭"],
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_status: "",
      },
      dialogVisible: false,
      value: 5,
      uuids: [],
      currentClass: 0,
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrder?search_type=2&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_status=${this.customerGroupForm.search_status}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 导出
    exportCommidyOrder() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrder/export?search_type=2&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&from_type=${this.customerGroupForm.from_type}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `虚拟商品订单 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    addClass(index) {
      this.currentClass = index;
      if (index == 0) {
        this.customerGroupForm.search_status = "";
      } else if (index == 1) {
        this.customerGroupForm.search_status = 1;
      } else if (index == 2) {
        this.customerGroupForm.search_status = 5;
      } else if (index == 3) {
        this.customerGroupForm.search_status = 6;
      }
      this.getList();
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    //  添加备注
    addRemark(type, row) {
      let uuids = "";
      if (type == "single") {
        uuids = [row.uuid];
      } else {
        uuids = this.uuids;
      }
      this.$prompt("备注信息", "添加备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            uuids: uuids,
            remark: value,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrder/remark`,
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功！",
            });
            this.getList();
          })
          .catch((err) => {
            this.$message.erroe(err);
          });
      });
    },
    //勾选
    handleSelectionChange(val) {
      this.uuids = val.map((item) => item.uuid);
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.virtualGoodsOrder .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
>>> .el-table tr {
  position: relative;
  width: 100%;
}
>>> .el-table .el-table__cell {
  position: static;
}
>>> .el-table td .cell {
  padding-top: 40px;
}
.orderInfo {
  width: 100%;
  background: #f6f9ff;
  padding: 5px 20px;
  box-sizing: content-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.copy {
  display: inline-block;
  padding: 2px 5px;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid #666;
  color: #666;
  font-size: 12px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
</style>
