<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>短信签名</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <div class="tips">
        <i class="el-icon-warning" style="color: #5b8fd9"></i>
        短信签名用于向阿里云申请，审核通过后即可新建短信模块
        <el-button type="text" size="mini">操作指引></el-button>
      </div>
      <div class="text-right mg-t-20 flex space-between">
        <el-form :model="customerGroupForm" label-width="100">
          <el-form-item>
            <el-select
              v-model="customerGroupForm.region"
              placeholder="全部状态"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="$router.push('/smsConfiguration')"
            >短信配置</el-button
          >
          <el-button type="primary" @click="$router.push('/addSignature')"
            >新建签名</el-button
          >
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="SignName" label="签名名称" align="center">
        </el-table-column>
        <el-table-column prop="BusinessType" label="签名用途" align="center">
        </el-table-column>
        <el-table-column
          prop="Reason.RejectInfo"
          label="备注信息"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column prop="AuditStatus" label="状态" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.AuditStatus == 'AUDIT_STATE_PASS'">
              <span class="circle pass"></span>审核通过
            </p>
            <p v-else><span class="circle unpass"></span>审核失败</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CreateDate" label="创建时间">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="pass(scope.row)" type="text" size="small"
              >详情</el-button
            >
            <el-button @click="deleted" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "smsSignature",
  data() {
    return {
      customerGroupForm: {
        status: "",
      },
      tableData: [],
    };
  },
  created() {
    this.getSmsSign();
  },
  methods: {
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/SmsSign${mark}${queryStry}`,
      })
        .then((res) => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete") {
            this.getSmsSign();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getSmsSign() {
      let data = {};
      let queryStry = `status=${this.customerGroupForm.status}`;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    deleted() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    pass(row) {
      let html = `
        <h5 class="flex mg-b-10"><span style="display: inline-block;width: 100px;text-align: right;">签名名称：</span><p> ${
          row.SignName
        }</p></h5>
        <h5 class="flex mg-b-10"><span style="display: inline-block;width: 100px;text-align: right;">签名用途：</span><p>${
          row.BusinessType
        }</p></h5>
        <h5 class="flex mg-b-10"><span style="display: inline-block;width: 100px;text-align: right;">备注信息： </span><p style="width:  70%">${
          row.Reason.RejectInfo !== null ? row.Reason.RejectInfo : ""
        }</p></h5>
        <h5 class="flex mg-b-10"><span style="display: inline-block;width: 100px;text-align: right;">状态：</span><p>${
          row.AuditStatus
        }</p></h5>
        <h5 class="flex mg-b-10"><span style="display: inline-block;width: 100px;text-align: right;">创建时间：</span><p>${
          row.CreateDate
        }</p></h5>`;
      this.$confirm(html, "签名详情", {
        cancelButtonText: "关闭",
        dangerouslyUseHTMLString: true,
      });
    },
  },
};
</script>
<style scoped>
.tips {
  padding: 5px 10px;
  background: #e7f0ff;
  border: 1px solid #7db3f2;
  line-height: 35px;
  font-size: 12px;
}
.tips >>> .el-button span {
  font-size: 12px;
  margin-left: 10px;
  display: inline-block;
}
.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 2px;
}
.circle.pass {
  background: #5ac724;
}
.circle.unpass {
  background: #f95036;
}
.circle.passing {
  background: #999999;
}
h5 span {
}
</style>
