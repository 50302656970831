<template>
  <div class="sensitiveWordsList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>敏感词库</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="dataTable">
      <div class="flex space-between">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item class="keyword">
            <el-input
              placeholder="请输入要搜索的内容"
              v-model="customerGroupForm.search_keyword"
              @input="getSensitiveWordGroup"
            >
              <i
                slot="append"
                class="el-icon-search"
                style="cursor: pointer"
              ></i>
            </el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" @click="add(editType)">新建</el-button>
          <el-button type="primary" @click="batchDel" :disabled="isSelected"
            >批量删除</el-button
          >
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%; height: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="序号"
          width="55px"
          :align="'center'"
          type="index"
        >
        </el-table-column>
        <el-table-column
          label="词库名"
          :align="'center'"
          prop="name"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column prop="words_number" label="敏感词数" :align="'center'">
        </el-table-column>
        <el-table-column prop="admin.name" label="创建人" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="最后修改时间"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="250"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)">编辑</el-button>
            <el-button
              type="text"
              @click="$router.push('/wordList?uuid=' + scope.row.uuid)"
              >敏感词列表</el-button
            >
            <el-button type="text" @click="delSensitiveWordGroup(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      title="添加敏感词库"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-input
        v-model.trim="name"
        type="textarea"
        resize="none"
        rows="8"
        placeholder=""
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancalAdd">取 消</el-button>
        <el-button type="primary" @click="addSensitiveWordGroup"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "sensitiveWordsList",
  components: {
    checkInfoDialog,
    pagination,
  },
  data() {
    return {
      isSelected: true,
      name: "",
      uuids: "",
      editType: "",
      customerGroupForm: {
        search_keyword: "",
      },
      dialogFormVisible: false,
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getSensitiveWordGroup();
  },
  methods: {
    handleSelectionChange(row) {
      if (row.length > 0) {
        this.isSelected = false;
        this.uuids = row.map((item) => item.uuid);
      }
    },
    add() {
      this.editType = "add";
      this.dialogFormVisible = true;
      this.name = "";
    },
    edit(row) {
      this.editType = "";
      this.dialogFormVisible = true;
      this.name = row.name;
      this.uuid = row.uuid;
    },
    cancalAdd() {
      this.dialogFormVisible = false;
      this.name = "";
    },
    batchDel() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              uuids: this.uuids,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/SensitiveWordGroup/delete_branch`,
          })
            .then((res) => {
              this.$message.success("删除成功！");
              this.getSensitiveWordGroup();
            })
            .catch((err) => {
              this.$message.success(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 列表
    getSensitiveWordGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },

        url: ` https://time.api.boyalife.cn/v1/cms/SensitiveWordGroup?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 新建
    addSensitiveWordGroup() {
      if (this.name == "") {
        this.$message.warning("词库不能为空！");
        return false;
      }
      if (this.editType === "add") {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            name: this.name,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/SensitiveWordGroup`,
        })
          .then((res) => {
            console.log(res.code);

            this.$message.success("新建成功！");
            this.dialogFormVisible = false;
            this.getSensitiveWordGroup();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      } else {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            name: this.name,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/SensitiveWordGroup/${this.uuid}`,
        })
          .then((res) => {
            this.$message.success("编辑成功！");
            this.dialogFormVisible = false;
            this.getSensitiveWordGroup();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      }
    },
    // 删除
    delSensitiveWordGroup(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/SensitiveWordGroup/${row.uuid}`,
          })
            .then((res) => {
              this.$message.success("删除成功！");
              this.getSensitiveWordGroup();
            })
            .catch((err) => {
              this.$message.success(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getSensitiveWordGroup();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getSensitiveWordGroup();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.sensitiveWordsList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
  margin-top: 0;
}
.dataTable .tabs {
  position: absolute;
  top: 35px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
