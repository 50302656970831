<template>
  <div class="inventoryManage">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>商品</el-breadcrumb-item>
      <el-breadcrumb-item>实物库存管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="商品分类">
          <el-select
            v-model="customerGroupForm.category_uuid"
            placeholder="请选择"
          >
            <el-option-group
              v-for="group in options"
              :key="group.uuid"
              :label="group.name"
              :value="group.uuid"
            >
              <el-option
                v-for="item in group.children"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="价格区间">
          <rangeInput
            @input="rangeNumber"
            :placeholderMin="'最低金额'"
            :placeholderMax="'最高金额'"
          />
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入商品编码/名称"
            v-model="customerGroupForm.content"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList(currentClass)"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.image.length > 0"
                :src="scope.row.image[0].image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="商品编码" :align="'center'">
        </el-table-column>
        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column prop="stock" label="库存" :align="'center'" sortable>
        </el-table-column>
        <el-table-column prop="category.name" label="分类" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >开售</span
            >
            <span v-else-if="scope.row.status == 2">未开售</span>
            <span v-else-if="scope.row.status == 3">已售完</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="150"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="changeKC(scope.row)"
              >修改库存</el-button
            >
            <el-button
              type="text"
              @click="$router.push(`/inventoryRecords?uuid=${scope.row.uuid}`)"
              >库存记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="库存管理" :visible.sync="dialogTableVisible">
      <el-table :data="gridData" border>
        <el-table-column
          v-for="(item, i) in headerList"
          :key="i"
          :prop="item"
          :label="item"
          align="center"
        ></el-table-column>

        <el-table-column prop="stock" label="库存" align="center" width="200">
        </el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template slot-scope="scope">
            <el-button type="text" @click="changeInventory(scope.row)"
              >修改库存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          type="primary"
          @click="dialogTableVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="修改库存" :visible.sync="inventoryDialog" width="30%">
      <el-form :model="inventoryForm" :rules="rules" ref="inventoryForm" inline>
        <el-form-item prop="type" label="类型">
          <el-radio v-model="inventoryForm.type" :label="1" border
            >增加</el-radio
          >
          <el-radio v-model="inventoryForm.type" :label="2" border
            >减少</el-radio
          >
        </el-form-item>
        <el-form-item prop="stock" label="数量">
          <el-input
            v-model="inventoryForm.stock"
            placeholder="输入库存数量"
          ></el-input>
        </el-form-item>
        <el-form-item prop="order_sn" label="单号">
          <el-input
            v-model="inventoryForm.order_sn"
            placeholder="输入出/入库单号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="cancel">取消</el-button>
        <el-button
          size="mini"
          type="primary"
          @click="operateInventory('inventoryForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import rangeInput from "../../../components/rangeInput.vue";
import pagination from "../../../components/pagination.vue";

export default {
  name: "inventoryManage",
  components: { rangeInput, pagination },
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        status: 0,
      },
      inventoryForm: {
        specs_uuid: "",
        type: 1,
        stock: "",
        action_type: 3,
        order_sn: "",
      },
      rules: {
        stock: { required: true, message: "请输入", trigger: "blur" },
        order_sn: { required: true, message: "请输入", trigger: "blur" },
      },
      headerList: [],
      kc: null,
      dialogTableVisible: false,
      inventoryDialog: false,
      tabs: [],
      currentClass: 0,
      options: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      gridData: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getCount();
    this.getData();
    this.getList(this.currentClass);
  },
  methods: {
    changeKC(row) {
      this.dialogTableVisible = true;
      this.gridData = [];
      console.log(row.specs);
      row.specs.map((item) => {
        let obj = {};
        for (let i in item.specs) {
          obj[i] = item.specs[i];
        }
        obj.stock = item.stock;
        obj.uuid = item.uuid;
        this.gridData.push(obj);
      });
      this.headerList = row.attrkey.map((item) => item.name);
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList(this.currentClass);
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    addClass(index) {
      this.currentClass = index;
      this.getList(index)
    },
    rangeNumber(val) {
      console.log(val);
    },
    handleChange(value) {
      console.log(value);
    },
    changeInventory(row) {
      this.inventoryForm.specs_uuid = row.uuid;
      this.inventoryDialog = true;
    },
    operateInventory() {
      this.$refs.inventoryForm.validate((valid) => {
        if (valid) {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.inventoryForm,
            url: ` https://time.api.boyalife.cn/v1/cms/GoodsStock`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.inventoryDialog = false;
              this.dialogTableVisible = false;
              this.getList(this.currentClass);
              for (let i in this.inventoryForm) {
                this.inventoryForm[i] = "";
              }
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    cancel() {
      for (let i in this.inventoryForm) {
        this.inventoryForm[i] = "";
      }
      this.inventoryDialog = false;
    },
    getCount() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCount?search_status=${this.customerGroupForm.status}&search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.countData = res.data.result;
          this.tabs = [
            `全部(${this.countData.total})`,
            `出售中(${this.countData.on_sale})`,
            `已下架(${this.countData.available_sale})`,
            `已售完(${this.countData.sold_out})`,
          ];
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取商品分类
    getData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategoryTree`,
      })
        .then((res) => {
          this.options = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList(status) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Goods?status=${status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_position=${this.customerGroupForm.search_position}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
          // this.tableData.data.map(item => {
          //   item.attr = []
          //   let obj = {}
          //   item.specs.map(jtem => {
          //     for (let i in jtem.specs) {
          //       obj[i] = jtem.specs[i]
          //     }
          //     item.attr.push(obj)
          //   })

          // })
          // console.log(this.tableData.data)
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList(this.currentClass);
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList(this.currentClass);
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.inventoryManage .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  position: relative;
  padding-top: 80px;
}
.dataTable .tabs {
  position: absolute;
  top: 35px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
