<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>短信模板</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <div class="tips">
        <i class="el-icon-warning" style="color: #5b8fd9"></i>
        短信模版申请成功后，可选择模版发送短信至客户
      </div>
      <div class="text-right mg-t-20 flex space-between mg-b-20">
        <div class="flex align-center">
          <el-form :model="customerGroupForm" label-width="100">
            <el-form-item>
              <el-select
                v-model="customerGroupForm.region"
                placeholder="全部状态"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <p class="color-999 font-12 mg-l-10">
            <i class="el-icon-refresh"></i>同步
            <el-tooltip
              class="item"
              effect="dark"
              content="可同步在阿里云平台上以往创建的短信模板"
              placement="top-start"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </p>
        </div>
        <div>
          <el-button type="primary" @click="pushSms">短信推送</el-button>
          <el-button
            type="primary"
            @click="builtNewTemplateDialogVisible = true"
            >新建模板</el-button
          >
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="TemplateName" label="模版名称" align="center">
        </el-table-column>
        <el-table-column prop="TemplateCode" label="模板code" align="center">
        </el-table-column>
        <el-table-column prop="AuditStatus" label="状态" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.AuditStatus == 'AUDIT_STATE_PASS'">
              <span class="circle pass"></span>审核通过
            </p>
            <p v-else><span class="circle unpass"></span>审核失败</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CreateDate" label="创建时间">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="checkInfo" type="text" size="small"
              >详情</el-button
            >
            <el-button
              @click="$router.push('/editProtocol')"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <smsPushDialog :dialogVisible="dialogVisible" @closeDialog="closeDialog" />
    <builtNewTemplateDialog
      :builtNewTemplateDialogVisible="builtNewTemplateDialogVisible"
      @closeDialog="closeDialog"
    />
  </div>
</template>
<script>
import smsPushDialog from "./smsPushDialog.vue";
import builtNewTemplateDialog from "./builtNewTemplateDialog.vue";

export default {
  name: "smsTemplate",
  components: {
    smsPushDialog,
    builtNewTemplateDialog,
  },
  data() {
    return {
      dialogVisible: false,
      builtNewTemplateDialogVisible: false,
      customerGroupForm: {},
      tableData: [],
    };
  },
  created() {
    this.getSmsTemplate();
  },
  methods: {
    pushSms() {
      this.dialogVisible = true;
      this.getSmsTemplateSelect();
    },
    getSmsTemplateSelect() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/SmsSignSelect`,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/SmsTemplate${mark}${queryStry}`,
      })
        .then((res) => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete") {
            this.getSmsTemplate();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getSmsTemplate() {
      let data = {};
      let queryStry = ``;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    deleted() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        showConfirmButton: false,
        cancelButtonText: "关闭",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    closeDialog(v) {
      this.dialogVisible = v;
      this.builtNewTemplateDialogVisible = v;
    },
    checkInfo() {
      let html = `
          <h5 class="flex mg-b-10"><span style="display: inline-block;width: 80px;text-align: right;">模版类型：</span><p style="width: 70%"> 通知类</p></h5>
          <h5 class="flex mg-b-10"><span style="display: inline-block;width: 80px;text-align: right;">模版名称：</span><p style="width: 70%">发货通知收货人</p></h5>
          <h5 class="flex mg-b-10"><span style="display: inline-block;width: 80px;text-align: right;">模版code： </span><p style="width: 70%">SMS1232133321</p></h5>
          <h5 class="flex mg-b-10"><span style="display: inline-block;width: 80px;text-align: right;">模版内容：</span><p style="width: 70%">尊敬的\${name}，运单\${order_code}已发货，请近几天保持电话畅通，以便配送人员联系，祝您生活愉快。</p></h5>
          <h5 class="flex mg-b-10"><span style="display: inline-block;width: 80px;text-align: right;">申请说明：</span><p style="width: 70%">这里是申请说明，这里是申请说明，这里是申请说明，这里是申请说明，这里是申请说明，</p></h5>
        `;
      this.$confirm(html, "模板详情", {
        cancelButtonText: "关闭",
        dangerouslyUseHTMLString: true,
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getSmsTemplate();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getSmsTemplate();
    },
  },
};
</script>
<style scoped>
.tips {
  padding: 5px 10px;
  background: #e7f0ff;
  border: 1px solid #7db3f2;
  line-height: 35px;
  font-size: 12px;
}
.tips >>> .el-button span {
  font-size: 12px;
  margin-left: 10px;
  display: inline-block;
}
>>> .el-form-item {
  margin-bottom: 0;
}
.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 2px;
}
.circle.pass {
  background: #5ac724;
}
.circle.unpass {
  background: #f95036;
}
.circle.passing {
  background: #999999;
}
.color-999.mg-l-10 {
  cursor: pointer;
}
</style>
