<template>
  <div class="morningPaperList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>商品</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品分类</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container boxLayout">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getCommodityCategoty"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="text-right mg-b-20">
        <span>分类开关</span>
        <el-switch
          class="mg-l-20"
          v-model="able_comment"
          active-value="1"
          inactive-value="2"
          @change="isComment"
          active-color="#13ce66"
          inactive-color="gray"
        >
        </el-switch>
        <el-button type="primary" class="mg-l-20" @click="add('levelOne')"
          >添加商品分类</el-button
        >
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="uuid"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="分类名称" width="180">
        </el-table-column>
        <el-table-column
          prop="number"
          label="商品数量"
          sortable
          :align="'center'"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="status" :align="'center'" label="是否显示">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status === 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" :align="'center'" label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="400"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.fid == 0"
              @click="add('levelTwo', scope.row)"
              >添加下级分类</el-button
            >
            <template>
              <el-button
                type="text"
                v-if="scope.row.fid != 0"
                @click="edit('levelTwo', scope.row)"
                >编辑</el-button
              >
              <el-button type="text" v-else @click="edit('levelOne', scope.row)"
                >编辑</el-button
              >
            </template>
            <el-button type="text" @click="toTop(scope.row.uuid)"
              >置顶</el-button
            >
            <el-button type="text" @click="moveUp(scope.row.uuid)"
              >上移</el-button
            >
            <el-button type="text" @click="moveDown(scope.row.uuid)"
              >下移</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="showOrHide(scope.row)"
              >隐藏</el-button
            >
            <el-button type="text" v-else @click="showOrHide(scope.row)"
              >显示</el-button
            >
            <el-button type="text" @click="deleted(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="类别"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-width="80px" class="mg-t-20">
        <el-form-item label="类别名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上级类别" v-if="addType == 'levelTwo'">
          <el-select v-model="form.fid" placeholder="请选择">
            <el-option
              placeholder="请选择"
              v-for="item in tableData"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="addItemCategory">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "commodityCategoty",
  data() {
    return {
      uuid: "",
      editType: "",
      able_comment: '1',
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
      },
      addType: "",
      form: {
        name: "",
        status: 1,
        fid: "",
      },
      dialogFormVisible: false,
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getCommodityCategoty();
    this.getSet()
  },
  methods: {
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
    },
    getSet () {
      this.axios({
        method: 'get',//请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get('__token'),
          "Authorization": `Bearer ${this.storage.get('__token')}`
        },
     
        url: `https://time.api.boyalife.cn/v1/cms/Settings/get`,
      }).then(res => {
        console.log(res)
        this.able_comment = res.data.result.cate_show
      }).catch(err=> {
        this.$message.error(err.response.data.error.message)
      })
    },
    // 是否允许用户评论
    isComment() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          id: 1,
          cate_show: this.able_comment,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Settings/edit`,
      })
        .then((res) => {
          this.$message.success("设置成功！");
          this.getSet()
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    add(type, row) {
      this.addType = type;
      if (type == "levelTwo" && row !== undefined) {
        this.form.fid = row.uuid;
      }
      this.editType = "add";
      this.dialogFormVisible = true;
    },
    edit(type, row) {
      this.dialogFormVisible = true;
      this.uuid = row.uuid;
      this.editType = "edit";
      this.addType = type;
      this.form.name = row.name;
      if (type == "levelOne") {
        this.form.fid = "0";
      } else {
        this.form.fid = row.fid;
      }
    },
    cancel() {
      this.form.name = "";
      this.form.fid = "";
      this.dialogFormVisible = false;
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategory${mark}${queryStry}`,
      })
        .then((res) => {
          console.log(res);
          this.addType = "";
          this.dialogFormVisible = false;
          this.form.name = "";
          this.form.fid = "";

          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null && typeof res.data.result != "boolean") {
            this.tableData = res.data.result;
          }
          if (methods === "delete" || methods === "post" || methods === "put") {
            this.getCommodityCategoty();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getCommodityCategoty() {
      let queryStry = `search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}`;
      this.getData("get", {}, queryStry, "?", this.tableData);
    },
    // 置顶
    toTop(uuid) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategory/top/${uuid}`,
      })
        .then((res) => {
          this.$message.success("置顶成功！");
          this.getCommodityCategoty();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 上移
    moveUp(uuid) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategory/moveup/${uuid}`,
      })
        .then(() => {
          this.$message.success("上移成功！");
          this.getCommodityCategoty();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 下移
    moveDown(uuid) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategory/movedown/${uuid}`,
      })
        .then(() => {
          this.$message.success("下移成功！");
          this.getCommodityCategoty();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    showOrHide(row) {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status: row.status == 1 ? 2 : 1,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategory/status/${row.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
          this.getCommodityCategoty();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 新增
    addItemCategory() {
      let data = {};
      if (this.addType == "levelOne") {
        this.form.fid = "0";
      } else {
        this.form.fid = this.form.fid;
      }
      data = { ...this.form };
      if (this.editType == "add") {
        this.getData("post", data, "", "", this.tableData, "新增成功！");
      } else {
        this.getData("put", data, `${this.uuid}`, "/", {}, "编辑成功！");
      }
    },
    deleted(row) {
      this.uuid = row.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.getData("delete", {}, `${this.uuid}`, "/", {}, "删除成功！");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
>>> .el-table .el-button {
  width: auto;
  margin-right: 10px;
}

>>> .el-dialog .el-form {
  width: 400px;
}
</style>
