<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>修改密码</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="旧密码" prop="old_password">
          <el-input
            v-model="basicInfoForm.old_password"
            :type="oldType"
            placeholder="请输入"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-view"
              @click="toggleShow('old')"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="new_password">
          <el-input
            v-model="basicInfoForm.new_password"
            :type="newType"
            placeholder="请输入"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-view"
              @click="toggleShow('new')"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码确认" prop="again_new_password">
          <el-input
            v-model="basicInfoForm.again_new_password"
            :type="againType"
            placeholder="请输入"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-view"
              @click="toggleShow('again_new')"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPassword('form')"
            >立即创建</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "changePassword",
  data() {
    return {
      oldType: "password",
      newType: "password",
      againType: "password",
      basicInfoForm: {
        new_password: "",
        old_password: "",
        again_new_password: "",
      },
      rules: {
        old_password: {
          required: true,
          message: "请输入旧密码",
          trigger: "blur",
        },
        new_password: {
          required: true,
          message: "请输入新密码",
          trigger: "blur",
        },
        again_new_password: {
          required: true,
          message: "请再次输入新密码",
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    // 修改密码
    resetPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.basicInfoForm.new_password !==
            this.basicInfoForm.again_new_password
          ) {
            this.$message.error("输入的新密码不一致");
            return false;
          } else {
            delete this.basicInfoForm.again_new_password;
          }
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/AdminUserResetPassword/1`,
          })
            .then((res) => {
              console.log(res);
              // this.tableData = res.data.result
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    toggleShow(type) {
      if (type == "old") {
        if (this.oldType == "password") {
          this.oldType = "text";
        } else {
          this.oldType = "password";
        }
      } else if (type == "new") {
        if (this.newType == "password") {
          this.newType = "text";
        } else {
          this.newType = "password";
        }
      } else {
        if (this.againType == "password") {
          this.againType = "text";
        } else {
          this.againType = "password";
        }
      }
    },
  },
};
</script>
<style scoped>
.el-form {
  width: 400px;
}
</style>
