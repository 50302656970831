<template>
  <div class="addProject">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>商品</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/virtualGoodsList'"
        >虚拟商品列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>添加虚拟商品</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout">
      <el-steps
        :space="'50%'"
        :active="active"
        finish-status="success"
        align-center
      >
        <el-step title="编辑商品基本信息"></el-step>
        <el-step title="编辑商品详情"></el-step>
        <el-step title="编辑商品参数"></el-step>
        <el-step title="编辑售后保障"></el-step>
      </el-steps>
    </div>
    <div class="info mg-t-20" v-if="active === 0">
      <el-form
        ref="basicInfoForm"
        :model="basicInfoForm"
        label-width="100px"
        :rules="rules"
      >
        <div class="boxLayout">
          <h3 class="title-t mg-b-30">基础信息</h3>
          <el-form-item label="商品图片">
            <span slot="label"><span style="color: red">*</span>商品图片</span>
            <uploadImagesBtn @getImageUrl="getImageUrl" />
            <el-button  type="text"  @click="chooseBanners">从素材库选择</el-button>
            <div
              class="cover_image_list flex"
              v-if="basicInfoForm.image.length > 0"
            >
              <draggable v-model="basicInfoForm.image" group="image">
                <div
                  class="img"
                  v-for="(item, index) in basicInfoForm.image"
                  :key="index"
                >
                  <i class="el-icon-delete" @click.stop="deleteImg(index)"></i>
                  <el-image :src="item" fit="cover"></el-image>
                </div>
              </draggable>
            </div>
            <p class="font-12 color-999">
              建议尺寸：800*800像素，拖拽图片可以调整顺序，最多上传15张
            </p>
          </el-form-item>
          <el-form-item label="商品视频">
            <el-button class="avatar-uploader video" @click="chooseVideo">
              <video v-if="basicInfoForm.video !== ''" width="178px" controls>
                <source :src="basicInfoForm.video" />
              </video>
              <i
                class="el-icon-delete"
                v-if="basicInfoForm.video !== ''"
                @click.stop="basicInfoForm.video = ''"
              ></i>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-button>
            <p class="font-12 color-999">视频最长支持10分钟</p>
          </el-form-item>
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="basicInfoForm.name"></el-input>
          </el-form-item>
          <el-form-item label="商品编码" prop="code">
            <el-input v-model="basicInfoForm.code"></el-input>
          </el-form-item>
          <el-form-item label="商品分类" prop="category_uuid">
            <el-select
              v-model="basicInfoForm.category_uuid"
              placeholder="请选择"
            >
              <el-option-group
                v-for="group in options"
                :key="group.uuid"
                :label="group.name"
                :value="group.uuid"
              >
                <el-option
                  v-for="item in group.children"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item label="投放人群" prop="group_uuid">
            <el-select
              multiple
              collapse-tags
              v-model="basicInfoForm.group_uuid"
              placeholder="请选择"
            >
              <el-option
                v-for="(jtem, i) in userGroupList"
                :key="i"
                :label="jtem"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加标签" prop="label_uuid">
            <el-tag v-for="tag in tags" :key="tag.uuid" :type="tag.type">
              {{ tag.name }}
            </el-tag>
            <span
              style="color: cornflowerblue; cursor: pointer"
              @click="labelDialogVisible = true"
            >
              <span v-if="tags.length > 0">+ 编辑标签</span>
              <span v-else>+ 添加标签</span>
            </span>
          </el-form-item>
          <el-form-item label="选择品牌" prop="supplier_uuid">
            <el-select
              v-model="basicInfoForm.supplier_uuid"
              placeholder="请选择"
            >
              <el-option
                v-for="jtem in brandList"
                :key="jtem.uuid"
                :label="jtem.name"
                :value="jtem.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="boxLayout mg-t-20">
          <h3 class="title-t mg-b-20">卡券信息</h3>
          <el-form-item label="卡券类型">
            <el-radio-group v-model="basicInfoForm.coupon_type">
              <el-radio :label="1">系统优惠券</el-radio>
              <el-radio :label="2">第三方卡券</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选择卡券" v-if="basicInfoForm.coupon_type == 1">
            <div class="flex addBtn">
              <el-button
                type="primary"
                @click="selectCouponDialogVisible = true"
                size="mini"
                >选择优惠券</el-button
              >
            </div>
            <el-table
              :data="couponList"
              border
              v-if="couponList.length > 0"
              style="width: 400px"
            >
              <el-table-column type="index" label="序号" width="55">
              </el-table-column>
              <el-table-column prop="name" label="优惠券名称"></el-table-column>
              <el-table-column prop="number" label="优惠券数量">
                <template slot-scope="scope">
                  <span
                    >{{ scope.row.used_num + scope.row.getted_num }}/{{
                      scope.row.usable
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="deleted(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="零售价" prop="price">
            <el-input v-model="basicInfoForm.price"></el-input>
          </el-form-item>
          <el-form-item label="划线价" prop="crossed_price">
            <el-input v-model="basicInfoForm.crossed_price"></el-input>
            <el-checkbox
              class="mg-l-20"
              v-model="basicInfoForm.show_crossed_price"
              >显示划线价</el-checkbox
            >
          </el-form-item>
          <el-form-item label="库存预警值" prop="warning_value">
            <el-input v-model="basicInfoForm.warning_value"></el-input>
          </el-form-item>
          <el-form-item label="商品库存" prop="stock">
            <el-input v-model="basicInfoForm.stock"></el-input>
          </el-form-item>
          <el-form-item label="支付方式" prop="pay_type">
            <el-checkbox
              v-model="basicInfoForm.only_cash"
              :checked="true"
              disabled
              >现金</el-checkbox
            >
            <el-checkbox v-model="basicInfoForm.cash_score" @change="isSelect1"
              >现金+积分</el-checkbox
            >
            <el-checkbox
              v-model="basicInfoForm.customize_score"
              @change="isSelect"
              >自定义积分</el-checkbox
            >
          </el-form-item>
          <el-form-item
            label=""
            v-if="
              basicInfoForm.cash_score == true ||
              basicInfoForm.customize_score == true
            "
          >
            <el-input
              v-model="basicInfoForm.score"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">分</template></el-input
            >
            <br />
          </el-form-item>
          <el-form-item label="销量" prop="sales_number">
            <span>{{ basicInfoForm.sales_number }}</span>
          </el-form-item>
        </div>

        <div class="boxLayout mg-t-20">
          <h3 class="title-t mg-b-20">其它设置</h3>
          <el-form-item label="上架设置">
            <el-radio-group v-model="basicInfoForm.status_type">
              <el-radio :label="1">立即上架</el-radio>
              <el-radio :label="2">暂不上架</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="开售时间">
            <el-radio-group v-model="basicInfoForm.shelf_type">
              <el-radio :label="1">立即开售</el-radio>
              <el-radio :label="2">定时开售</el-radio>
              <el-date-picker
                v-model="basicInfoForm.shelf_time"
                v-if="basicInfoForm.shelf_type == 2"
                type="datetime"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                default-time="12:00:00"
              >
              </el-date-picker>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="限购">
            <el-checkbox v-model="basicInfoForm.is_purchase_limit"
              >设置每人限购数量</el-checkbox
            >
            <el-input
              v-model="basicInfoForm.purchase_limit"
              class="ipt mg-l-20"
              placeholder="请输入"
              ><template slot="prepend">件</template></el-input
            >
          </el-form-item>
          <el-form-item label="隐藏销量">
            <el-checkbox v-model="basicInfoForm.hide_sales"
              >不显示该商品销量</el-checkbox
            >
            <el-input
              v-model="basicInfoForm.sales_number"
              class="ipt mg-l-20"
              placeholder="请输入"
              ><template slot="prepend">件</template></el-input
            >
            <br />
          </el-form-item>
          <el-form-item label="隐藏商品">
            <el-checkbox v-model="basicInfoForm.hide_goods"
              >设置商品不在商城中显示</el-checkbox
            >
          </el-form-item>
          <el-form-item label="商品推广">
            <el-checkbox v-model="basicInfoForm.goods_extend"
              >允许插入资讯内容中推广</el-checkbox
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="info boxLayout mg-t-20" v-show="active === 1">
      <h3 class="title-t mg-b-30">商品详情</h3>
      <wangEditorTool
        ref="detailEditor"
        :content="basicInfoForm.goods_detail"
      />
    </div>
    <div class="info boxLayout mg-t-20" v-show="active === 2">
      <h3 class="title-t mg-b-30">商品参数</h3>
      <wangEditorTool
        ref="parameterEditor"
        :content="basicInfoForm.goods_parameter"
      />
    </div>
    <div class="info boxLayout mg-t-20" v-show="active === 3">
      <h3 class="title-t mg-b-30">售后保障</h3>
      <wangEditorTool ref="saleEditor" :content="basicInfoForm.after_sale" />
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" v-if="active !== 0" @click="toPrev"
        >上一步</el-button
      >
      <el-button type="primary" v-if="active !== 3" size="mini" @click="toNext"
        >下一步</el-button
      >
      <el-button
        type="primary"
        size="mini"
        v-if="active === 3"
        @click="submitForm('basicInfoForm')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <selectCoupon
      :coupons="couponList"
      :dialogVisible="selectCouponDialogVisible"
      @confirmCoupon="confirmCoupon"
      @closeDialog="closeDialog"
    />
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @selectImg="selectImg"
        @insertVideoHandler="insertVideoHandler"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择标签"
      :visible.sync="labelDialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <markLabel
        @closeDialog="closeLabelDialog"
        :tags="tags"
        :type="'addCustomer'"
      />
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogImageVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
      @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
   
    </el-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import selectCoupon from "../../../components/selectCoupon.vue";
import markLabel from "../../../components/markLabel.vue";
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import wangEditorTool from "../../../components/wangEditorTool.vue";
import uploadImagesBtn from "../../../components/uploadImagesBtn.vue";
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addProject",
  components: {
    selectCoupon,
    MaterialLibrary,
    wangEditorTool,
    markLabel,
    draggable,
    uploadImagesBtn,
    imageDialog
  },
  data() {
    return {
      dialogImageVisible: false,
      active: 0,
      selectCouponDialogVisible: false,
      labelDialogVisible: false,
      dialogVisible: false,
      inputVisible: false,
      inputValue: "",
      insertImgType: "",
      title: "",
      tags: [],
      imgType: "",
      // 添加规格
      specificateList: [],
      userGroupList: [],
      brandList: [],
      couponList: [],
      options: [],
      basicInfoForm: {
        image: [],
        video: "",
        name: "",
        code: "",
        category_uuid: "",
        group_uuid: [],
        label_uuid: [],
        goods_extend: false,
        goods_detail: "",
        goods_parameter: "",
        after_sale: "",
        only_cash: false,
        coupon_type: 1,
        coupon: [],
        price: "",
        crossed_price: "",
        show_crossed_price: false,
        stock: "",
        warning_value: "",
        pay_type: "",
        score: "",
        status_type: 1,
        shelf_type: 1,
        shelf_time: "",
        sales_number: 0,
        is_purchase_limit: false,
        purchase_limit: "",
        hide_goods: false,
        hide_sales: false,
        supplier_uuid: "",
        cash_score: false,
        customize_score: false,
      },
      rules: {
        category_uuid: {
          required: true,
          message: "请选择商品分类",
          trigger: "change",
        },
        name: { required: true, message: "请输入", trigger: "blur" },
        price: { required: true, message: "请输入", trigger: "blur" },
        stock: { required: true, message: "请输入", trigger: "blur" },
      },
    };
  },
  created() {
    this.getBrand();
    this.getUserGroup();
    this.getData();
    if (this.$route.query.uuid) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/VirtualGoods/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          let d = res.data.result;
          for (let i in d) {
            this.basicInfoForm[i] = d[i];
          }
          this.basicInfoForm.image = this.basicInfoForm.image.map(
            (item) => item.image
          );
          if (this.basicInfoForm.group.length > 0) {
            this.basicInfoForm.group_uuid = this.basicInfoForm.group.map(
              (item) => {
                return item.uuid;
              }
            );
          }
          this.couponList = this.basicInfoForm.coupon;
          this.basicInfoForm.only_cash =
            this.basicInfoForm.only_cash == 1 ? true : false;
          this.basicInfoForm.cash_score =
            this.basicInfoForm.cash_score == 1 ? true : false;
          this.basicInfoForm.customize_score =
            this.basicInfoForm.customize_score == 1 ? true : false;
          this.basicInfoForm.show_crossed_price =
            this.basicInfoForm.show_crossed_price == 1 ? true : false;
          this.basicInfoForm.hide_sales =
            this.basicInfoForm.hide_sales == 1 ? true : false;
          this.basicInfoForm.hide_goods =
            this.basicInfoForm.hide_goods == 1 ? true : false;
          this.basicInfoForm.is_purchase_limit =
            this.basicInfoForm.is_purchase_limit == 1 ? true : false;
          this.basicInfoForm.goods_extend =
            this.basicInfoForm.goods_extend == 1 ? true : false;
          this.basicInfoForm.coupon_type = Number(
            this.basicInfoForm.coupon_type
          );
          this.basicInfoForm.status_type = Number(
            this.basicInfoForm.status_type
          );
          this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);

          if (this.basicInfoForm.shelf_type == 1) {
            this.basicInfoForm.shelf_time = null;
          }
          this.basicInfoForm.shelf_time = new Date(
            this.basicInfoForm.shelf_time
          );
          this.tags = this.basicInfoForm.label;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    }
  },
  methods: {
    chooseBanners () {
      this.dialogImageVisible = true
    },
    closeImageDialog () {
      this.dialogImageVisible = false
    },
    getImageUrl (item) {
      this.basicInfoForm.image.push(item)
    },
    confirmImageDialog (item) {
      item.map(i => {
        this.basicInfoForm.image.push(i.url)
      })
      this.dialogImageVisible = false

    },
    // 删除图片
    deleteImg(index) {
      this.basicInfoForm.image.splice(index, 1);
    },
    isSelect(v) {
      if (this.basicInfoForm.customize_score == true) {
        this.basicInfoForm.cash_score = false;
      }
    },
    isSelect1(v) {
      console.log(v);
      if (this.basicInfoForm.cash_score == true) {
        this.basicInfoForm.customize_score = false;
      }
    },
    deleted(index) {
      this.couponList.splice(index, 1);
    },
    closeLabelDialog(v) {
      this.tags = JSON.parse(JSON.stringify(v));
      this.labelDialogVisible = false;
    },
    // 选择banner
    chooseBanner() {
      this.title = "选择图片";
      this.imgType = "chooseThumbnail_image";
      this.insertImgType = "projectImg";
      this.dialogVisible = true;
    },

    chooseVideo() {
      this.imgType = "chooseVideo";
      this.dialogVisible = true;
      this.title = "选择视频";
    },
    selectImg(item) {
      this.dialogVisible = false;
      if (this.insertImgType == "projectImg") {
        this.basicInfoForm.image.push(item.url);
      } else {
        this.slotProps.row.image = item.url;
      }
    },
    insertVideoHandler(url) {
      this.dialogVisible = false;
      this.basicInfoForm.video = url;
    },
    toPrev() {
      this.active--;
      if (this.active == 0) {
        this.basicInfoForm.goods_detail = this.$refs.detailEditor.html;
      } else if (this.active == 1) {
        this.basicInfoForm.goods_parameter = this.$refs.parameterEditor.html;
      } else if (this.active == 2) {
        this.basicInfoForm.after_sale = this.$refs.saleEditor.html;
      }
    },
    toNext() {
      if (this.active == 0) {
        this.$refs.basicInfoForm.validate((valid) => {
          if (valid) {
            if (this.basicInfoForm.image.length < 1) {
              this.$message.error("请至少上传一张项目图片");
              return false;
            } else {
              this.active++;
            }
          } else {
            return false;
          }
        });
      } else {
        if (this.active == 1) {
          this.basicInfoForm.goods_detail = this.$refs.detailEditor.html;
        }
        if (this.active == 2) {
          this.basicInfoForm.goods_parameter = this.$refs.parameterEditor.html;
        }
        this.active++;
      }
    },
    confirmCoupon(row) {
      this.couponList = row;
      this.selectCouponDialogVisible = false;
    },
    closeDialog() {
      this.selectCouponDialogVisible = false;
    },
    // 获取商品分类
    getData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsCategoryTree`,
      })
        .then((res) => {
          this.options = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getBrand() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/SupplierTree`,
      })
        .then((res) => {
          this.brandList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`,
      })
        .then((res) => {
          this.userGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submitForm(form) {
      if (this.tags.length > 0) {
        this.basicInfoForm.label_uuid = this.tags.map((item) => {
          return item.uuid;
        });
      }
      this.basicInfoForm.only_cash =
        this.basicInfoForm.only_cash == true ? 1 : 2;
      this.basicInfoForm.cash_score =
        this.basicInfoForm.cash_score == true ? 1 : 2;
      this.basicInfoForm.customize_score =
        this.basicInfoForm.customize_score == true ? 1 : 2;
      this.basicInfoForm.hide_sales =
        this.basicInfoForm.hide_sales == true ? 1 : 2;
      this.basicInfoForm.after_sale = this.$refs.saleEditor.html;
      this.basicInfoForm.goods_extend =
        this.basicInfoForm.goods_extend == true ? 1 : 2;
      this.basicInfoForm.show_crossed_price =
        this.basicInfoForm.show_crossed_price == true ? 1 : 2;
      this.basicInfoForm.is_purchase_limit =
        this.basicInfoForm.is_purchase_limit == true ? 1 : 2;
      this.basicInfoForm.hide_goods =
        this.basicInfoForm.hide_goods == true ? 1 : 2;
      this.basicInfoForm.hide_sales =
        this.basicInfoForm.hide_sales == true ? 1 : 2;
      this.basicInfoForm.coupon = this.couponList.map((item) => item.uuid);

      if (!this.$route.query.uuid) {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: this.basicInfoForm,
          url: ` https://time.api.boyalife.cn/v1/cms/VirtualGoods`,
        })
          .then((res) => {
            this.$message.success("新增成功！");
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$message.success(err);
          });
      } else {
        delete this.basicInfoForm.category;
        delete this.basicInfoForm.delete_time;
        delete this.basicInfoForm.create_time;
        delete this.basicInfoForm.group;
        delete this.basicInfoForm.publish_time;
        delete this.basicInfoForm.update_time;

        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: this.basicInfoForm,
          url: ` https://time.api.boyalife.cn/v1/cms/VirtualGoods/${this.$route.query.uuid}`,
        })
          .then((res) => {
            this.$message.success("新增成功！");
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$message.success(err);
          });
      }
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addProject {
  margin-bottom: 80px;
}
.info >>> .el-form .el-form-item__content > .el-input {
  width: 300px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.specificationsBox {
  padding: 20px;
  border: 1px dashed rgb(223, 223, 223);
}
.addBtn {
  padding-bottom: 20px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.bdb {
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(232, 232, 232);
}
>>> .ipt.el-input {
  width: 300px !important;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete,
.video .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -24px;
  bottom: 0px;
}
.el-form-item .avatar-uploader {
  padding: 10px;
}
.el-tag + .el-tag {
  margin-left: 0;
}
.el-tag {
  margin-right: 5px;
}
.el-table .avatar {
  width: 50px;
  height: 50px;
}
.cover_image_list > div {
  flex-wrap: wrap;
  margin-top: 20px;
  display: flex;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
</style>
