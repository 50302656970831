<template>
  <div class="dialog-container" v-show="visible">
    <div class="bg-layout"></div>
    <div class="dialog">
      <div class="boxLayout">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="客户等级">
            <el-select
              collapse-tags
              v-model="customerGroupForm.search_level"
              placeholder="请选择"
            >
              <el-option
                v-for="(jtem, i) in levelList"
                :key="i"
                :label="jtem"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户标签">
            <el-cascader
              v-model="label"
              :options="labelList"
              @change="getLabelValue"
              :emitPath="false"
              :props="props"
              collapse-tags
              :show-all-levels="false"
              filterable
            ></el-cascader>
            <!-- <el-cascader
              v-model="label"
              :options="labelList"
              @change="getLabelValue"
              :props="props"
              collapse-tags
              :show-all-levels="false"
              clearable></el-cascader> -->
          </el-form-item>
          <el-form-item label="关键字" class="keyword">
            <el-input
              placeholder="请输入用户ID/名称/手机/邮箱"
              v-model="customerGroupForm.search_keyword"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getList"
            >查询</el-button
          >
          <el-button size="medium" @click="clear">清空</el-button>
        </div>
      </div>
      <h3 class="mg-l-10 mg-b-10">
        已选 <span style="color: orange">{{ selectNum }} </span>人
      </h3>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        row-key="getRowKeys"
      >
        <el-table-column type="selection" :align="'center'" width="55">
        </el-table-column>
        <el-table-column label="客户头像/昵称" :align="'center'" width="160">
          <template slot-scope="scope">
            <router-link :to="'/customerInfo'">
              <div class="nickAndAvatar">
                <el-image :src="scope.row.avatar"></el-image>
                <p>
                  <span>{{ scope.row.nick_name }}</span> <br />
                </p>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="注册手机号码" :align="'center'">
        </el-table-column>
        <el-table-column prop="level.name" label="客户等级" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="label"
          label="客户标签"
          :align="'center'"
          show-overflow-tooltip
        >
          <template slot-scope="scope" v-if="scope.row.label.length > 0">
            <el-tag
              type="success"
              v-for="item in scope.row.label"
              :key="item.uuid"
              >{{ item.name }}&nbsp;/&nbsp;</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="consume_number"
          label="累计消费次数"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="consume_price"
          label="累计消费金额"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="user_role" label="客户身份" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.user_role == 1">游客</span>
            <span v-else-if="scope.row.user_role == 2">会员</span>
            <span v-else>推广员</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <div class="dialog-footer text-right mg-t-20">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "./pagination.vue";
export default {
  name: "",
  components: {
    pagination,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: "",
    },
    customer: [],
  },
  watch: {
    dialogVisible(n) {
      this.visible = n;
    },
    customer(n) {
      if (n.length > 0) {
        this.getList();
      }
    },
    source(n) {
      if (n != "") {
        this.$nextTick(() => {
          this.getList();
        });
      }
    },
  },
  data() {
    return {
      visible: false,
      selectNum: 0,
      selectRows: [],
      label: "",
      customerGroupForm: {
        search_level: "",
        search_label: "",
        search_keyword: "",
      },
      props: {
        label: "name",
        value: "uuid",
        children: "label",
      },
      labelList: [],
      levelList: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getLabel();
    this.getLevel();
  },
  methods: {
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.label = "";
      this.getList();
    },
    handleClose() {
      this.visible = false;
      this.$emit("cancel", this.visible);
    },
    confirm() {
      this.$emit("confirmCustomer", this.selectRows);
    },
    handleSelectionChange(rows) {
      this.selectNum = rows.length;
      this.selectRows = rows;
    },
    getLabelValue(v) {
      this.customerGroupForm.search_label = v[v.length - 1];
    },
    getList() {
      let condition = "";
      if (this.source != "") {
        condition = `&search_is_real_name=1&search_user_role=2`;
      } else {
        condition = "";
      }
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/User?search_level=${this.customerGroupForm.search_level}&search_label=${this.customerGroupForm.search_label}&search_keyword=${this.customerGroupForm.search_keyword}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}${condition}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
          if (this.customer && this.customer.length > 0) {
            console.log(this.customer);
            this.selectRows = this.customer;
            let uuids = this.selectRows.map((i) => i.uuid);
            this.tableData.data.forEach((row) => {
              if (uuids.includes(row.uuid)) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(row, true);
                });
              }
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getLevel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getLabel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabelGroupTree`,
      })
        .then((res) => {
          this.labelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getRowKeys(row) {
      return row.uuid;
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
>>> .el-dialog {
  min-width: 1000px;
}
.el-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
