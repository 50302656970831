<template>
  <div class="newMemberRewards">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>营销管理</el-breadcrumb-item>
      <el-breadcrumb-item>新会员奖励设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t">基础信息</h3>
      <el-form :model="basicInfoForm" label-width="120px">
        <el-form-item label="是否启用" prop="status">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="奖励内容" prop="name">
          <el-checkbox
            v-model="basicInfoForm.is_score"
            label="积分奖励"
          ></el-checkbox>
          <el-input
            v-if="basicInfoForm.is_score == true"
            v-model="basicInfoForm.score"
            class="ipt"
            placeholder="请输入"
            ><template slot="prepend">币</template></el-input
          >
          <br />
          <el-checkbox
            label="优惠券（多选）"
            v-model="basicInfoForm.is_coupon"
          ></el-checkbox>
          <el-button
            type="text"
            @click="selectCouponDialogVisible = true"
            v-if="basicInfoForm.is_coupon == true"
            >+ 选择优惠券</el-button
          >
          <el-table :data="couponList" border v-if="couponList.length > 0">
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="优惠券名称"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="活动头图">
          <span slot="label">活动头图 <i style="color: red">*</i></span>
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.banner != ''"
              :src="basicInfoForm.banner"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：800*800像素，限制一张</p>
        </el-form-item>
        <el-form-item label="活动说明">
          <wangEditorTool
            ref="editors"
            :content="basicInfoForm.desc"
            v-if="basicInfoForm.desc !== ''"
          />
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog  :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"/>
   
    </el-dialog>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit">保存</el-button>
    </div>
    <selectCoupon
      :coupons="couponList"
      :releaseType="'6'"
      :dialogVisible="selectCouponDialogVisible"
      @confirmCoupon="confirmCoupon"
      @closeDialog="closeDialog"
    />
  </div>
</template>
<script>
import wangEditorTool from "../../../components/wangEditorTool.vue";
import selectCoupon from "../../../components/selectCoupon.vue";
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "newMemberRewards",
  components: { wangEditorTool, imageDialog, selectCoupon },
  data() {
    return {
      dialogVisible: false,
      selectCouponDialogVisible: false,
      couponList: [],
      imgType: "",
      basicInfoForm: {
        status: 2,
        is_score: "",
        is_coupon: "",
        score: "",
        banner: "",
        desc: "",
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
    confirmImageDialog (item) {
      this.basicInfoForm.banner = item[0].url
      this.dialogVisible = false

    },
    httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.banner =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/NewAward/1`,
      })
        .then((res) => {
          let a = res.data.result;
          for (let i in a) {
            this.basicInfoForm[i] = a[i];
          }
          this.couponList = this.basicInfoForm.coupon;
          this.basicInfoForm.status = Number(this.basicInfoForm.status);
          this.basicInfoForm.is_score =
            this.basicInfoForm.is_score == 1 ? true : false;
          this.basicInfoForm.is_coupon =
            this.basicInfoForm.is_coupon == 1 ? true : false;
          this.basicInfoForm.desc =
            this.basicInfoForm.desc == "" ? "<br>" : this.basicInfoForm.desc;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择封面
    selectImg(item) {
      this.basicInfoForm.banner = item.url;
      this.dialogVisible = false;
    },
    confirmCoupon(row) {
      this.couponList = row;
      this.selectCouponDialogVisible = false;
      this.basicInfoForm.coupon_uuid = row.map((item) => item.uuid);
    },
    closeDialog() {
      this.selectCouponDialogVisible = false;
    },
    submit() {
      this.basicInfoForm.desc = this.$refs.editors.html;
      if (this.basicInfoForm.is_coupon == false) {
        this.basicInfoForm.coupon_uuid = [];
      }
      if (this.basicInfoForm.banner == "") {
        this.$message.error("活动头图必传");
        return false;
      }
      this.basicInfoForm.is_score = this.basicInfoForm.is_score == true ? 1 : 2;
      this.basicInfoForm.is_coupon =
        this.basicInfoForm.is_coupon == true ? 1 : 2;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/NewAward/1`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.getInfo();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.newMemberRewards {
  margin-bottom: 80px;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
