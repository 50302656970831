<template>
  <div class="orderInfo">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/commodityOrder'">订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout" v-if="Object.keys(info).length > 0">
      <div class="top">
        <template v-if="info.status == 1">
          <h2 class="color-333">商品已拍下，等待买家付款</h2>
          <p class="font-12 mg-t-10 mg-b-20">
            如买家未在<span style="color: rgb(234, 79, 79)">12分34秒</span
            >内付款，订单将按自动关闭。
          </p>
        </template>

        <template v-if="info.status == 2">
          <h2 class="color-333">商品已付款，等待卖家发货</h2>
          <p class="font-12 mg-t-10 mg-b-20">买家已付款，请尽快安排发货。</p>
        </template>
        <template v-if="info.status == 3 && info.is_all_send == 0">
          <h2 class="color-333">部分商品已发货</h2>
          <p class="font-12 mg-t-10 mg-b-20">买家已付款，请尽快安排发货。</p>
        </template>
        <template v-if="info.status == 3 && info.is_all_send != 0">
          <h2 class="color-333">卖家已发货，等待买家确认收货</h2>
          <p class="font-12 mg-t-10 mg-b-20">
            如买家未在<span style="color: rgb(234, 79, 79)"
              >6天23小时59分钟22秒</span
            >没有申请退款，交易将自动完成。
          </p>
        </template>
        <template v-if="info.status == 4">
          <h2 class="color-333">交易成功</h2>
          <p class="font-12 mg-t-10 mg-b-20">买家已确认收货，交易已完成。</p>
        </template>
        <template v-if="info.status == 5">
          <h2 class="color-333">交易已完成</h2>
          <!-- <p class="font-12 mg-t-10 mg-b-20">支付超时自动关闭。</p> -->
        </template>
        <el-steps
          :space="'50%'"
          :active="active"
          simple
          finish-status="success"
          align-center
        >
          <el-step title="提交订单"></el-step>
          <el-step title="买家付款"></el-step>
          <el-step title="商家发货"></el-step>
          <el-step title="买家确认收货"></el-step>
        </el-steps>
        <div class="flex mg-t-20">
          <p>买家备注：</p>
          <p>-</p>
        </div>

        <div class="remark">
          <div class="flex align-center" style="width: 100%">
            <p>卖家备注：</p>
            <el-tooltip
              class="item"
              effect="dark"
              v-if="remark"
              :content="remark"
              placement="top"
            >
              <p class="p">{{ remark }}</p>
            </el-tooltip>
            <el-button
              type="text"
              class="font-14"
              @click="addRemark"
              v-if="!remark"
              >添加备注</el-button
            >

            <el-button type="text" class="font-14" @click="addRemark" v-else
              >编辑</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="info boxLayout mg-t-20" v-if="Object.keys(info).length > 0">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <div class="flex">
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">
            收货人信息
            <el-button type="text" @click="showDialog('editInfo')"
              >编辑</el-button
            >
          </h4>
          <p>收货人: {{ info.name }}</p>
          <p>联系电话: {{ info.mobile }}</p>
          <p>
            收货地址: {{ info.province_name }}{{ info.city_name
            }}{{ info.area_name }}{{ info.address }}
          </p>
          <p>配送方式: 快递配送</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">推广信息</h4>
          <p v-if="info.fxy1_name != null">一级推广人：{{ info.fxy1_name }}</p>
          <p v-if="info.fxy2_name != null">二级推广人：{{ info.fxy2_name }}</p>
        </div>
        <div class="mg-r-50 mg-l-20">
          <h4 class="mg-b-10 font-16">订单信息</h4>
          <p>
            订单编号: {{ info.order_sn }}
            <el-button type="text" @click="onCopy">复制</el-button>
          </p>
          <p>
            下单账号: {{ info.nick_name }}
            <el-button
              type="text"
              @click="$router.push('/customerInfo?uuid=' + info.user_uuid)"
              >客户详情</el-button
            >
          </p>
          <p>下单时间: {{ info.create_time }}</p>
          <p v-if="info.status == 1">订单状态：等待买家付款</p>
          <p v-else-if="info.status == 2">订单状态：等待卖家发货</p>
          <p v-else-if="info.status == 3">订单状态：已发货</p>
          <p v-else-if="info.status == 4" style="color: red">
            订单状态：退款售后
          </p>
          <p v-else-if="info.status == 5" style="color: green">
            订单状态：已完成
          </p>
          <p v-else>订单状态：已关闭</p>
          <template
            v-if="info.status == 2 || info.status == 3 || info.status == 5"
          >
            <p>付款时间: {{ info.pay_time }}</p>
            <p>付款金额: ￥{{ info.price }}</p>
            <p>支付方式: 微信支付</p>
          </template>
        </div>
      </div>
    </div>
    <div class="contractInfo boxLayout mg-t-20" v-if="deliveryList.length > 0">
      <h3 class="title-t mg-b-20">物流信息</h3>
      <el-table
        ref="multipleTable"
        :data="deliveryList"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="index" label="包裹" :align="'center'" width="55">
        </el-table-column>
        <el-table-column prop="style" label="配送方式" :align="'center'">
          <template slot-scope=""> 快递物流 </template>
        </el-table-column>
        <el-table-column
          label="商品信息"
          :align="'center'"
          show-overflow-tooltip
          width="260"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar" v-if="scope.row.goods.length > 0">
              <div>
                <p
                  class="ellipsis"
                  v-for="(item, index) in scope.row.goods"
                  :key="item.uuid"
                >
                  商品{{ index + 1 }}：{{ item.name }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="number" label="商品数量" :align="'center'">
          <template slot-scope="scope">
            <p>{{ scope.row.goods.length }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="delivery_status"
          label="物流状态"
          :align="'center'"
        >
        </el-table-column>

        <el-table-column
          prop="delivery_name"
          label="快递公司"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="code" label="物流单号" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="发货时间"
          sortable
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="admin_name" label="操作人" :align="'center'">
        </el-table-column>
        <el-table-column label="操作" align="center" width="140">
          <template slot-scope="scope">
            <el-button
              @click="checkDelivery(scope.row)"
              type="text"
              size="small"
            >
              查看物流
            </el-button>
            <el-button
              v-if="scope.row.delivery_status != '签收'"
              @click="send('change', scope.row)"
              type="text"
              size="small"
            >
              修改物流
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="contractInfo boxLayout mg-t-20">
      <h3 class="title-t mg-b-20">商品</h3>
      <el-table
        ref="multipleTable"
        :data="goodsList.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="商品信息" :align="'center'" width="260">
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image :src="scope.row.image"></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="retail_price"
          label="是否赠品"
          :align="'center'"
        >
          <template slot-scope="scope">
            <p>{{ Number(scope.row.retail_price) == 0 ? '是' : '否' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="retail_price" label="单价" :align="'center'">
          <template slot-scope="scope">
            ￥{{ scope.row.retail_price }}
          </template>
        </el-table-column>
        <el-table-column prop="goods[0].number" label="数量" :align="'center'">
          <template slot-scope="scope">
            {{ scope.row.number }}
          </template>
        </el-table-column>
        <el-table-column
          prop="label"
          label="优惠"
          :align="'center'"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="fx_price1"
          label="一级分销佣金"
          :align="'center'"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.fx_price1">￥{{ scope.row.fx_price1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="fx_price2"
          label="二级分销佣金"
          :align="'center'"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.fx_price2">￥{{ scope.row.fx_price2 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price_real" label="小计" :align="'center'">
        </el-table-column>
        <el-table-column prop="status_name" label="发货状态" :align="'center'">
        </el-table-column>
        <el-table-column prop="is_after" label="售后服务" :align="'center'">
          <template slot-scope="scope">
            <span>{{ scope.row.is_after == 0 ? "否" : "是" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="info.status != 4 && info.status != 5 && info.status != 6"
              type="text"
              size="small"
              @click="refundMoney(scope.row)"
            >
              退款
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="text-right mg-t-20" v-if="goodsList.data.length > 0">
        <p>商品总价：￥{{ Number(goodsList.data[0].retail_price) }}</p>
        <p>运费：￥{{ info.freight }}</p>
        <p>
          优惠：￥{{
            Number(goodsList.data[0].retail_price) * Number(goodsList[0].number) -
              Number(goodsList.data[0].price_real) * Number(goodsList[0].number)
          }}
        </p>
        <p>佣金：¥0.00</p>
        <p class="font-18">
          应付款：<span style="color:tomato;"
            >￥{{
              Number(goodsList[0].price_real) * Number(goodsList[0].number)
            }}</span
          >
        </p>
      </div> -->
    </div>
    <div class="operateBtn">
      <el-button
        size="mini"
        type="primary"
        v-if="info.is_all_send == 0 && info.status != 4 && info.status != 5 && info.status != 6"
        @click="send('send')"
        >发货</el-button
      >
      <!--  <el-button
        size="mini"
        type="primary"
        v-if="active === 2"
        @click="send('change')"
        >修改物流</el-button
      > -->
      <!-- <el-button size="mini" type="primary" v-if="active === 2">处理退款</el-button> -->
      <el-button size="mini" v-if="active === 0">关闭订单</el-button>
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <checkInfoDialog
      :cancelBtnText="'取消'"
      :confirmBtnText="confirmBtnText"
      :visible="checkInfoDialogVisible"
      :title="title"
      :showCancelBtn="true"
      :showConfirmBtn="true"
      @closeDialog="closeDialog"
      @confirmDialog="confirmDialog"
    >
      <el-form :model="infoForm" label-width="100px">
        <div class="editInfo mg-t-20" v-if="dialogType === 'editInfo'">
          <el-form-item label="收货人姓名">
            <el-input v-model="infoForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="infoForm.mobile"></el-input>
          </el-form-item>
          <el-form-item label="所在地区">
          <el-cascader
            v-model="value"
            :options="cityList"
            @change="getCityValue"
            :props="props"
            collapse-tags
            clearable
          ></el-cascader>
        </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="infoForm.address"></el-input>
          </el-form-item>
        </div>
        <div class="sign mg-t-20" v-if="dialogType === 'sign'">
          <el-form-item label="提醒内容">
            <p>您预约的服务还没有签约，超时订单将自动关闭，请及时签约哦～</p>
          </el-form-item>
          <el-form-item label="发送方式">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="system">系统消息</el-checkbox>
              <el-checkbox label="chat">聊天</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <!-- 物流信息 -->
        <div class="logisticsInfo" v-if="dialogType === 'logistics'">
          <el-timeline :reverse="false">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :icon="index === 0 ? 'el-icon-truck' : 'el-icon-more'"
              :color="index === 0 ? '#0bbd87' : '#d3d1d1'"
              :size="index === 0 ? 'large' : 'normal'"
              :timestamp="activity.timestamp"
            >
              <p class="font-12 color-333">{{ activity.status }}</p>
              <p class="font-12 color-666">{{ activity.content }}</p>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-form>
    </checkInfoDialog>
    <el-dialog
      title="发货"
      :visible.sync="sendVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="send mg-t-20">
        <p>共{{ unSendGoods.length }}件商品</p>
        <el-table
          ref="sendTables"
          border
          :data="unSendGoods"
          class="mg-b-20 sendTable"
          row-key="getRowKeys"
          type="selection"
          @selection-change="sendHandleSelectionChange"
        >
          <el-table-column
            type="selection"
            border
            :selectable="selectTableUuids"
            width="55"
          >
          </el-table-column>
          <el-table-column
            label="商品信息"
            :align="'center'"
            show-overflow-tooltip
            width="260"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.name }}</p>
              <!-- <p
                v-for="(item, index) in JSON.parse(scope.row.specs)"
                :key="index"
              >
                {{ item }}
              </p> -->
            </template>
          </el-table-column>
          <el-table-column property="number" label="数量" align="center">
          </el-table-column>
          <el-table-column property="status" align="center" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">未发货</span>
              <span v-else>已发货</span>
            </template>
          </el-table-column>
          <el-table-column
            property="delivery.name"
            align="center"
            label="物流公司"
          >
          </el-table-column>
          <el-table-column
            property="delivery.code"
            label="物流单号"
            align="center"
            width="200"
          >
          </el-table-column>
        </el-table>
        <el-form
          ref="sendInfoForm"
          v-if="sendInfoForm.goods_uuid.length > 0"
          :model="sendInfoForm"
          :rules="sendInfoRules"
          label-width="80px"
          style="width: 400px"
        >
          <el-form-item label="物流公司" prop="delivery_uuid">
            <el-select v-model="sendInfoForm.delivery_id" placeholder="请选择">
              <el-option
                v-for="(item, i) in deliveryCompanyList"
                :key="i"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运单号" prop="code">
            <el-input v-model="sendInfoForm.delivery_code"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="sendInfoForm.delivery_remark"
              type="textarea"
              resize="none"
              rows="5"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeSendDialog">关闭</el-button>
        <el-button
          size="mini"
          v-if="sendInfoForm.goods_uuid.length > 0"
          type="primary"
          @click="sumbitDelivery('sendInfoForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="退款" :visible.sync="dialogVisible" width="30%">
      <h5 class="mg-l-30 mg-b-10">退卡方式: 仅退款</h5>
      <el-form
        :model="form"
        :rules="rules"
        label-width="93px"
        ref="form"
      >
        <el-form-item label="退款原因" prop="reason_uuid">
          <el-select v-model="form.reason_uuid" placeholder="请选择">
            <el-option v-for="item in resonOption" :key="item.uuid" :label="item.content" :value="item.uuid" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退款金额" prop="refund_price">
          <el-input type="number" v-model="form.refund_price" />
          <p>最多退款 ￥{{maxPrice * number}}</p>
        </el-form-item>
        <el-form-item label="退款备注" prop="admin_remark">
          <el-input type="textarea" rows="5" v-model="form.admin_remark" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "orderInfo",
  components: {
    checkInfoDialog,
  },
  data() {
    return {
      active: 0,
      dialogVisible: false,
      value: '',
      cityList: [],
      sendVisible: false,
      selectableData: [],
      unSendGoods: [],
      sendGoods: [],
      remark: "",
      checkList: [],
      deliveryCompanyList: [],
      checkInfoDialogVisible: false,
      dialogType: "",
      confirmBtnText: "确定",
      title: "编辑合同信息",
      info: {},
      form: {
        uuid: '',
        reason_uuid: '',
        admin_remark: '',
        refund_price: ''
      },
      maxPrice: '',
      number: 1,
      rules: {
        reason_uuid: {
          required: true,
          message: "请选择",
          trigger: "change",
        },refund_price: {
          required: true,
          message: "请输入",
          trigger: "change",
        },
      },
      infoForm: {
        uuid: '',
        name: '',
        mobile: '',
        address: '',
        province_uuid: '',
        city_uuid: '',
        area_uuid: ''
      },
      sendInfoForm: {
        delivery_id: "",
        delivery_code: "",
        delivery_remark: "",
        goods_uuid: [],
        
      },
      sendInfoRules: {
        delivery_id: {
          required: true,
          message: "请选择物流公司",
          trigger: "change",
        },
        delivery_code: {
          required: true,
          message: "请输入物流单号",
          trigger: "input",
        },
      },
      uuid: "",
      tableData: [],
      deliveryList: [],
      goodsList: [],
      activities: [],
      props: {
        label: "area_name",
        value: "area_id",
      },
      resonOption: []
    };
  },
  created() {
    this.getInfo(this.$route.query.uuid);
    this.infoForm.uuid = this.$route.query.uuid
    this.getDeliveryList();
    this.getGoods();
    this.getCity()
    this.getAfterReasonList()
  },
  methods: {
    // 退款
    refundMoney (item) {
      console.log(item)
      this.dialogVisible = true
      this.maxPrice = Number(item.retail_price)
      this.number = Number(item.number)
      this.form.uuid = item.uuid

    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: `https://time.api.boyalife.cn/v1/cms/GoodsOrder/refund`,
            })
              .then((res) => {
                this.$message.success("退款成功！");
                for (let i in this.form) {
                  this.form[i] = ''
                }
                this.dialogVisible = false;
                this.getInfo();
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
    
      });
    },
    getAfterReasonList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: `https://time.api.boyalife.cn/v1/cms/AfterReason?page_size=999&page_index=1`,
      })
        .then((res) => {
          this.resonOption = res.data.result.data;
          console.log(this.resonOption)
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCityValue(v) {
      console.log(v)
      this.infoForm.province_uuid = v[0];
      this.infoForm.city_uuid = v[1];
      this.infoForm.area_uuid = v[2];
    },
    showDialog(type) {
      this.dialogType = type;
      this.checkInfoDialogVisible = true;
      if (type === "editInfo") {
        this.confirmBtnText = "确定";
        this.title = "编辑客户信息";
        this.infoForm.name = this.info.name
        this.infoForm.mobile = this.info.mobile
        this.infoForm.province_uuid = this.info.province_uuid
        this.infoForm.area_uuid = this.info.area_uuid
        this.infoForm.city_uuid = this.info.city_uuid
        this.infoForm.address = this.info.address
        this.value = [this.info.province_uuid,this.info.city_uuid,this.info.area_uuid]
        console.log(this.value)
      } else if (type === "sign") {
        this.confirmBtnText = "确定发送";
        this.title = "提醒买家付款";
      } else if (type === "send") {
        this.confirmBtnText = "确定";
        this.title = "订单发货";
      } else if (type === "logistics") {
        this.confirmBtnText = "确定";
        this.title = "物流信息";
      }
    },
    confirmDialog () {
      if(this.dialogType == 'editInfo') {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: this.infoForm,
          url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrder/editaddress`,
        })
          .then((res) => {
            this.$message.success('编辑成功!')
            this.checkInfoDialogVisible = false
            this.getInfo(this.$route.query.uuid)

          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      }
    },
     // 地区
     getCity() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Region`,
      })
        .then((res) => {
          this.cityList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取快递
    getDelivery() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: `https://time.api.boyalife.cn/v1/app/ExpressList`,
      })
        .then((res) => {
          this.deliveryCompanyList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取商品列表
    getGoods() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrderGoods?order_uuid=${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.goodsList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    send(type) {
      this.uuid = this.$route.query.uuid;
      this.sendType = type;
      this.sendVisible = true;
      this.getDelivery();
      if (type == "send") {
        let uuids = this.info.goods.map((i) => {
          if (i.status == 2) {
            return i.goods_uuid;
          }
        });
        let that = this;
        this.info.goods.forEach((item) => {
          if (uuids.includes(item.goods_uuid)) {
            that.$nextTick(() => {
              that.$refs.sendTables.toggleRowSelection(item, true);
            });
          }
        });
      }

      this.unSendGoods = this.info.goods;
      console.log(this.unSendGoods);
      this.selectableData = JSON.parse(JSON.stringify(this.info.goods));
    },
    closeSendDialog() {
      this.sendVisible = false;
      for (let i in this.sendInfoForm) {
        this.sendInfoForm[i] = "";
      }
    },
    // 表格勾选置灰
    selectTableUuids(row) {
      if (this.unSendGoods.length > 0 && this.sendType == "send") {
        //判断容器表里是否有数据
        //有数据的话，取容器表里的id和勾选过来的id判断是否有相等

        if (
          this.unSendGoods.some(
            (item) => item.goods_uuid == row.goods_uuid && item.status == 2
          )
        ) {
          return false; //有相等的返回false ,返回false当前行的checkbox就可以置灰
        } else {
          return true; //没有相等的返回true,当前行checkbox不置灰
        }
      } else {
        return true;
      }
    },
    sumbitDelivery(form) {
      console.log(this.sendInfoForm.goods_uuid);
      this.$refs[form].validate((valid) => {
        if (valid) {
          let data = {};
          let updateData = {
            delivery_uuid: "",
            code: "",
            remark: "",
            uuids: [],
          };
          if (this.sendType == "send") {
            data = this.sendInfoForm;
          } else {
            updateData.code = this.sendInfoForm.delivery_code;
            updateData.delivery_uuid = this.sendInfoForm.delivery_id;
            updateData.remark = this.sendInfoForm.delivery_remark;
            updateData.uuids = this.sendInfoForm.goods_uuid;
          }
          if (this.sendType == "send") {
            this.getData(
              "PUT",
              data,
              this.uuid,
              "/",
              null,
              "操作成功！",
              "GoodsOrder/send"
            );
          } else {
            this.getData(
              "POST",
              updateData,
              "",
              "",
              null,
              "操作成功！",
              "GoodsOrderDelivery/update"
            );
          }
          this.sendVisible = false;
          for (let i in this.sendInfoForm) {
            this.sendInfoForm[i] = "";
          }
        } else {
          return false;
        }
      });
    },
    getData(methods, data, queryStry, mark, tableData, msg, api) {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/${api}${mark}${queryStry}`,
      })
        .then((res) => {
          if (msg !== "") {
            this.$message.success(msg);
            this.getInfo(this.$route.query.uuid);
            this.getDeliveryList();
            this.getGoods();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    closeDialog(v) {
      this.checkInfoDialogVisible = v;
    },
    
    checkDelivery(row) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrderDelivery/delivery/${row.uuid}`,
      })
        .then((res) => {
          this.showDialog("logistics");
          this.activities = [];
          res.data.result.data.map((item, index) => {
            let obj = {};

            obj.status = item.context;
            obj.timestamp = item.time;
            this.activities.push(obj);
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    updateDelivery(row) {
      this.sendVisible = true;
      console.log(this.unSendGoods);
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrderDelivery/update`,
      })
        .then((res) => {})
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    getInfo(uuid) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: `https://time.api.boyalife.cn/v1/cms/GoodsOrder/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.info = res.data.result;
          this.tableData = res.data.result.goods;
          this.remark = this.info.seller_remark;
          this.active = Number(this.info.status) - 1;
          // this.goodsList = this.info.goods;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getDeliveryList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrderDelivery?order_uuid=${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.deliveryList = res.data.result.data;
          console.log(this.deliveryList);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    sendHandleSelectionChange(val) {
      this.sendInfoForm.goods_uuid = [];

      val.map((item) => {
        console.log(item.status);
        if (item.status != 2 && this.sendType == "send") {
          this.sendInfoForm.goods_uuid.push(item.goods_uuid);
        }

        if (item.status != 2 && this.sendType != "send") {
          this.sendInfoForm.goods_uuid.push(item.delivery_uuid);
        }
      });
      console.log(this.sendInfoForm.goods_uuid);
    },

    //  添加备注
    addRemark() {
      const msg = this.remark !== "" ? "编辑" : "添加";
      this.$prompt("备注信息", msg + "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputPlaceholder: this.remark,
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      }).then(({ value }) => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            uuids: this.$route.query.uuid,
            remark: value,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/GoodsOrder/remark`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
            this.remark = value;
            this.getInfo();
          })
          .catch((err) => {
            this.$message.erroe(err);
          });
      });
    },
  },
};
</script>
<style scoped>
.orderInfo {
  margin-bottom: 80px;
}
.top .remark .el-button {
  padding: 0 10px;
}
>>> .el-dialog {
  width: 800px !important;
}
>>> .el-step__title.is-process {
  color: #409eff;
}
>>> .el-step.is-simple .el-step__icon {
  border-color: #409eff;
}
>>> .el-step__head.is-wait .el-step__icon {
  border-color: #c0c4cc;
}
>>> .el-step__head.is-success .el-step__icon {
  border-color: #67c23a;
}
>>> .el-button--text {
  padding: 5px 0px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 22px;
  max-height: 22px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
>>> .el-timeline-item {
  margin-left: 200px;
  padding-top: 20px;
}
>>> .el-timeline-item__timestamp.is-bottom {
  width: 140px;
  position: absolute;
  right: 100%;
  top: 0;
}
>>> .el-timeline-item__node--large {
  width: 25px;
  height: 25px;
  left: -7px;
}
>>> .el-timeline-item__node--large .el-timeline-item__icon {
  font-size: 17px;
}
.logisticsInfo {
  max-height: 400px;
  overflow: auto;
}
</style>
