<template>
  <div class="projectEvaluateList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>项目</el-breadcrumb-item>
      <el-breadcrumb-item>评价</el-breadcrumb-item>
      <el-breadcrumb-item>评价管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="显示状态">
          <el-select
            v-model="customerGroupForm.search_status"
            placeholder="请选择"
          >
            <el-option label="显示" :value="1"></el-option>
            <el-option label="不显示" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getItemCommentList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="项目名称" :align="'center'" width="260">
          <template slot-scope="scope">
            <div class="product flex">
              <el-image fit="cover" :src="scope.row.item.cover"></el-image>
              <div style="width: 190px" class="text-left">
                <el-tooltip :content="scope.row.item.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.item.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="项目评分"
          prop="quality"
          :align="'center'"
          width="200"
          sortable
        >
          <template slot-scope="scope">
            <div class="flex align-center">
              <p>质量：</p>
              <el-rate
                v-model="scope.row.quality"
                score-template="{scope.row.quality}"
                disabled
                :colors="colors"
              >
              </el-rate>
            </div>
            <div class="flex align-center">
              <p>服务：</p>
              <el-rate
                v-model="scope.row.service"
                score-template="{scope.row.service}"
                disabled
                :colors="colors"
              >
              </el-rate>
            </div>
            <div class="flex align-center">
              <p>推荐：</p>
              <el-rate
                v-model="scope.row.exponent"
                score-template="{scope.row.exponent}"
                disabled
                :colors="colors"
              >
              </el-rate>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="客户" :align="'center'">
          <template slot-scope="scope">
            <router-link
              :to="'/customerInfo?uuid=' + scope.row.user.uuid"
              target="_blank"
            >
              <div style="margin: 0 auto; width: 150px">
                <div class="flex align-center">
                  <el-image
                    fit="cover"
                    v-if="scope.row.user != null && scope.row.user.avatar"
                    :src="scope.row.user.avatar"
                  ></el-image>
                  <div class="text-left">
                    <p>{{ scope.row.user.nick_name }}</p>
                    <p>{{ scope.row.user.mobile }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="是否显示" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >显示</span
            >
            <span v-else>不显示</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="发布时间"
          :align="'center'"
          sortable
          width="150"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="250"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >置顶</el-button
            >
            <el-button type="text" @click="checkInfo(scope.row)"
              >评价详情</el-button
            >
            <el-button
              type="text"
              @click="$router.push('/cellInfo?uuid=' + scope.row.order_uuid)"
              >订单详情</el-button
            >
            <el-button type="text">隐藏</el-button>
            <el-button type="text" @click="deleteEvaluate">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="评价详情" :visible.sync="dialogVisible" width="30%">
      <div class="dialogs" v-if="Object.keys(commentInfo).length > 0">
        <div class="flex space-between">
          <div class="flex avatar" style="flex: 1">
            <el-image
              class="img"
              v-if="commentInfo.user != null && commentInfo.user.avatar != null"
              :src="commentInfo.user.avatar"
            ></el-image>
            <div class="mg-l-10">
              <p style="margin-left: 11px">{{ commentInfo.user.nick_name }}</p>
              <p class="font-10 color-999">{{ commentInfo.create_time }}</p>
            </div>
          </div>
          <div>
            <p class="text-right">{{ commentInfo.quality }}星</p>
            <el-rate
              v-model="commentInfo.quality"
              score-template="{commentInfo.quality}"
              disabled
              :colors="colors"
            >
            </el-rate>
          </div>
        </div>
        <p class="font-12 mg-t-10 mg-b-10" v-html="commentInfo.content"></p>

        <div class="imgs">
          <el-image
            fit="cover"
            class="commentImg mg-r-10"
            v-for="item in commentInfo.images"
            :key="item"
            :src="item.url"
          ></el-image>
        </div>

        <p class="font-12 color-666 mg-t-10">
          商品:{{ commentInfo.item.name }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "projectEvaluateList",
  components: {
    pagination,
  },
  data() {
    return {
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        search_status: "",
      },
      dialogVisible: false,
      value: 5,
      commentInfo: {},
      imgs: [
        "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__84f78bae4cddf5a665809315656f99cf.svg",
        "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__21adab465da8deb8b27e917559e883b4.svg",
        "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__a74ba5d381c943891f0908d7dc409adb.svg",
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getItemCommentList();
  },
  methods: {
    checkInfo(row) {
      this.dialogVisible = true;
      this.commentInfo = row;
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getItemCommentList();
    },
    getData(methods, data, queryStry, mark, tableData, msg = "") {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/ItemComment${mark}${queryStry}`,
      })
        .then((res) => {
          console.log(res);
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (tableData !== null) {
            this.tableData = res.data.result;
          }
          if (methods === "delete") {
            this.getItemCommentList();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 列表
    getItemCommentList() {
      let data = {};
      let queryStry = `search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}&search_status=${this.customerGroupForm.search_status}`;
      this.getData("get", data, queryStry, "?", this.tableData);
    },
    deleteEvaluate() {
      this.$confirm("确定删除该评价吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "成功删除!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getItemCommentList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getItemCommentList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.projectEvaluateList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.dialogs .imgs {
  padding: 20px 0;
}
.imgs .commentImg {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
.avatar .img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
</style>
