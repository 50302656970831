<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>配送服务</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/freightTemplate' }"
        >运费模板</el-breadcrumb-item
      >
      <el-breadcrumb-item>添加运费模板</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-20">基本信息</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="140px">
        <el-form-item label="模板名称" prop="name" :required="true">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" prop="status" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="计费方式" prop="type">
          <el-radio-group v-model="basicInfoForm.type">
            <el-radio :label="1">按重量</el-radio>
            <el-radio :label="2">按件数</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="指定地区运费">
          <el-button type="text" @click="addFreight('add')"
            >+ 添加指定地区运费</el-button
          >
          <div
            class="freight mg-b-20"
            v-for="(item, index) in basicInfoForm.area"
            :key="index"
            v-if="basicInfoForm.type == 1 && basicInfoForm.area.length > 0"
          >
            <div class="flex top space-between align-center">
              <p>{{ item.trueLabel.join(",") }}</p>
              <div>
                <el-button
                  type="text"
                  @click="addFreight('edit', item.province_uuid, index)"
                  >编辑</el-button
                >
                <el-button type="text" @click="deletedFreight(index)"
                  >删除</el-button
                >
              </div>
            </div>
            <div class="flex mg-t-20">
              <el-form-item
                label-width="0"
                :prop="`area[${index}].within_kg`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.within_kg"></el-input> KG以内
              </el-form-item>
              <el-form-item
                label-width="0"
                :prop="`area[${index}][within_kg_money]`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="item.within_kg_money"></el-input> 元；
              </el-form-item>
              <el-form-item
                label-width="0"
                :prop="`area[${index}][increase_kg]`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
              >
                每增加<el-input v-model="item.increase_kg"></el-input> KG，
              </el-form-item>
              <el-form-item
                label-width="0"
                :prop="`area[${index}][increase_kg_money]`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
              >
                运费增加<el-input v-model="item.increase_kg_money"></el-input>
                元；
              </el-form-item>
            </div>
          </div>
          <div
            class="freight mg-b-20"
            v-for="(item, index) in basicInfoForm.area"
            :key="index"
            v-if="basicInfoForm.type == 2 && basicInfoForm.area.length > 0"
          >
            <div class="flex top space-between align-center">
              <p>{{ item.trueLabel.join(",") }}</p>
              <div>
                <el-button
                  type="text"
                  @click="addFreight('edit', item.province_uuid, index)"
                  >编辑</el-button
                >
                <el-button type="text" @click="deletedFreight(index)"
                  >删除</el-button
                >
              </div>
            </div>
            <div class="flex mg-t-20">
              <el-form-item
                label-width="0"
                :prop="`area[${index}][within_num]`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
                ><el-input v-model="item.within_num"></el-input>
                件以内</el-form-item
              >
              <el-form-item
                label-width="0"
                :prop="`area[${index}].within_num_money`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
                ><el-input v-model="item.within_num_money"></el-input>
                元；</el-form-item
              >
              <el-form-item
                label-width="0"
                :prop="`area[${index}][increase_num]`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
                >每增加<el-input v-model="item.increase_num"></el-input>
                件，</el-form-item
              ><el-form-item
                label-width="0"
                :prop="`area[${index}][increase_num_money]`"
                :rules="{
                  required: true,
                  message: '不能为空',
                  trigger: 'blur',
                }"
                >运费增加<el-input v-model="item.increase_num_money"></el-input>
                元；</el-form-item
              >
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="boxLayout layout" :class="{ toTop: dialogVisible }">
      <div class="bj"></div>
      <div class="box">
        <h2 class="mg-b-20 mg-t-20">选择省份</h2>
        <el-select
          value-key="area_id"
          multiple
          collapse-tags
          v-model="group_uuid"
          placeholder="请选择"
          @change="selectPro"
        >
          <el-option
            v-for="jtem in provinceoptions"
            :key="jtem.area_id"
            :label="jtem.area_name"
            :value="jtem"
          >
          </el-option>
        </el-select>

        <p class="text-center mg-t-20">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmSelectArea">确 定</el-button>
        </p>
      </div>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "addFreightTemplate",
  data() {
    return {
      dialogVisible: false,
      index: "",
      type: "",
      group_uuid: [],
      provinceoptions: [],
      basicInfoForm: {
        name: "",
        status: "",
        type: 1,
        area: [],
      },

      rules: {
        name: { required: true, message: "请填写", trigger: "blur" },
      },
      selectProUuids: [],
      fakeLabel: [],
      trueLabel: [],
      options: [],
    };
  },
  created() {
    this.getProvince();
    if (this.$route.query.uuid) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Freight/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          let d = res.data.result;
          this.basicInfoForm.name = d.name;
          this.basicInfoForm.status = Number(d.status);
          this.basicInfoForm.type = Number(d.type);
          this.basicInfoForm.area = d.area;
          d.area.map((item, i) => {
            this.basicInfoForm.area[i].trueLabel = item.province.map(
              (jtem) => jtem.area_name
            );
            this.basicInfoForm.area[i].province_uuid = item.province.map(
              (jtem) => jtem.area_id
            );
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    }
  },
  methods: {
    selectPro(v) {
      console.log(v);
      this.fakeLabel = v.map((item) => item.area_name);
      this.selectProUuids = v.map((item) => item.area_id);
    },
    addFreight(str, arr, index) {
      this.index = index;
      this.type = str;
      this.dialogVisible = true;
      if (str == "add") {
        this.group_uuid = [];
      } else {
        console.log(arr);
        this.group_uuid = [];
        arr.map((item) => {
          this.provinceoptions.map((jtem) => {
            if (item == jtem.area_id) {
              this.group_uuid.push(jtem);
            }
          });
        });
      }
    },

    confirmSelectArea() {
      this.dialogVisible = false;
      if (this.type == "add") {
        this.basicInfoForm.area.push({
          trueLabel: this.fakeLabel,
          province_uuid: this.selectProUuids,
        });
      } else {
        this.basicInfoForm.area[this.index].trueLabel = this.fakeLabel;
        this.basicInfoForm.area[this.index].province_uuid = this.selectProUuids;
        console.log(this.basicInfoForm.area[this.index].province_uuid);
      }
    },
    deletedFreight(index) {
      this.basicInfoForm.area.splice(index, 1);
    },
    getProvince() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Province?parent_id=0`,
      })
        .then((res) => {
          console.log(res);
          this.provinceoptions = JSON.parse(JSON.stringify(res.data.result));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log(valid);
          this.basicInfoForm.area.map((item) => {
            delete item.trueLabel;
            if (this.basicInfoForm.type == 1) {
              delete item.within_num;
              delete item.within_num_money;
              delete item.increase_num;
              delete item.increase_num_money;
            } else {
              delete item.within_kg;
              delete item.within_kg_money;
              delete item.increase_kg;
              delete item.increase_kg_money;
            }
          });

          if (!this.$route.query.uuid) {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Freight`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.basicInfoForm.area.map((item) => {
              delete item.province;
              delete item.uuid;
              delete item.freight_uuid;
            });
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Freight/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.freight {
  padding: 10px 20px;
  background: rgb(252, 247, 247);
  width: 70%;
}
.freight .top {
  padding: 5px 0;
  border-bottom: 1px solid rgb(236, 236, 236);
}
.flex .el-input {
  width: 100px;
  margin: 0 5px;
}
.boxLayout.layout {
  position: relative;
  z-index: -100;
  visibility: hidden;
}
.boxLayout.layout.toTop {
  z-index: 20;
  visibility: visible;
}
.bj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 7;
}
.box {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  width: 220px;
  height: 150px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
}
>>> .el-cascader-menu__wrap {
  height: 340px;
}
</style>
