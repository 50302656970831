<template>
  <!-- 添加客户 -->
  <div class="taskList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>任务</el-breadcrumb-item>
      <el-breadcrumb-item>任务设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">任务设置</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="150px">
        <el-form-item label="每日签到" prop="check_in_life">
          <el-switch
            v-model="basicInfoForm.is_check_in"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <p class="mg-t-10">
            签到完成可获得<el-input
              v-model="basicInfoForm.check_in_life"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">life币</template></el-input
            >
          </p>
          <p class="mg-t-10">
            签到完成可获得<el-input
              v-model="basicInfoForm.check_in_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
          </p>
        </el-form-item>
        <el-form-item label="每日分享" prop="share_times">
          <el-switch
            v-model="basicInfoForm.is_share"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <div class="flex align-center mg-t-10 flex-wrap">
            <p>
              每日前<el-input
                v-model="basicInfoForm.share_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              分享<el-input
                v-model="basicInfoForm.share_information"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">篇</template></el-input
              >资讯，
            </p>
            <p>
              可获得<el-input
                v-model="basicInfoForm.share_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
              <el-input
              v-model="basicInfoForm.share_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
          </p>
          </div>
        </el-form-item>
        <el-form-item label="浏览资讯" prop="is_view_information">
          <el-switch
            v-model="basicInfoForm.is_view_information"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <div class="flex align-center mg-t-10">
            <p>
              每日前<el-input
                v-model="basicInfoForm.view_information_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              浏览<el-input
                v-model="basicInfoForm.view_information_seconds"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">秒</template></el-input
              >资讯，
            </p>
            <p>
              可获得<el-input
                v-model="basicInfoForm.view_information_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
              <el-input
              v-model="basicInfoForm.view_information_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="浏览项目商城" prop="is_view_item">
          <el-switch
            v-model="basicInfoForm.is_view_item"
            active-color="#13ce66"
            :active-value="1"
            :inactive-value="2"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <div class="flex align-center mg-t-10">
            <p>
              每日前<el-input
                v-model="basicInfoForm.view_time_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              浏览<el-input
                v-model="basicInfoForm.view_time_seconds"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">秒</template></el-input
              >商城，
            </p>
            <p>
              可获得<el-input
                v-model="basicInfoForm.view_time_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
              <el-input
              v-model="basicInfoForm.view_item_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="浏览积分商城" prop="is_view_goods">
          <el-switch
            v-model="basicInfoForm.is_view_goods"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <div class="flex align-center mg-t-10">
            <p>
              每日前<el-input
                v-model="basicInfoForm.view_goods_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              浏览<el-input
                v-model="basicInfoForm.view_goods_seconds"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">秒</template></el-input
              >商城，
            </p>
            <p>
              可获得<el-input
                v-model="basicInfoForm.view_goods_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
              <el-input
              v-model="basicInfoForm.view_goods_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
          
            </p>
          </div>
        </el-form-item>
        <el-form-item label="完成项目预约" prop="is_consult">
          <el-switch
            v-model="basicInfoForm.is_consult"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <div class="flex align-center mg-t-10">
            <p>
              每日前<el-input
                v-model="basicInfoForm.consult_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              咨询<el-input
                v-model="basicInfoForm.consult_finish_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >项目，
            </p>
            <p>
              可获得<el-input
                v-model="basicInfoForm.consult_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
              <el-input
              v-model="basicInfoForm.consult_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="邀请好友注册" prop="is_invite">
          <el-switch
            v-model="basicInfoForm.is_invite"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <div class="flex align-center mg-t-10">
            <p>
              每日前<el-input
                v-model="basicInfoForm.invite_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次</template></el-input
              >
            </p>
            <p>
              邀请<el-input
                v-model="basicInfoForm.invite_finish_times"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">位</template></el-input
              >新用户，
            </p>
            <p>
              可获得<el-input
                v-model="basicInfoForm.invite_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
              <el-input
              v-model="basicInfoForm.invite_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
            </p>
          </div>
        </el-form-item>
        <el-form-item label="老会员回归任务" prop="is_old_back">
          <el-switch
            v-model="basicInfoForm.is_old_back"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <p class="font-12 color-666">启用后，满足条件的用户将获得对应奖励</p>
          <div class="flex align-center mg-t-10">
            <p>
              身份认证可获得<el-input
                v-model="basicInfoForm.back_life"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">life币</template></el-input
              >
            </p>
            <p>
              life币有效期<el-input
                v-model="basicInfoForm.back_life_limit"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
              <el-input
              v-model="basicInfoForm.back_growth"
              class="ipt"
              placeholder="请输入"
              ><template slot="prepend">成长值</template></el-input
            >
            </p>
      
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "taskList",
  data() {
    return {
      imageUrl: "",
      isShow: false,
      basicInfoForm: {
        back_life_limit: '',
        back_life: '',
        is_old_back: 2,
        check_in_life: "",
        check_in_growth: "",
        share_growth: '',
        view_information_growth: '',
        view_item_growth: '',
        invite_growth: '',
        back_growth: '',
        view_goods_growth: '',
        consult_growth: '',
        consult_finish_times: "",
        consult_life: "",
        consult_times: "",
        invite_finish_times: "",
        invite_life: "",
        invite_times: "",
        is_check_in: 2,
        is_consult: 2,
        is_invite: 2,
        is_share: 2,
        is_view_goods: 2,
        is_view_information: 2,
        is_view_item: 2,
        share_information: "",
        share_life: "",
        share_times: "",
        uuid: "1",
        view_goods_life: "",
        view_goods_seconds: "",
        view_goods_times: "",
        is_view_information: 2,
        view_information_life: "",
        view_information_seconds: "",
        view_information_times: "",
        view_time_life: "",
        view_time_seconds: "",
        view_time_times: "",
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/Task/1`,
    })
      .then((res) => {
        let a = res.data.result;
        for (let i in a) {
          this.basicInfoForm[i] = Number(a[i]);
        }
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    submit() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/Task/1`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.taskList {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
p {
  line-height: 40px;
}
.flex-wrap {
  flex-wrap: wrap;
}
</style>
