<template>
  <!-- 客户详情 -->
  <div class="promoterInfo">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>推广</el-breadcrumb-item>
      <el-breadcrumb-item>推广管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/promoterList' }"
        >推广员列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>推广详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 基础信息 -->
    <div class="basicInfo" v-if="Object.keys(userInfo).length > 0">
      <h3 class="title-t">基础信息</h3>
      <div class="top mg-t-20">
        <div class="nick">
          <el-image
            :src="
              userInfo.user.avatar == null || userInfo.user.avatar == ''
                ? defaultImg
                : userInfo.user.avatar
            "
          ></el-image>
          <span>{{ userInfo.name }}</span>
          <span class="level font-12" v-if="userInfo.level != null"
            >LV{{ userInfo.level }}</span
          >
          <el-button
            type="text"
            class="font-12"
            style="font-size: 12px; margin-left: 20px"
            @click="
              $router.push({
                name: 'addCustomer',
                query: { uuid: userInfo.user.uuid },
              })
            "
            >编辑</el-button
          >
        </div>
        <div class="remark">
          <div class="flex align-center" style="width: 100%">
            <p>备注：</p>
            <el-tooltip
              class="item"
              effect="dark"
              v-if="remark"
              :content="remark"
              placement="top"
            >
              <p class="p">{{ remark }}</p>
            </el-tooltip>
            <el-button
              type="text"
              class="font-14"
              @click="addRemark"
              v-if="!remark"
              >添加备注</el-button
            >

            <el-button type="text" class="font-14" @click="addRemark" v-else
              >编辑</el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom">
        <div>
          <p>
            手机号码：
            {{ userInfo.user.mobile == null ? "" : userInfo.user.mobile }}
          </p>
          <p>性别： {{ userInfo.user.sex }}</p>
          <p>
            生日：
            {{ userInfo.user.birthday == null ? "" : userInfo.user.birthday }}
          </p>
          <p v-if="userInfo.interest != '' && userInfo.interest != null">
            兴趣爱好：{{ userInfo.interest }}
          </p>
          <p
            v-if="userInfo.manager_name != '' && userInfo.manager_name != null"
          >
            所属销售：{{ userInfo.manager_name }}
          </p>
        </div>
        <div>
          <p>职业： {{ userInfo.job == null ? "" : userInfo.job }}</p>
          <p>
            地区：
            {{ userInfo.province == null ? "" : userInfo.province.area_name
            }}{{ userInfo.city == null ? "" : userInfo.city.area_name
            }}{{ userInfo.area == null ? "" : userInfo.area.area_name }}
          </p>
          <p
            v-if="
              userInfo.confinement_date != null &&
              userInfo.confinement_date.search('0') != 0
            "
          >
            预产期： {{ userInfo.confinement_date }}
          </p>
          <p
            v-if="
              userInfo.antenatal_hospital != '' &&
              userInfo.antenatal_hospital != null
            "
          >
            预产医院： {{ userInfo.antenatal_hospital }}
          </p>
        </div>
        <div>
          <p>注册时间： {{ userInfo.create_time }}</p>
          <p>客户来源： 小程序注册</p>
          <p>最近登录时间：{{ userInfo.user.last_login_time }}</p>
          <p>最近购买时间：{{ userInfo.user.last_consumption_time }}</p>
        </div>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <h3 class="title-t">推广信息</h3>
      <div class="flex mg-t-20">
        <div class="mg-l-50">
          <h3 class="mg-b-10">
            LV{{ userInfo.level }}推广员
            <el-button
              type="text"
              size="mini"
              @click="updateLevel(userInfo.level)"
            >
              修改等级</el-button
            >
          </h3>
          <p>提现方式: 微信</p>
          <p>提现银行: {{ userInfo.payment_bank }}</p>
          <p>银行卡号: {{ userInfo.card_number }}</p>
          <p>预留手机: {{ userInfo.mobile }}</p>
        </div>
        <div class="mg-l-50">
          <h3 class="mg-b-20">&nbsp;</h3>
          <p>注册时间: {{ userInfo.create_time }}</p>
          <p>
            推广类型: {{ userInfo.type == 1 ? "内部推广员" : "外部推广员" }}
          </p>
          <p>最近登录时间：{{ userInfo.user.last_login_time }}</p>
          <p>最近推广时间: 无字段</p>
        </div>
      </div>
    </div>
    <!-- 推广员资产 -->
    <div
      class="clientAssets mg-t-20"
      v-if="Object.keys(generalizationTotal).length > 0"
    >
      <h3 class="title-t">推广资产</h3>
      <div class="flex">
        <div class="box">
          <div class="name color-666 mg-b-20">客户数量</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.user_num }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(1)">查看</el-button>
          </div> -->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">佣金总额</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.yj_total }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="$router.push('/promotionOrderRecords')">推广记录</el-button>
          </div> -->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">佣金余额</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.yj_ye }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="$router.push('/promotionOrderRecords')">推广记录</el-button>
          </div> -->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">下级数量</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.next_user_num }}
          </div>
        </div>
      </div>
    </div>

    <!-- 交易统计 -->
    <div
      class="transactionStatistics mg-t-20"
      v-if="Object.keys(generalizationTotal).length > 0"
    >
      <h3 class="title-t">交易统计</h3>
      <div class="flex">
        <div class="box">
          <div class="name color-666 mg-b-20">累计订单数</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.order_num }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="$router.push('/promotionOrderRecords')">查看订单记录</el-button>
          </div> -->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">累计推广金额（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.order_total }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">客单价（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.kdj }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">累计退款订单数</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.refund_num }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">累计退款金额（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ generalizationTotal.refund_amount }}
          </div>
        </div>
      </div>
    </div>
    <!-- 客户列表 -->
    <div
      class="transactionStatistics mg-t-20"
      v-if="customerList.data.length > 0"
    >
      <h3 class="title-t">客户列表</h3>
      <el-table :data="customerList.data" style="width: 100%">
        <el-table-column
          prop="inviteduser.uuid"
          label="用户ID"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inviteduser.nick_name"
          label="用户昵称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="inviteduser.mobile"
          label="手机号"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="inviteduser.sex" label="性别" align="center">
        </el-table-column>
        <el-table-column prop="xfcs" label="消费次数" align="center">
        </el-table-column>
        <el-table-column prop="xfje" label="消费金额" align="center">
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="邀请时间"
          width="160"
          align="center"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="customerList.total"
        :per_page="customerList.per_page"
        :current_page="customerList.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog title="修改等级" :visible.sync="dialogLevelVisible" inline>
      <el-form :model="customerForm" class="mg-t-20">
        <el-form-item label="推广员等级">
          <el-select v-model="level" placeholder="请选择">
            <el-option label="Lv1推广员" value="1"></el-option>
            <el-option label="Lv2推广员" value="2"></el-option>
            <el-option label="Lv3推广员" value="3"></el-option>
            <el-option label="Lv4推广员" value="4"></el-option>
            <el-option label="Lv5推广员" value="5"></el-option>
            <el-option label="Lv6推广员" value="6"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogLevelVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateLevel">确 定</el-button>
      </div>
    </el-dialog>
    <div class="operateBtn">
      <el-button size="mini" type="danger" @click="blockCustomer"
        >拉黑客户</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <BigImg
      :visible="photoVisible"
      :url="bigImgUrl"
      @closeClick="
        () => {
          photoVisible = false;
        }
      "
    ></BigImg>
    <checkInfoDialog
      :cancelBtnText="cancelBtnText"
      :visible="checkInfoDialogVisible"
      :title="title"
      :showCancelBtn="showCancelBtn"
      :showConfirmBtn="showConfirmBtn"
      @closeDialog="closeDialog"
    >
      <el-form :model="dialogForm" label-width="100px">
        <!-- 查看权益 -->
        <div class="dialogContent" v-if="dialogNumber === 1">
          <div class="flex">
            <p class="mg-r-20 color-666">客户等级</p>
            <p>L1</p>
          </div>
          <div class="flex mg-t-20">
            <p class="mg-r-20 color-666">等级权益</p>
            <p>包邮；2%积分；9.9折</p>
          </div>
        </div>
        <!-- 设置等级 -->
        <div class="setLevel" v-if="dialogNumber === 2">
          <el-form-item label="客户等级">
            <el-select v-model="dialogForm.setLevel" placeholder="请选择">
              <el-option label="L1" value="shanghai"></el-option>
              <el-option label="L2" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- life币记录 -->
        <div class="pointRecord" v-if="dialogNumber === 3">
          <el-table :data="lifeCornTableData" border style="width: 100%">
            <el-table-column prop="number" label="life币数量" align="center">
            </el-table-column>
            <el-table-column prop="reason" label="操作原因" align="center">
            </el-table-column>
            <el-table-column prop="people" label="操作人" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="操作时间"
              align="center"
              width="160"
            >
            </el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 赠送life -->
        <div class="giveCoin" v-if="dialogNumber === 4">
          <el-form-item label="life币数量">
            <el-input v-model="dialogForm.num" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="dialogForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 成长值记录 -->
        <div class="pointRecord" v-if="dialogNumber === 5">
          <el-table :data="lifeCornTableData" border style="width: 100%">
            <el-table-column prop="number" label="成长值数量" align="center">
            </el-table-column>
            <el-table-column prop="reason" label="操作原因" align="center">
            </el-table-column>
            <el-table-column prop="people" label="操作人" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="操作时间"
              align="center"
              width="160"
            >
            </el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 赠送成长值 -->
        <div class="giveCoin" v-if="dialogNumber === 6">
          <el-form-item label="成长值数量">
            <el-input
              v-model="dialogForm.growthValue"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="dialogForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 优惠券记录 -->
        <div class="givecon" v-if="dialogNumber === 7">
          <el-table :data="gridData" border>
            <el-table-column
              property="name"
              label="优惠券名称"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="status"
              label="状态"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <span style="color: #2ed477">{{ scope.row.status }}</span>
              </template>
            </el-table-column>
            <el-table-column
              property="content"
              label="优惠券内容"
              align="center"
            >
              <template slot-scope="scope">
                <p>￥{{ scope.row.money }}</p>
                <p>满{{ scope.row.bigMoney }}元可用</p>
              </template>
            </el-table-column>
            <el-table-column
              property="range"
              label="适用范围"
              align="center"
            ></el-table-column>
            <el-table-column
              property="date"
              label="有效期"
              align="center"
            ></el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 积分记录 -->
        <div class="pointRecord" v-if="dialogNumber === 8">
          <el-table :data="lifeCornTableData" border style="width: 100%">
            <el-table-column prop="number" label="积分数量" align="center">
            </el-table-column>
            <el-table-column prop="reason" label="操作原因" align="center">
            </el-table-column>
            <el-table-column prop="people" label="操作人" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="操作时间"
              align="center"
              width="160"
            >
            </el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 赠送积分 -->
        <div class="giveCoin" v-if="dialogNumber === 9">
          <el-form-item label="积分币数量">
            <el-input v-model="dialogForm.num" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="dialogForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </checkInfoDialog>
  </div>
</template>
<script>
import BigImg from "@/components/previwerImg.vue";
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
import pagination from "../../../components/pagination.vue";

export default {
  name: "promoterInfo",
  components: {
    BigImg,
    checkInfoDialog,
    pagination,
  },
  data() {
    return {
      remark: "备注备注备注备注备注备注备注备注备注备注",
      customerForm: {},
      dialogLevelVisible: false,
      uuid: "",
      level: "",
      showCancelBtn: true,
      cancelBtnText: "关闭",
      showConfirmBtn: false,
      title: "",
      dialogForm: {},
      checkInfoDialogVisible: false,
      dialogNumber: null,
      photoVisible: false,
      bigImgUrl: "",
      userInfo: {},
      currentPage4: 4,
      defaultImg:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201512%2F06%2F20151206205342_rsM3R.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1691462488&t=369088ef6d16d0369431142f03e254ab",
      tableData: [],
      lifeCornTableData: [],
      customerList: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      gridData: [],
      generalizationTotal: {},
    };
  },
  created() {
    this.getGeneralizationTotal();
    this.getCustomerList();
    this.getInfo();
  },
  methods: {
    updateLevel(level) {
      this.dialogLevelVisible = true;
      this.uuid = this.$route.query.uuid;
      this.level = level;
    },
    confirmUpdateLevel() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          uuid: this.uuid,
          level: this.level,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Extend/updateLevel`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
          this.dialogLevelVisible = false;
          this.getInfo();
          this.getList();
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取客户列表
    getCustomerList() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          extend_uuid: this.$route.query.uuid,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Extend/getIncome?page_size=${this.customerList.per_page}&page_index=${this.customerList.current_page}`,
      }).then((res) => {
        this.customerList = res.data.result;
      });
    },
    // 获取推广资产
    getGeneralizationTotal() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          extend_uuid: this.$route.query.uuid,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Extend/total`,
      }).then((res) => {
        this.generalizationTotal = res.data.result;
      });
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Extend/${this.$route.query.uuid}`,
      }).then((res) => {
        this.userInfo = res.data.result;
        // this.labelList = JSON.parse(JSON.stringify(this.userInfo.label))
        this.remark = this.userInfo.remark;
      });
    },

    blockCustomer(row) {
      let html = "拉黑后客户将无法购买商品，确定要拉黑此客户吗?";

      this.$confirm(html, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            uuid: this.$route.query.uuid,
            status: 2,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/Extend/updateStatus/${this.$route.query.uuid}`,
        })
          .then((res) => {
            this.$message.success("操作成功！");
          })
          .catch((err) => {
            this.$message.success(err);
          });
      });
    },
    //  添加备注
    addRemark() {
      const msg = this.remark !== "" ? "编辑" : "添加";
      this.$prompt("备注信息", msg + "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputValue: this.remark,
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注",
      })
        .then(({ value }) => {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: {
              remark: value,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/UserRemark/${this.$route.query.uuid}`,
          })
            .then((res) => {
              this.$message.success("操作成功！");
              this.remark = value;
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleSizeChange(v) {
      this.customerList.per_page = v;
      this.getCustomerList();
    },
    handleCurrentChange(v) {
      this.customerList.current_page = v;
      this.getCustomerList();
    },
    // 查看
    check(n) {
      console.log(n);
      this.checkInfoDialogVisible = true;
      this.dialogNumber = n;
      switch (n) {
        case 1:
          this.title = "客户权益";
          this.cancelBtnText = "关闭";
          break;
        case 2:
          this.title = "设置客户等级";
          this.cancelBtnText = "取消";
          this.showConfirmBtn = true;
          break;
        case 3:
          this.title = "life币记录";
          this.cancelBtnText = "关闭";
          break;
        case 4:
          this.title = "赠送life币";
          this.cancelBtnText = "关闭";
          this.showConfirmBtn = true;
          break;
        case 5:
          this.title = "成长值记录";
          this.cancelBtnText = "关闭";
          break;
        case 6:
          this.title = "赠送成长值";
          this.cancelBtnText = "关闭";
          this.showConfirmBtn = true;
          break;
        case 7:
          this.title = "优惠券";
          this.cancelBtnText = "关闭";
          break;
        case 8:
          this.title = "积分记录";
          this.cancelBtnText = "关闭";
          break;
        case 9:
          this.title = "赠送积分";
          this.cancelBtnText = "关闭";
          this.showConfirmBtn = true;
          break;
      }
    },
    closeDialog(v) {
      this.checkInfoDialogVisible = v;
      this.showConfirmBtn = v;
    },
  },
};
</script>
<style scoped>
.promoterInfo {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo,
.realNameAuthentication,
.customerLevel,
.clientAssets,
.honorWall,
.transactionStatistics {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.basicInfo .remark .p {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top {
  display: flex;
  justify-content: space-between;
}
.top .el-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.top .nick {
  display: flex;
  align-items: center;
}
.top > div {
  width: calc(25% - 13px);
}
.remark {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.remark div .el-button {
  padding: 0;
}
.top .nick .level {
  border: 1px solid #ffc71c;
  color: #ffc71c;
  display: inline-block;
  padding: 2px 5px;
  margin: 0 10px;
  border-radius: 4px;
}
.bottom {
  margin-left: 60px;
  display: flex;
}
.bottom div {
  width: 25%;
  color: #666;
}

/* 实名审核 */
.realNameAuthentication .box {
  margin-left: 40px;
  margin-top: 20px;
}
.realNameAuthentication .box p {
  margin-top: 5px;
}
.realNameAuthentication p.img span {
  width: 80px;
  height: 80px;
  display: inline-block;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  position: relative;
}
.realNameAuthentication p.img span i {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.realNameAuthentication p.img span .el-image__inner {
  max-width: 100%;
}
/* 客户资产 */
.clientAssets .flex,
.transactionStatistics .flex {
  justify-content: space-around;
  flex-flow: wrap;
  margin-left: 40px;
}
.clientAssets .flex .box,
.transactionStatistics .flex .box {
  padding-top: 30px;
  width: 16.5%;
}

.transactionStatistics .el-table {
  margin-top: 20px;
}
.pagination {
  margin-left: 40px;
  margin-top: 20px;
  text-align: center;
}
>>> .el-dialog__body {
  border-top: 1px solid #f2f2f2;
}
>>> .el-dialog {
  width: 800px !important;
}
.giveCoin {
  width: 500px;
}
.honorWall img {
  max-width: 100%;
}
.honorWall .grid-content {
  padding: 20px 0;
}
.address p {
  line-height: 26px;
}
.address h3 {
  position: relative;
}
.address .export {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
