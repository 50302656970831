<template>
  <div>
    <div class="flex space-between">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
    </div>
    <div class="libraryBox">
      <div
        v-if="
          type == 'insert_image' ||
          type == 'chooseThumbnail_image' ||
          type == 'chooseCover_image'
        "
      >
        <div class="flex bbox">
          <div class="menu pic">
            <dl>
              <dd
                :class="{ current: activeIndex === index }"
                v-for="(item, index) in list.data"
                :key="index"
                @click="changeMenu(item, index)"
              >
                <div class="flex space-between align-center">
                  <div class="flex align-center">
                    <i class="el-icon-camera-solid"></i>
                    <span>{{ item.name }}({{ item.picture_count }})</span>
                  </div>
                </div>
              </dd>
            </dl>
            <pagination
              :small="true"
              :layout="'prev, pager, next'"
              :pager-count="5"
              :total="list.total"
              :per_page="list.per_page"
              :current_page="list.current_page"
              @handleCurrentChange="listHandleCurrentChange"
              @handleSizeChange="listHandleSizeChange"
            />
          </div>
          <div class="image mg-l-20">
            <div>
              <el-form
                :inline="true"
                :model="customerGroupForm"
                class="demo-form-inline"
              >
                <el-form-item label="创建日期">
                  <el-date-picker
                    v-model="customerGroupForm.time"
                    type="daterange"
                    align="right"
                    @change="getTimes"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy/MM/dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="关键字" class="keyword">
                  <el-input
                    placeholder="请输入内容"
                    v-model="customerGroupForm.search_keyword"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="btn mg-t-10">
                <el-button type="primary" size="medium" @click="getPictureList"
                  >查询</el-button
                >
                <el-button size="medium" @click="clearForm">清空</el-button>
              </div>
            </div>
            <div>
              <div v-if="pictureList.data !== null">
                <el-row :gutter="20" class="mg-t-20">
                  <el-col
                    :span="6"
                    v-for="(item, index) in pictureList.data"
                    :key="item.uuid"
                  >
                    <div
                      class="box"
                      style="border: 1px solid #dcdcdc"
                      @click="addStyle(item, index)"
                      :class="{ on: selectImgIndex === index }"
                    >
                      <img class="img" :src="item.url" fit="contain" />
                      <div class="info">
                        <div class="top">
                          <h4 class="font-14 ellipsis">{{ item.name }}</h4>
                          <p class="font-12 color-999">
                            <span v-if="item.width != null"
                              >{{ String(item.file_size).split(".")[0] }}K
                              {{ item.width }}*{{ item.height }}</span
                            ><span v-else>无信息</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <div class="text-center mg-t-20">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pictureList.current_page"
                    :page-sizes="[12, 24, 36, 48, 60]"
                    :page-size="pictureList.per_page"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pictureList.total"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-else>
                <el-empty description="暂无图片"></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="type == 'chooseFile'">
        <div class="flex bbox">
          <div class="menu file">
            <dl>
              <dd
                :class="{ current: activeIndex === index }"
                v-for="(item, index) in fileGrouplist"
                :key="index"
                @click="changeMenu(item, index)"
              >
                <div class="flex space-between align-center">
                  <div class="flex align-center">
                    <i class="el-icon-s-order"></i>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div class="image mg-l-20">
            <div>
              <el-form
                :inline="true"
                :model="customerGroupForm"
                class="demo-form-inline"
              >
                <el-form-item label="创建日期">
                  <el-date-picker
                    v-model="customerGroupForm.time"
                    type="daterange"
                    align="right"
                    @change="getTimes"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy/MM/dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="关键字" class="keyword">
                  <el-input
                    placeholder="请输入内容"
                    v-model="customerGroupForm.search_keyword"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="btn mg-t-10">
                <el-button type="primary" size="medium" @click="getFileList"
                  >查询</el-button
                >
                <el-button size="medium" @click="clearForm">清空</el-button>
              </div>
            </div>
            <div v-if="activeIndex !== ''">
              <div v-if="fileList.data !== null">
                <el-table
                  ref="multipleTable"
                  :data="fileList.data"
                  tooltip-effect="dark"
                  style="width: 100%"
                >
                  <el-table-column
                    prop="name"
                    label="文件名称"
                    align="center"
                    width="280"
                    show-overflow-tooltip
                  >
                  </el-table-column>
                  <el-table-column prop="size" align="center" label="文件大小">
                  </el-table-column>
                  <el-table-column
                    prop="format"
                    align="center"
                    label="文件格式"
                  >
                    <template slot-scope="scope"> PDF </template>
                  </el-table-column>
                  <el-table-column
                    prop="create_time"
                    label="创建时间"
                    align="center"
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    :align="'center'"
                    width="200"
                    fixed="right"
                  >
                    <template slot-scope="scope">
                      <el-button type="text" @click="selectFile(scope.row)"
                        >点击选择</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="text-center mg-t-20">
                  <el-pagination
                    @size-change="handleSizeChange3"
                    @current-change="handleCurrentChange3"
                    :current-page="fileList.current_index"
                    :page-sizes="[12, 24, 36, 48, 60]"
                    :page-size="Number(fileList.per_page)"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="fileList.total"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-else>
                <el-empty description="暂无文件"></el-empty>
              </div>
            </div>
            <div v-else class="text-center mg-t-50 color-666">请先选择分组</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex bbox">
          <div class="menu">
            <dl>
              <dd
                :class="{ current: activeIndex === index }"
                v-for="(item, index) in videoGrouplist"
                :key="index"
                @click="changeMenu(item, index)"
              >
                <div class="flex space-between align-center">
                  <div class="flex align-center">
                    <i class="el-icon-camera-solid"></i>
                    <span>{{ item.name }}({{ item.movie_count }})</span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
          <div class="image mg-l-20">
            <div>
              <el-form
                :inline="true"
                :model="customerGroupForm"
                class="demo-form-inline"
              >
                <el-form-item label="创建日期">
                  <el-date-picker
                    v-model="customerGroupForm.time"
                    type="daterange"
                    align="right"
                    @change="getTimes"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy/MM/dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="关键字" class="keyword">
                  <el-input
                    placeholder="请输入内容"
                    v-model="customerGroupForm.search_keyword"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="btn mg-t-10">
                <el-button type="primary" size="medium" @click="getVideoList"
                  >查询</el-button
                >
                <el-button size="medium" @click="clearForm">清空</el-button>
                  <el-button type="warning" icon="el-icon-plus" @click="uploadDialogVisible = true"
                    >上传视频</el-button
                  >
              </div>
            </div>
            <div>
              <div v-if="videoList.data !== null">
                <el-row :gutter="20" class="mg-t-20">
                  <el-col
                    :span="6"
                    v-for="item in videoList.data"
                    :key="item.uuid"
                  >
                    <div
                      style="border: 1px solid rgb(220, 220, 220)"
                      @click="selectInsertVideo(item.url)"
                    >
                      <div class="img">
                        <div class="l">
                          <i class="el-icon-video-play"></i>
                        </div>
                        <video
                          width="100%"
                          height="150px"
                          :src="item.url"
                          :poster="item.cover"
                        ></video>
                      </div>
                      <div class="info">
                        <div class="top">
                          <h4 class="font-14 ellipsis">{{ item.name }}</h4>
                          <p class="font-12 color-999">300.60K 1440*2410</p>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <div class="text-center mg-t-20">
                  <el-pagination
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                    :current-page.sync="videoList.current_page"
                    :page-sizes="[12, 24, 36, 48, 60]"
                    :page-size="videoList.per_page"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="videoList.total"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-else>
                <el-empty description="暂无视频"></el-empty>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="上传视频"
      class="specialDialog"
      :visible.sync="uploadDialogVisible"
      width="70%"
      :close-on-click-modal="false"
      :show-close="false"
      :modal="false"
    >
      <uploadVideoDialog @closeUploadDialog="closeUploadDialog" />
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../pagination.vue";
import uploadVideoDialog from "../uploadVideoDialog.vue";

export default {
  name: "materialLibrary",
  props: {
    type: {
      type: String,
      default: "",
    },
    isLimit: {
      type: Boolean,
      default: false,
    },
    limitSize: {
      type: String,
      default: "",
    },
  },
  components: {
    pagination,
    uploadVideoDialog
  },
  data() {
    return {
      uploadDialogVisible: false,
      currentClass: 0,
      player: "",
      tabs: ["图片", "视频", "文件"],
      activeIndex: "",
      list: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      videoGrouplist: [],
      fileGrouplist: [],
      pictureList: {
        data: [],
        per_page: 12,
        current_page: 1,
        total: 0,
      },
      fileList: {
        data: [],
        per_page: 12,
        current_page: 1,
        total: 0,
      },
      videoList: {
        data: [],
        per_page: 12,
        current_page: 1,
        total: 0,
      },
      selectImgUrl: "",
      selectImgIndex: "",
      customerGroupForm: {
        time: "",
        search_start_time: "",
        search_end_time: "",
        per_page: 12,
        current_index: 1,
        search_keyword: "",
      },
      uuid: "",
      group_uuid: "",
    };
  },
  created() {
    if (
      this.type === "chooseThumbnail_image" ||
      this.type === "chooseCover_image" ||
      this.type === "insert_image"
    ) {
      this.tabs = [];
      this.getGroupList();
      this.selectImgIndex = "";
    } else if (this.type == "chooseVideo") {
      this.tabs = [];
      this.getVideoGroupList();
    } else if (this.type == "chooseFile") {
      this.tabs = [];
      this.getFileGroupList();
    } else {
      this.tabs = this.tabs.filter((item) => item === "视频");
      this.getVideoGroupList();
    }
  },

  watch: {
    type(n) {
      if (
        n === "chooseThumbnail_image" ||
        n === "chooseCover_image" ||
        n === "insert_image"
      ) {
        this.tabs = this.tabs.filter((item) => item === "图片");
        this.getGroupList();
        this.selectImgIndex = "";
      } else if (n == "chooseVideo") {
        this.tabs = [];
        this.getVideoGroupList();
      } else if (n == "chooseFile") {
        this.tabs = [];
        this.getFileGroupList();
      } else {
        this.tabs = this.tabs.filter((item) => item === "视频");
        this.getVideoGroupList();
      }
    },
  },
  mounted() {},
  methods: {
    closeUploadDialog () {
      this.uploadDialogVisible = false
      this.getVideoList()
    },
    addClass(index) {
      this.currentClass = index;
    },
    selectFile(row) {
      this.$emit("insertFile", row);
    },
    // 点击更换组
    changeMenu(item, index) {
      this.group_uuid = item.uuid;
      this.activeIndex = index;
      if (this.type === "insert_video" || this.type == "chooseVideo") {
        this.getVideoList();
      } else if (this.type == "chooseFile") {
        this.getFileList();
      } else {
        this.getPictureList();
      }
    },
    // 获取图片分组列表
    getGroupList() {
      console.log(22);
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/PictureGroup?page_index=${this.list.current_page}&page_size=${this.list.per_page}`,
      }).then((res) => {
        //执行成功后代码处理
        console.log(res);
        this.list = res.data.result;
        this.group_uuid = res.data.result[0].uuid;
        this.getPictureList();
      });
    },

    // 获取视频分组列表
    getVideoGroupList() {
      this.$api.materialLibrary.getVideoGroupList().then((res) => {
        this.videoGrouplist = res;
        this.group_uuid = res[0].uuid;
        this.getVideoList();
      });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 获取图片列表
    getPictureList(group_uuid) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.customerGroupForm,
        url: ` https://time.api.boyalife.cn/v1/cms/Picture?group_uuid=${this.group_uuid}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&page_index=${this.pictureList.current_page}&page_size=${this.pictureList.per_page}`,
      }).then((response) => {
        this.pictureList = response.data.result;
      });
    },
    // 获取视频列表
    getVideoList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.customerGroupForm,
        url: ` https://time.api.boyalife.cn/v1/cms/Movie?group_uuid=${this.group_uuid}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&page_index=${this.videoList.current_page}&page_size=${this.videoList.per_page}`,
      }).then((res) => {
        this.videoList = res.data.result;
      });
    },
    // 获取文件分组列表
    getFileGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/FileGroup`,
      }).then((res) => {
        this.fileGrouplist = res.data.result;
      });
    },
    // 获取文件列表
    getFileList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/File?group_uuid=${this.group_uuid}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&page_size=${this.fileList.per_page}&page_index=${this.fileList.current_page}`,
      }).then((res) => {
        this.fileList = res.data.result;
      });
    },
    handleSizeChange(val) {
      this.pictureList.per_page = Number(val);
      this.getPictureList();
    },
    handleCurrentChange(val) {
      this.pictureList.current_page = Number(val);
      this.getPictureList();
    },
    handleSizeChange2(val) {
      this.videoList.per_page = Number(val);
      this.getVideoList();
    },
    handleCurrentChange2(val) {
      this.videoList.current_page = Number(val);
      this.getVideoList();
    },
    handleCurrentChange3(val) {
      this.fileList.current_page = Number(val);
      this.getFileList();
    },
    handleSizeChange3(val) {
      this.fileList.per_page = Number(val);
      this.getFileList();
    },
    listHandleSizeChange(val) {
      this.list.per_page = Number(val);
      this.getGroupList();
    },
    listHandleCurrentChange(val) {
      this.list.current_page = Number(val);
      this.getGroupList();
    },
    // 清空查询参数
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
    },
    addStyle(item, index) {
      this.selectImgUrl = "";
      this.selectImgIndex = index;
      this.selectImgIndex = "";
      if (this.type === "insert_image") {
        this.$emit("insertImg", item);
      } else {
        // if (this.isLimit && item.size > this.limitSize) {
        //   this.$message.error(`请选择小于或者等于${this.limitSize}kb的图片`)
        //   return false
        // }
        this.$emit("selectImg", item);
      }
    },
    selectInsertVideo(url) {
      if (this.type === "insert_video" || this.type == "chooseVideo") {
        this.$emit("insertVideoHandler", url);
      } else {
        this.$emit("selectVideoHandler", url);
      }
    },
  },
};
</script>
<style scoped>
.tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.tabs p.on {
  color: #2561ef;
}
.tabs p.on:after {
  background: #2561ef;
}
.libraryBox {
}
.menu {
  width: 20%;
  min-width: 200px;
  max-height: calc(100vh + 400px);
  overflow: auto;
  height: 80vh;
}
.bbox {
  position: relative;
}
.bbox .upload-demo {
  position: absolute;
  right: 0;
  top: -74px;
}

.menu .btn {
  display: none;
}
.menu dl dd {
  cursor: pointer;
  padding: 0 5px;
}
.menu dl dd:hover,
.menu dl dd.current {
  background: #f6f9fe;
  color: #2561ef;
}
.menu dl dd .el-button {
  padding: 0;
}
.menu dl dd >>> .el-button span {
  font-size: 12px;
}
.menu dl dd:hover .btn {
  display: block;
}
.image {
  width: 80%;
}
.el-icon-circle-plus-outline {
  color: #2561ef;
  font-size: 16px;
  cursor: pointer;
}
.el-icon-camera-solid {
  color: #ffc71c;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}

.el-row {
  height: 400px;
  overflow: auto;
}
.el-row .el-col {
  margin-bottom: 20px;
  height: 230px;
}

.el-row .img {
  width: 100%;
  display: block;
  height: 150px;
  cursor: pointer;
}
.el-row .info {
  margin: 0 1px;
}
.el-row .box.on {
  background: #7da1f6;
}
.el-row .box.on .info .top h4,
.el-row .box.on .top p {
  color: #fff !important;
}
.el-row .info .top {
  padding: 10px;
  border-bottom: 1px solid rgb(220, 220, 220);
}
.el-row .info .top h4 {
  color: #7da1f6;
}
.el-row .info .bottom >>> .el-button span {
  font-size: 12px !important;
}
>>> .el-radio__input {
  display: none;
}
.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 8;
  left: 0;
  top: 0;
}
.layout .el-icon-circle-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
#dplayer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
}
>>> .dplayer-video-wrap {
  width: 80%;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
>>> .dplayer-mask {
  background: rgba(0, 0, 0, 1);
  display: block;
}
>>> .dplayer-controller,
.dplayer-controller-mask {
  z-index: 3;
}
.img {
  position: relative;
  cursor: pointer;
}
.img .l {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}
.img:hover .l {
  background: rgba(0, 0, 0, 0.6);
}
.img:hover .el-icon-video-play {
  color: #c5c5c5;
}
.el-icon-video-play {
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  transition: all 0.2s;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 35px;
  max-height: 35px;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
.menu dl dd {
  padding: 10px 5px;
}
</style>
