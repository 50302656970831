<template>
  <div class="customerLabel">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>客户标签</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="标签组筛选">
          <el-select
            v-model="customerGroupForm.search_group_uuid"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, i) in labelGroupList"
              :key="i"
              :label="item"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打标方式">
          <el-select
            v-model="customerGroupForm.search_tag_type"
            placeholder="请选择"
          >
            <el-option label="自动打标" :value="1"></el-option>
            <el-option label="手动打标" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签名称" class="keyword">
          <el-input
            placeholder="请输入标签名称"
            v-model="customerGroupForm.search_keyword"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getLabelList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportLabel">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addLabel')"
          >新增标签</el-button
        >
        <el-button type="primary" @click="$router.push('/labelGroup')"
          >标签组</el-button
        >
        <el-upload
          class="upload-demo"
          action="#"
          accept=".xlsx"
          :show-file-list="false"
          :http-request="httpRequest"
          :before-upload="beforeUpload"
        >
          <el-button type="primary">批量导入标签</el-button>
        </el-upload>
        <a
          href="https://time.api.boyalife.cn/excel/%E5%AF%BC%E5%85%A5%E6%A0%87%E7%AD%BE.xlsx"
          style="color: #409eff; font-size: 12px"
          class="mg-l-20"
          >下载导入模板</a
        >
      </div>
      <el-table
        :data="tableData.data"
        border
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <!-- <el-table-column
          type="selection"
          width="55">
        </el-table-column> -->
        <el-table-column fixed prop="name" label="标签名称" align="center">
        </el-table-column>
        <el-table-column prop="labelgroup.name" label="标签组" align="center">
        </el-table-column>
        <el-table-column prop="user_number" label="应用人数" align="center">
        </el-table-column>
        <el-table-column prop="tag_type" label="打标方式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.tag_type == 1">自动打标</span>
            <span v-else>手动打标</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="desc"
          show-overflow-tooltip
          label="标签条件"
          align="center"
        >
          <template slot-scope="scope" v-if="scope.row.condition.length > 0">
            <span v-for="(item, index) in scope.row.condition" :key="index">
              {{ item.condition_name }}{{ item.judgment_name }}
              <span v-if="item.value != null && item.value_name != null">
                <!-- <span v-if="item.value_name.value.min"> (最小为{{ item.value_name.value.min }}, 最大为{{ item.value_name.value.max }})</span> -->
                <span v-if="item.value_name.value.includes(',') == true"
                  >{{ item.value_name.value.replace(",", "-") }} &nbsp; /
                  &nbsp;</span
                >
                <span v-else>{{ item.value_name.value }}&nbsp; / &nbsp;</span>
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="admin.name" label="创建人" align="center">
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="更新时间"
          align="center"
          width="160"
        >
        </el-table-column>
        <el-table-column prop="road" label="标签渠道" align="center">
          <template slot-scope="scope">
            <span>小程序标签</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="toEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleted(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";

export default {
  name: "customerLabel",
  components: {
    pagination,
  },
  data() {
    return {
      dataTime: "",
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_group_uuid: "",
        search_tag_type: "",
        search_keyword: "",
      },
      labelGroupList: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      uuids: [],
    };
  },
  created() {
    this.getLabelList();
    this.getLabelGroupList();
  },
  methods: {
    handleSelectionChange(row) {
      this.uuids = [];
      this.uuids = row.map((item) => item.uuid);
    },
    beforeUpload(file) {
      console.log(file);
      this.load = true;
      this.$message.info("稍等片刻，正在上传...");
      if (file.name.split(".")[1].toLowerCase() != "xlsx") {
        this.$message.error("文件格式错误~");
        return false; // 终止上传
      }
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    // 上传图片
    httpRequest(data) {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.formData,
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabel/import`,
      }).then((res) => {
        if (res.data.result == true) {
          this.$message.success("保存成功！");
          this.load = false;
          this.getLabelList();
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getLabelList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 编辑跳转
    toEdit(row) {
      this.$router.push({
        name: "addLabel",
        query: { uuid: row.uuid },
      });
    },
    // 标签组
    getLabelGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabelGroupSelect`,
      })
        .then((res) => {
          this.labelGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 导出
    exportLabel() {
      this.axios({
        method: "post", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          uuid: this.uuids,
          search_start_time: this.customerGroupForm.search_start_time,
          search_end_time: this.customerGroupForm.search_end_time,
          search_group_uuid: this.customerGroupForm.search_group_uuid,
          search_tag_type: this.customerGroupForm.search_tag_type,
          search_keyword: this.customerGroupForm.search_keyword,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabel/export`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);

          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;
          link.download = "客户标签" + "_" + formattedDate + ".xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getLabelList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLabel?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_group_uuid=${this.customerGroupForm.search_group_uuid}&search_tag_type=${this.customerGroupForm.search_tag_type}&search_keyword=${this.customerGroupForm.search_keyword}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    deleted(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.axios({
          method: "delete", //请求方法
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/UserLabel/${row.uuid}`,
        })
          .then(() => {
            this.$message.success("删除成功！");
            this.getLabelList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getLabelList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getLabelList();
    },
  },
};
</script>
<style scoped>
.customerLabel {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
.upload-demo {
  display: inline-block;
  margin-left: 10px;
}
</style>
