<template>
  <div class="managerMasterList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理师列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
            class="input-with-select"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getManagerList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportManage">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addManagerMaster')"
          >新增管理师</el-button
        >
      </div>
      <el-table :data="tableData.data" border style="width: 100%">
        <el-table-column
          fixed
          prop="nick_name"
          label="健康管理师名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="label[0].user_number"
          label="关联客户数"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="label_number" label="关联标签数" align="center">
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">启用</span>
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button @click="toEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleted(scope.row.uuid)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "managerMasterList",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        search_keyword: "",
      },
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getManagerList();
  },
  methods: {
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getManagerList();
    },
    // 编辑跳转
    toEdit(row) {
      row.label = JSON.stringify(row.label);
      this.$router.push({
        name: "addManagerMaster",
        query: { ...row },
      });
    },
    getManagerList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Manager?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 导出
    exportManage() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ManagerExport?search_keyword=${this.customerGroupForm.search_keyword}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `管理师信息 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    deleted(uuid) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/Manager/${uuid}`,
          })
            .then(() => {
              this.$message.success("删除成功！");
              this.getManagerList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getManagerList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getManagerList();
    },
  },
};
</script>
<style scoped>
.managerMasterList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
</style>
