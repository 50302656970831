<template>
  <div class="addInformation">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>产品与服务</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="产品与服务标题" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="产品与服务视频" prop="cover_image">
          <span slot="label">产品与服务视频</span>
          <el-button class="avatar-uploader video" @click="chooseVideo">
            <video v-if="basicInfoForm.movie !== ''" width="178px" controls>
              <source :src="basicInfoForm.movie" />
            </video>
            <i
              class="el-icon-delete"
              v-if="basicInfoForm.movie !== ''"
              @click.stop="basicInfoForm.movie = ''"
            ></i>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-button>
          <p class="font-12 color-999">建议尺寸：1080P，500M以内</p>
        </el-form-item>
      </el-form>
      <div class="info mg-t-20">
        <h3 class="title-t mg-b-30">产品与服务详情</h3>
        <wangEditorTool ref="editors" :content="basicInfoForm.desc" />
      </div>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm">保存</el-button>
      <!-- <el-button size="mini" @click="$router.go(-1)">返回</el-button> -->
    </div>
    <el-dialog
      title="选择视频"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @insertVideoHandler="insertVideoHandler"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import wangEditorTool from "../../../components/wangEditorTool.vue";
export default {
  name: "addInformation",
  components: { MaterialLibrary, wangEditorTool },
  data() {
    return {
      pageType: "新增",
      imgType: "",
      labelDialogVisible: false,
      tags: [],
      active: 0,
      dialogVisible: false,
      uuid: "",
      options: [],
      props: {
        label: "category_name",
        value: "uuid",
        children: "children",
      },
      value2: "",
      value3: "",
      basicInfoForm: {
        name: "",
        movie: "",
        desc: "",
      },
      rules: {
        name: [{ required: true, message: "请输入标题", trigger: "blur" }],
        desc: [{ required: true, message: "请填写简介详情", trigger: "blur" }],
      },
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.getInfo();
    } else {
    }
  },

  methods: {
    chooseVideo() {
      this.imgType = "chooseVideo";
      this.dialogVisible = true;
    },
    insertVideoHandler(url) {
      this.dialogVisible = false;
      this.basicInfoForm.movie = url;
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Information/${this.$route.query.uuid}`,
      }).then((res) => {
        console.log(res);
        let params = res.data.result;
        for (let i in params) {
          this.basicInfoForm[i] = params[i];
        }
        this.value3 = this.basicInfoForm.information_category;
        this.tags = this.basicInfoForm.label;
        this.basicInfoForm.group_uuid = this.basicInfoForm.group.map(
          (item) => item.uuid
        );
        this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);
        if (this.basicInfoForm.shelf_type == 1) {
          this.basicInfoForm.shelf_time = "";
        }
        if (this.basicInfoForm.publish_time.slice(0, 1) == 0) {
          this.basicInfoForm.publish_time = "";
        }
        console.log(this.basicInfoForm.publish_time);
        this.basicInfoForm.cover_image =
          this.basicInfoForm.cover_image.split(",");
        console.log(this.basicInfoForm.cover_image);
      });
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.basicInfoForm.desc = this.$refs.editors.html;
          if (
            this.basicInfoForm.desc == "<p><br></p>" ||
            this.basicInfoForm.desc == ""
          ) {
            this.$message.warning("请填写资讯详情");
            return false;
          }

          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/Information`,
          }).then((res) => {
            this.$message.success("保存成功！");
            this.$router.go(-1);
          });
        }
      });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addInformation {
  margin-bottom: 80px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.cover_image_list {
  flex-wrap: wrap;
  margin-top: 20px;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>> .el-form .el-input {
  width: 400px;
}

.insertVideo {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 107px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
