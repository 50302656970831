<template>
  <div class="paymentRecords" style="margin-bottom: 80px">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/cellList'">细胞列表</el-breadcrumb-item>
      <el-breadcrumb-item>缴费记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="pay_price" label="缴费金额" :align="'center'">
        </el-table-column>
        <el-table-column prop="price" label="优惠金额" :align="'center'">
          <template slot-scope="scope">
            <span>{{
              Number(scope.row.price) - Number(scope.row.pay_price)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户信息" :align="'center'">
          <template slot-scope="scope">
            <router-link :to="'/customerInfo?uuid=' + scope.row.user.uuid">
              <div class="nickAndAvatar">
                <el-image
                  v-if="scope.row.user != null && scope.row.user.avatar != ''"
                  :src="scope.row.user.avatar"
                ></el-image>
                <p>
                  <span>{{ scope.row.user.nick_name }}</span> <br />
                  <span>{{ scope.row.user.mobile }}</span>
                </p>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="stages" label="期数" :align="'center'">
          <template slot-scope="scope">
            <span>全款支付</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="缴费方式" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477">微信支付</span>
          </template>
        </el-table-column>
        <el-table-column prop="fee_date" label="缴费时间" :align="'center'">
        </el-table-column>
      </el-table>
    </div>
    <div class="operateBtn">
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "paymentRecords",
  data() {
    return {
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemFeeStages?fee_uuid=${this.$route.query.uuid}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
</style>
