<template>
  <div class="productOverview">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>交易分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="overview boxLayout">
      <div class="title flex space-between align-center mg-b-20">
        <h3 class="title-t mg-b-20">交易概况</h3>
        <div>
          <span>选择日期</span>
          <el-date-picker
            class="mg-l-20"
            v-model="value"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-button class="mg-l-20" @click="transactionProfileExport"
            >导出</el-button
          >
        </div>
      </div>
      <div class="box box1 flex align-center">
        <h4>访客</h4>
        <div class="text-center">
          <p class="color-666">访客量</p>
          <p class="font-24">{{ transactionData.visitor_number }}</p>
        </div>
      </div>
      <div class="box box2 flex align-center">
        <h4>下单</h4>
        <div class="text-center">
          <p class="color-666">下单人数</p>
          <p class="font-24">{{ transactionData.order_user }}</p>
        </div>
        <div class="text-center">
          <p class="color-666">下单笔数</p>
          <p class="font-24">{{ transactionData.order_number }}</p>
        </div>
        <div class="text-center">
          <p class="color-666">下单金额(元)</p>
          <p class="font-24">{{ transactionData.order_amount }}</p>
        </div>
      </div>
      <div class="box box3 flex align-center">
        <h4>支付</h4>
        <div class="text-center">
          <p class="color-666">支付人数</p>
          <p class="font-24">{{ transactionData.pay_user }}</p>
        </div>
        <div class="text-center">
          <p class="color-666">支付订单数</p>
          <p class="font-24">{{ transactionData.pay_number }}</p>
        </div>
        <div class="text-center">
          <p class="color-666">支付金额(元)</p>
          <p class="font-24">{{ transactionData.pay_amount }}</p>
        </div>
        <div class="text-center">
          <p class="color-666">客单价 (元)</p>
          <p class="font-24">{{ transactionData.avg_pay_user_amount }}</p>
        </div>
      </div>
      <div
        id="transactionOverview"
        class="mg-t-30"
        ref="myChart"
        style="width: 100%; height: 600px"
      ></div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="title flex space-between align-center mg-b-20">
        <h3 class="title-t mg-b-20">地域分布</h3>
        <div>
          <span>选择日期</span>
          <el-date-picker
            class="mg-l-20"
            v-model="value"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes2"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-button class="mg-l-20" @click="exportProvinceData"
            >导出</el-button
          >
        </div>
      </div>
      <div class="flex space-between">
        <div
          id="main"
          ref="myChart2"
          style="width: 40%; height: 500px; margin-left: 40px"
        ></div>
        <div style="width: 50%">
          <el-table
            class="mg-t-20"
            :data="
              tableData.slice(
                (currentPage - 1) * PageSize,
                currentPage * PageSize
              )
            "
            style="width: 100%"
            border
          >
            <el-table-column
              prop="province"
              label="TOP省份"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center">
            </el-table-column>
            <el-table-column
              prop="pay_user"
              show-overflow-tooltip
              label="支付人数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="visitor_user"
              show-overflow-tooltip
              label="访客人数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="avg_visitor_pay"
              show-overflow-tooltip
              label="访客-支付转化率"
              align="center"
            >
            </el-table-column>
          </el-table>
          <el-pagination
            class="mg-t-20"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="title flex space-between align-center mg-b-20">
        <h3 class="title-t mg-b-20">订单金额分布</h3>
        <div>
          <el-button @click="orderAmountExport">导出</el-button>
        </div>
      </div>
      <div
        id="orderMoney"
        class="mg-t-30"
        ref="myChart2"
        style="width: 100%; height: 400px"
      ></div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";

import china from "../../../../static/china.json";
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
  .toString()
  .padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
let timed = start.toLocaleDateString();
export default {
  name: "",
  data() {
    return {
      dateArray: [],
      numberArray: [],
      china,
      chinaData: [],
      customerForm: {
        search_start_time: timed,
        search_end_time: formattedDate,
        search_start_time2: timed,
        search_end_time2: formattedDate,
      },
      transactionData: {},
      orderAmountData: [],
      value: [timed, formattedDate],
      value2: "",
      type: "pay_amount",
      isReady: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 1,
      // 个数选择器（可修改）
      pageSizes: [5],
      // 默认每页显示的条数（可修改）
      PageSize: 5,
    };
  },
  created() {
    this.getTransactionData();
    this.getTransactionProfilePolyline();
    this.getProvinceData();
    this.getOrderAmount();
  },
  mounted() {
    // 交易数据
    setTimeout(() => {
      this.initTransactionData(); // 树状
      this.initProvinceData();
      this.initOrderAmount();
    }, 1000);
  },
  methods: {
    // 导出交易概况
    transactionProfileExport() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/transactionProfileExport?start_time=${this.customerForm.search_start_time}&end_time=${this.customerForm.search_end_time}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          console.log(link);
          link.download = "交易概况数据.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 导出省份数据
    exportProvinceData() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/provinceDataExport?start_time=${this.customerForm.search_start_time2}&end_time=${this.customerForm.search_end_time2}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          console.log(link);
          link.download = "省份交易数据.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 导出金额分布
    orderAmountExport() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/orderAmountExport`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          console.log(link);
          link.download = "订单金额分布数据.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    initTransactionData() {
      // 交易概况
      let chartDom = document.getElementById("transactionOverview");
      let transactionOverview = echarts.init(chartDom);
      let transactionOverviewOption = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            "支付金额",
            "支付人数",
            "支付订单数",
            "访问量",
            "客单价",
            "访问-下单转化率",
            "下单-支付转化率",
            "访问-支付转化率",
          ],
          bottom: "20px",
          selectedMode: "single",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          height: "500px",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.dateArray,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "支付金额",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "支付人数",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "支付订单数",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "访问量",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "客单价",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "访问-下单转化率",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "下单-支付转化率",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
          {
            name: "访问-支付转化率",
            smooth: true,
            type: "line",
            data: this.numberArray,
            label: {
              show: false,
              position: "top",
              textStyle: {
                color: "gray",
                fontSize: 16,
              },
            },
          },
        ],
      };
      let that = this;
      transactionOverview.clear();
      transactionOverviewOption &&
        transactionOverview.setOption(transactionOverviewOption);
      transactionOverview.on("legendselectchanged", function (params) {
        let name = params.name;
        switch (name) {
          case "支付金额":
            that.type = "pay_amount";
            break;
          case "支付人数":
            that.type = "pay_user";

            break;
          case "支付订单数":
            that.type = "pay_number";
            break;
          case "访问量":
            that.type = "visitor_number";
            break;
          case "客单价":
            that.type = "avg_pay_user_amount";
            break;
          case "访问-下单转化率":
            that.type = "avg_visitor_order";
            break;
          case "下单-支付转化率":
            that.type = "avg_order_pay";
            break;
          case "访问-支付转化率":
            that.type = "avg_visitor_pay";
            break;
          default:
            that.type = "pay_amount";
        }
        that.getTransactionProfilePolyline();
      });
    },
    initProvinceData() {
      let bigData = JSON.parse(JSON.stringify(this.tableData));
      bigData.map((item) => {
        for (let i in item) {
          if (i !== "province") {
            item[i] = Number(item[i]);
          }
        }
        item.name = item.province;
        item.value = item.pay_amount;
      });
      this.chinaData = this.tableData.map((item) => {
        let myCity = {};
        myCity.pay_amount = item.pay_amount;
        myCity.pay_user = item.pay_user;
        myCity.visitor_user = item.visitor_user;
        myCity.avg_visitor_pay = item.avg_visitor_pay;
        return myCity;
      });
      const chartDom2 = document.getElementById("main");
      const myChart2 = echarts.init(chartDom2);
      let option2 = {};

      myChart2.showLoading();

      myChart2.hideLoading();
      echarts.registerMap("china", china);
      myChart2.setOption(
        (option2 = {
          myChart: null,
          mapName: "china",

          geo: {
            map: "china",
            zoom: 1.25,
            roam: false, //放大缩小地图、可拖拽
            itemStyle: {
              normal: {
                borderColor: "#fff", //地图边框颜色
                areaColor: "#004805", //地图背景颜色
                color: "#fff",
              },
              emphasis: {
                show: true,
                textColor: "#fff",
                areaColor: "yellow", //鼠标放到地图上显示的颜色
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                borderWidth: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            textStyle: {
              color: "#fff",
            },
            //点击省份后背景颜色改变
            // select: {
            //   itemStyle: {
            //     color: "yellow",
            //     areaColor: "#357375", //点击省份后背景颜色改变
            //   },
            // },
          },
          tooltip: {
            trigger: "item",
            formatter: function (params) {
              return ` ${params.name}<br> 支付金额： ${params.value} (元)<br>
                  支付人数: ${params["data"].pay_user} (人) <br>
                  访客人数：${params["data"].visitor_user} (人) <br>
                  访客-支付转化率：${params["data"].avg_visitor_pay} (%)

                `;
            },
          },
          visualMap: {
            min: 100,
            max: 3000,
            realtime: false,
            calculable: true,
            orient: "horizontal",
            left: "center",
            bottom: "20%",
            inRange: {
              color: ["#0fa719", "yellow", "orangered"],
            },
          },
          series: [
            {
              type: "map",
              map: "中国",
              mapType: "china",
              geoIndex: 0, // 解决设置geo后地图重影问题
              zoom: 1,
              silent: false,
              label: {
                show: true,
              },
              data: bigData,
            },
          ],
        })
      );
      myChart2.clear();
      option2 && myChart2.setOption(option2);
    },
    initOrderAmount() {
      var chartDom3 = document.getElementById("orderMoney");
      var myChart3 = echarts.init(chartDom3);
      var option3;
      let that = this;
      option3 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["订单数量"],
          bottom: "0",
          selectedMode: "single",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          height: "400px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              "100以下",
              "100-500",
              "500-1000",
              "1000-1500",
              "1500-2000",
              "2000-2500",
              "2500-3000",
              "3000及以上",
            ],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "订单数量",
            type: "bar",
            barWidth: "60%",
            data: that.orderAmountData,
          },
        ],
      };

      option3 && myChart3.setOption(option3);
    },
    // 金额分布
    getOrderAmount() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/orderAmount`,
      })
        .then((res) => {
          console.log(res);
          this.orderAmountData = [];
          res.data.result.map((item) => {
            this.orderAmountData.push(item.number);
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 数据概览
    getTransactionData() {
      let currentTime = new Date();
      let year = currentTime.getFullYear();
      let month = currentTime.getMonth() + 1;
      let day = currentTime.getDate();
      let formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}`;
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      if (this.customerForm.search_start_time == "") {
        this.customerForm.search_start_time =
          start.toLocaleDateString() + " 00:00:00";
      }
      if (this.customerForm.search_end_time == "") {
        this.customerForm.search_end_time = formattedDate + " 23:59:59";
      }

      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/transactionProfile?start_time=${this.customerForm.search_start_time}&end_time=${this.customerForm.search_end_time}`,
      })
        .then((res) => {
          this.transactionData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 折线图
    getTransactionProfilePolyline() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/transactionProfilePolyline?start_time=${this.customerForm.search_start_time}&end_time=${this.customerForm.search_end_time}&type=${this.type}`,
      })
        .then((res) => {
          let params = res.data.result;
          this.dateArray = [];
          this.numberArray = [];
          params.map((item) => {
            this.dateArray.push(item.date);
            this.numberArray.push(item.number);
          });
          this.isReady = true;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 区域图
    getProvinceData() {
      let currentTime = new Date();
      let year = currentTime.getFullYear();
      let month = currentTime.getMonth() + 1;
      let day = currentTime.getDate();
      let formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}`;
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      if (this.customerForm.search_start_time2 == "") {
        this.customerForm.search_start_time2 = start.toLocaleDateString();
      }
      if (this.customerForm.search_end_time2 == "") {
        this.customerForm.search_end_time2 = formattedDate;
      }
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/provinceData?start_time=${this.customerForm.search_start_time2}&end_time=${this.customerForm.search_end_time2}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
          this.totalCount = res.data.result.length;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 每页显示的条数
    handleSizeChange(val) {
      // 改变每页显示的条数
      this.PageSize = val;
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage = 1;
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
    },
    // 选择时间
    getTimes(v) {
      this.customerForm.search_start_time = v[0] + " 00:00:00";
      this.customerForm.search_end_time = v[1] + " 23:59:59";
      this.getTransactionData();
      this.getTransactionProfilePolyline();
      setTimeout(() => {
        this.initTransactionData(); // 树状
      }, 1000);
    },
    getTimes2(v) {
      this.customerForm.search_start_time2 = v[0] + " 00:00:00";
      this.customerForm.search_end_time2 = v[1] + " 23:59:59";
      this.getProvinceData();
      setTimeout(() => {
        this.initProvinceData(); // 树状
      }, 1000);
    },
  },
};
</script>
<style scoped>
.box {
  border-top: 3px solid #fff;
  position: relative;
}
.box.flex h4 {
  width: 200px;
  padding: 45px 0;
  color: #fff;
  text-align: center;
}
.box1 {
  background: #cddcff;
}
.box1 h4 {
  background: #2561ef;
}
.box2 {
  background: #dfe9ff;
}
.box2 h4 {
  background: #618ffb;
}
.box3 {
  background: #eef3ff;
}
.box3 h4 {
  background: #9bb9ff;
}
.text-center {
  width: 100px;
  margin-left: 10%;
}
</style>
