<template>
  <div class="promotionOrderRecords">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>推广</el-breadcrumb-item>
      <el-breadcrumb-item>推广管理</el-breadcrumb-item>
      <el-breadcrumb-item>推广订单记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="结算状态">
          <el-select v-model="customerGroupForm.status" placeholder="请选择">
            <el-option label="待上帐" :value="1"></el-option>
            <el-option label="已上帐" :value="2"></el-option>
            <el-option label="已作废" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单日期">
          <el-date-picker
            v-model="customerGroupForm.registerDate"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结算日期">
          <el-date-picker
            v-model="customerGroupForm.registerDate2"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes2"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单搜索" class="keyword">
          <el-input
            placeholder="请输入订单ID"
            v-model="customerGroupForm.order_id"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" :align="'center'" width="55">
        </el-table-column>
        <el-table-column label="商品描述" :align="'center'" width="260">
          <template slot-scope="scope">
            <div class="product flex">
              <el-image
                fit="cover"
                :src="scope.row.goods_order.goods[0].image"
              ></el-image>
              <div style="width: 190px" class="text-left">
                <el-tooltip
                  :content="scope.row.goods_order.goods[0].name"
                  placement="top"
                >
                  <p class="ellipsis">
                    {{ scope.row.goods_order.goods[0].name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="购买人"
          :align="'center'"
          width="170"
        >
          <template slot-scope="scope">
            <router-link
              :to="'/customerInfo?uuid=' + scope.row.goods_order.user_uuid"
            >
              <div style="margin: 0 auto; width: 150px">
                <div class="flex align-center">
                  <!-- <el-image fit="cover" :src="scope.row.avatar"></el-image> -->
                  <div class="text-left">
                    <p>{{ scope.row.goods_order.name }}</p>
                    <p>{{ scope.row.goods_order.mobile }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="phone"
          label="推广人"
          :align="'center'"
          width="170"
        >
          <template slot-scope="scope">
            <router-link
              :to="'/customerInfo?uuid=' + scope.row.extend.user_uuid"
            >
              <div style="margin: 0 auto; width: 150px">
                <div class="flex align-center">
                  <!-- <el-image fit="cover" :src="scope.row.avatar"></el-image> -->
                  <div class="text-left">
                    <p>{{ scope.row.extend.name }}</p>
                    <p>{{ scope.row.extend.mobile }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>

        <el-table-column
          prop="get_extend_money.total_order_num"
          label="购买数量"
          :align="'center'"
          sortable
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.goods_order.goods.length }}
          </template>
        </el-table-column>
        <el-table-column
          prop="fxje"
          label="佣金"
          :align="'center'"
          sortable
          width="150"
        >
          <template slot-scope="scope">
            <p>￥{{ scope.row.fxje }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="integral" label="结算状态" :align="'center'">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 1">待上帐</el-tag>
            <el-tag type="success" v-if="scope.row.status == 2">已上帐</el-tag>
            <el-tag type="success" v-if="scope.row.status == 3">已作废</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="下单时间"
          :align="'center'"
          sortable
          width="160"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="250"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                $router.push('/orderInfo?uuid=' + scope.row.goods_order.uuid)
              "
              >订单详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "promotionOrderRecords",
  components: {
    pagination,
  },
  data() {
    return {
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      customerGroupForm: {
        status: "",
        order_id: "",
        xd_time: "",
        sz_time: "",
      },
      dialogVisible: false,
      value: 5,
      imgs: [
        "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__84f78bae4cddf5a665809315656f99cf.svg",
        "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__21adab465da8deb8b27e917559e883b4.svg",
        "https://axure-file.lanhuapp.com/06b9f5b8-0544-41f9-bee3-1322615ce006__a74ba5d381c943891f0908d7dc409adb.svg",
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.xd_time = v[0] + " - " + v[1];
    },
    // 选择时间
    getTimes2(v) {
      this.customerGroupForm.sz_time = v[0] + " - " + v[1];
    },
    // 列表
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/FxOrder/index?page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&status=${this.customerGroupForm.status}&xd_time=${this.customerGroupForm.xd_time}&sz_time=${this.customerGroupForm.sz_time}&order_id=${this.customerGroupForm.order_id}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    deleteEvaluate() {
      this.$confirm("确定删除该评价吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "成功删除!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.promotionOrderRecords .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img img {
  width: 104px;
  height: 86px;
  border-radius: 8px;
}
</style>
