<template>
  <!-- 添加客户 -->
  <div class="addMaterial">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customerList' }"
        >全部客户</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}客户资料</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="资料项名称" prop="name">
          <el-input
            v-model="basicInfoForm.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="个人主页展示" prop="is_homepage">
          <el-switch
            v-model="basicInfoForm.is_homepage"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <span class="font-12 color-999 mg-l-5"
            >启用后，将在APP端个人主页中展示</span
          >
        </el-form-item>
        <el-form-item label="参数类型" prop="type">
          <el-select v-model="basicInfoForm.type" placeholder="请选择">
            <el-option label="文本" :value="1"></el-option>
            <el-option label="图片" :value="2"></el-option>
            <el-option label="地区" :value="3"></el-option>
            <el-option label="数字" :value="4"></el-option>
            <el-option label="日期" :value="5"></el-option>
            <el-option label="单选" :value="6"></el-option>
            <el-option label="多选" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="选项"
          v-if="basicInfoForm.type == 6 || basicInfoForm.type === 7"
        >
          <div class="chooseBox" v-if="options.length > 0">
            <p
              class="flex align-center mg-b-10"
              v-for="(item, index) in options"
              :key="index"
            >
              <span>{{ chooseIndex[index] }}</span>
              <el-input class="mg-l-20 mg-r-20" v-model="item.name"></el-input>
              <el-button type="text" @click="delChoose(index)">删除</el-button>
            </p>
          </div>
          <el-button type="text" @click="addChoose"> + 添加选项</el-button>
        </el-form-item>
        <template v-if="basicInfoForm.type == 1 || basicInfoForm.type == 4">
          <el-form-item label="文本提示" prop="tip">
            <el-input
              v-model="basicInfoForm.tip"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="文本长度" prop="length">
            <el-input
              v-model="basicInfoForm.length"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item label="是否可重复" prop="is_repeat" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.is_repeat"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
          <span class="font-12 color-999 mg-l-5">启用后，允许字段重复</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "addMaterial",
  data() {
    return {
      imageUrl: "",
      pageType: "",
      isShow: false,
      chooseIndex: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "J",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      options: [],
      basicInfoForm: {
        name: "",
        type: 1,
        is_homepage: 2,
        source: 2,
        tip: "",
        length: "",
        is_repeat: 2,
        option: [],
      },
      rules: {
        name: { required: true, message: "请输入昵称", trigger: "blur" },
        type: { required: true, message: "请选择类型", trigger: "change" },
        "option.name": {
          required: true,
          message: "请选择类型",
          trigger: "blur",
        },
      },
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.getInfo();
      this.pageType = "编辑";
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/UserProfile/${this.$route.query.uuid}`,
      }).then((res) => {
        let params = res.data.result;
        for (let i in params) {
          this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
        }
        this.basicInfoForm.type = Number(this.basicInfoForm.type);
        this.basicInfoForm.is_repeat = Number(this.basicInfoForm.is_repeat);
        this.basicInfoForm.is_homepage = Number(this.basicInfoForm.is_homepage);
        this.basicInfoForm.source = 2;
        this.options = this.basicInfoForm.option;
      });
    },
    addChoose() {
      let obj = {
        name: "",
      };
      this.options.push(obj);
    },
    // 删除选项
    delChoose(i) {
      this.options.splice(i, 1);
    },
    // 提交表单
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.basicInfoForm.type == 6 || this.basicInfoForm.type == 7) {
            let isCross = false;
            let str = "";
            if (this.options.length < 1) {
              this.$message.warning("单选多选类型的资料需要添加选项");
              return false;
            } else {
              this.options.map((item) => {
                if (item.name == "") {
                  this.$message.warning("选项不能为空");
                  return false;
                } else {
                  isCross = true;
                }
              });
            }

            // this.basicInfoForm.option =
            if (!isCross) {
              return false;
            } else {
              this.basicInfoForm.option = this.options.map((item) => item.name);
            }
          }

          delete this.basicInfoForm.admin;
          delete this.basicInfoForm.admin_uuid;
          delete this.basicInfoForm.create_time;
          delete this.basicInfoForm.delete_time;
          delete this.basicInfoForm.field;
          delete this.basicInfoForm.update_time;
          delete this.basicInfoForm.uuid;
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/UserProfile/${this.$route.query.uuid}`,
            }).then((res) => {
              this.$message.success("编辑成功！");
              this.$router.go(-1);
            });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/UserProfile`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addMaterial {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  max-width: 70%;
}
.chooseBox {
  border: 1px solid #e5e5e5;
  padding: 20px;
}
</style>
