<template>
  <div class="activityData" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>活动</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/activityList' }"
        >活动列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>活动数据</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare boxLayout">
      <h3 class="title-t mg-b-30">使用数据</h3>
      <div
        class="analysis flex space-around"
        v-if="Object.keys(dataCount).length > 0"
      >
        <div class="text-center">
          <h6 class="font-12 color-999">活动名额</h6>
          <p class="font-30">
            {{ dataCount.number == null ? 0 : dataCount.number }}
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">报名人数</h6>
          <p class="font-30">{{ dataCount.apply_num }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">签到人数</h6>
          <p class="font-30">{{ dataCount.signed_num }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">扫码签到</h6>
          <p
            class="font-12 mg-t-10"
            @click="show = true"
            style="cursor: pointer; color: blueviolet"
          >
            点击查看二维码
          </p>
        </div>
      </div>

      <div class="dataTable">
        <div class="tabs flex space-between">
          <div class="flex">
            <p
              v-for="(item, index) in tabs"
              :key="index"
              @click="addClass(index)"
              :class="{ on: currentClass === index }"
            >
              {{ item }}
            </p>
          </div>
        </div>
        <div v-if="currentClass === 0">
          <p class="text-right">
            <el-button size="mini" class="mg-b-10" @click="exportList"
              >导出</el-button
            >
          </p>
          <el-table :data="tableData.data" style="width: 100%" border>
            <el-table-column
              prop="create_time"
              label="报名时间"
              sortable
              align="center"
            >
            </el-table-column>
            <el-table-column prop="date" label="报名人信息" align="center">
              <template slot-scope="scope">
                <router-link :to="'/customerInfo?uuid=' + scope.row.user.uuid">
                  <div class="nickAndAvatar">
                    <el-image
                      v-if="
                        scope.row.user != null && scope.row.user.avatar != null
                      "
                      :src="scope.row.user.avatar"
                    ></el-image>
                    <p>
                      <span>{{ scope.row.name }}</span> <br />
                      <span>{{ scope.row.mobile }}</span>
                    </p>
                  </div>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="报名状态"
              sortable
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1">待审核</span>
                <span v-if="scope.row.status == 2" style="color: #2ed477"
                  >审核通过</span
                >
                <span v-if="scope.row.status == 3" style="color: red"
                  >审核不通过</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="apply_num" label="报名次数" align="center">
            </el-table-column>
            <el-table-column
              label="操作"
              :align="'center'"
              fixed="right"
              width="120"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="scope.row.status == 1"
                  @click="pass(scope.row)"
                  >审核通过</el-button
                >
                <el-button
                  type="text"
                  v-if="scope.row.status == 1"
                  @click="refuse(scope.row)"
                  >审核不通过</el-button
                >
                <el-button
                  type="text"
                  v-if="scope.row.status == 3"
                  @click="checkInfo(scope.row)"
                  >审核记录</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="tableData.total"
            :per_page="tableData.per_page"
            :current_page="tableData.current_page"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
          />
        </div>
        <div v-if="currentClass === 1">
          <p class="text-right">
            <el-button size="mini" class="mg-b-10" @click="exportList2"
              >导出</el-button
            >
          </p>
          <el-table :data="tableData.data" style="width: 100%" border="">
            <el-table-column
              prop="create_time"
              label="报名时间"
              sortable
              align="center"
            >
            </el-table-column>
            <el-table-column prop="date" label="报名人信息" align="center">
              <template slot-scope="scope">
                <router-link :to="'/customerInfo?uuid=' + scope.row.user.uuid">
                  <div class="nickAndAvatar">
                    <div
                      v-if="
                        scope.row.user != null && scope.row.user.avatar != null
                      "
                    >
                      <el-image :src="scope.row.user.avatar"></el-image>
                    </div>
                    <p>
                      <span>{{ scope.row.name }}</span> <br />
                      <span>{{ scope.row.mobile }}</span>
                    </p>
                  </div>
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_sign_in"
              label="是否签到"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.is_sign_in == 1">是</span>
                <span v-if="scope.row.is_sign_in == 2">否</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sign_in_time"
              label="签到时间"
              align="center"
            >
            </el-table-column>
          </el-table>
          <pagination
            :total="tableData2.total"
            :per_page="tableData2.per_page"
            :current_page="tableData2.current_page"
            @handleCurrentChange="handleCurrentChange2"
            @handleSizeChange="handleSizeChange2"
          />
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="show" width="30%" center>
      <div class="qrCode">
        <img
          :src="` https://time.api.boyalife.cn/v1/cms/Activity/qrcode?uuid=${this.$route.query.uuid}`"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="show = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "activityData",
  components: {
    pagination,
  },
  data() {
    return {
      show: false,
      tabs: ["报名审核", "签到记录"],
      currentClass: 0,
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      dataCount: {},
      tableData2: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getCount();
  },
  methods: {
    addClass(index) {
      this.currentClass = index;
      if (index == 0) {
        this.getList();
      } else {
        this.getList2();
      }
    },

    // 导出
    exportList() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },

        url: ` https://time.api.boyalife.cn/v1/cms/ActivityApply/export/${this.$route.query.uuid}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;
          link.download = "报名审核列表" + "_" + formattedDate + ".xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 导出
    exportList2() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ActivityApply/exportsigned/${this.$route.query.uuid}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;
          link.download = "签到记录列表" + "_" + formattedDate + ".xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCount() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },

        url: ` https://time.api.boyalife.cn/v1/cms/ActivityApply/count?uuid=${this.$route.query.uuid}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.dataCount = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },

        url: ` https://time.api.boyalife.cn/v1/cms/ActivityApply?activity_uuid=${this.$route.query.uuid}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList2() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },

        url: ` https://time.api.boyalife.cn/v1/cms/ActivityApply/signed?activity_uuid=${this.$route.query.uuid}&page_index=${this.tableData2.current_page}&page_size=${this.tableData2.per_page}`,
      })
        .then((res) => {
          this.tableData2 = res.data.result.list;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    pass(row) {
      this.$confirm("确定审核通过该用户的活动报名信息吗?", "审核通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/ActivityApply/approve/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("审核成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    refuse(row) {
      this.$prompt("确定不通过该用户的报名信息吗？", "审核不通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        inputPlaceholder: "请输入不通过理由",
        inputType: "textarea",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "理由不能为空",
      }).then(({ value }) => {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            reason: value,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/ActivityApply/reject/${row.uuid}`,
        })
          .then((res) => {
            this.$message.success("审核成功！");
            this.getList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.error.message);
          });
      });
    },
    checkInfo(row) {
      let html = `
        <h5 class="mg-l-30 mg-b-10">操作人: ${row.op_by_user}</h5>
        <h5 class="mg-l-30 mg-b-10">操作时间：${row.op_at}</h5>
        <h5 class="mg-l-30 mg-b-10">审核结果：审核不通过</h5>
        <h5 class="mg-l-30 mg-b-10">不通过原因：${row.reason}</h5>
      `;
      this.$confirm(html, "查看审核记录", {
        confirmButtonText: "确认",
        showCancelButton: false,
        dangerouslyUseHTMLString: true,
      })
        .then(() => {})
        .catch(() => {});
    },

    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
    handleSizeChange2(v) {
      this.tableData2.per_page = v;
      this.getList2();
    },
    handleCurrentChange2(v) {
      this.tableData2.current_page = v;
      this.getList2();
    },
  },
};
</script>
<style scoped>
.dataTable {
  box-shadow: none;
  border-radius: 0;
  margin-top: 50px;
}
.dataTable .tabs {
  position: static;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.qrCode {
  text-align: center;
}
.qrCode img {
  width: 200px;
  height: 200px;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on {
  color: #409eff;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
/deep/ .el-message-box__message img {
  width: 100px;
  height: 100px;
}
</style>
