<template>
  <!-- 添加客户 -->
  <div class="addSupplierList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>门店</el-breadcrumb-item>
      <el-breadcrumb-item>门店关联</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/supplierList' }">
        供应商列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}供应商</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="供应商名称" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="供应商地址">
          <el-cascader
            :options="cityList"
            v-model="address"
            @change="getCityValue"
            :props="props"
            collapse-tags
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" v-if="districtValue !== ''">
          <el-input v-model="basicInfoForm.address"></el-input>
        </el-form-item>
        <el-form-item label="所属品牌" prop="brand_uuid">
          <el-select
            clearable
            v-model="basicInfoForm.brand_uuid"
            placeholder="请选择"
          >
            <el-option
              placeholder="请选择"
              clearable
              v-for="item in brandList"
              :key="item.uuid"
              :label="item.name"
              :value="item.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="basicInfoForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="basicInfoForm.contact_mobile"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="sumbitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "addSupplierList",
  data() {
    return {
      imageUrl: "",
      pageType: "",
      isShow: false,
      brandList: [],
      provinceoptions: [],
      provinceValue: "",
      cityOptions: [],
      cityValue: "",
      districtOptions: [],
      districtValue: "",
      address: [],
      cityList: [],
      props: {
        label: "area_name",
        value: "area_id",
      },
      basicInfoForm: {
        brand_uuid: "",
        address: "",
        province_uuid: "",
        city_uuid: "",
        area_uuid: "",
        contact_mobile: "",
        contact: "",
      },
      rules: {
        name: { required: true, message: "请输入供应商名称", trigger: "blur" },
        brand_uuid: {
          required: true,
          message: "请选择品牌",
          trigger: "change",
        },
      },
    };
  },
  created() {
    this.getBrandList();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
      console.log(this.basicInfoForm);

      this.basicInfoForm.province !== ""
        ? (this.basicInfoForm.province = JSON.parse(
            this.basicInfoForm.province
          ))
        : "";
      this.basicInfoForm.city !== ""
        ? (this.basicInfoForm.city = JSON.parse(this.basicInfoForm.city))
        : "";
      this.basicInfoForm.area !== ""
        ? (this.basicInfoForm.area = JSON.parse(this.basicInfoForm.area))
        : "";

      console.log(typeof this.basicInfoForm.province);
      this.getProvince(-1);
      this.getCity(this.basicInfoForm.province_uuid);
      this.getArea(this.basicInfoForm.city_uuid);
      this.provinceValue = this.basicInfoForm.province;
      this.cityValue = this.basicInfoForm.city;
      this.districtValue = this.basicInfoForm.area;
      console.log(this.districtValue);
    } else {
      this.pageType = "新增";
    }
    this.getProvince(-1);
    this.getCity(this.basicInfoForm.province_uuid);
  },

  methods: {
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Region`,
      })
        .then((res) => {
          this.cityList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 地区
    getCity() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Region`,
      })
        .then((res) => {
          this.cityList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCityValue(v) {
      this.basicInfoForm.province_uuid = v[0];
      this.basicInfoForm.city_uuid = v[1];
      this.basicInfoForm.area_uuid = v[2];
    },
    search(obj) {
      this.basicInfoForm.address = "";
      if (obj.level == 1) {
        this.basicInfoForm.province_uuid = obj.area_id;
        this.cityValue = "";
        this.districtValue = "";
        this.getCity(obj.area_id);
      }
      if (obj.level == 2) {
        this.basicInfoForm.city_uuid = obj.area_id;
        this.districtValue = "";
        this.getArea(obj.area_id);
      } else {
        this.basicInfoForm.area_uuid = obj.area_id;
      }
    },
    clearProvince() {
      this.basicInfoForm.provinceValue = "";
      this.basicInfoForm.cityValue = "";
      this.basicInfoForm.districtValue = "";
    },
    clearCity() {
      this.basicInfoForm.cityValue = "";
      this.basicInfoForm.districtValue = "";
    },
    clearDis() {
      this.basicInfoForm.districtValue = "";
    },
    // 品牌列表
    getBrandList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Brand`,
      })
        .then((res) => {
          console.log(res);
          this.brandList = res.data.result.data;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 提交表单
    sumbitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Supplier/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Supplier`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style scoped>
.addSupplierList {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form .el-input {
  width: 400px;
}
.el-form .el-input-group > .el-input__inner {
  width: 60px;
}
.el-form .el-checkbox-group {
  width: 500px;
}
.el-form .flex {
  flex-wrap: wrap;
}
.el-form .flex .flex {
  width: 40%;
  flex-wrap: nowrap;
}
</style>
