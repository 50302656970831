<template>
  <div class="addProjectProcess">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/processManage'">进度管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加项目进度</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t">基础信息</h3>
      <div class="box mg-t-30 mg-l-30">
        <el-form
          ref="form"
          :model="basicForm"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item label="项目名称" prop="s_name">
            <el-input
              v-model="basicForm.s_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否启用" prop="status" placeholder="请输入">
            <el-switch
              v-model="basicForm.status"
              :active-value="1"
              :inactive-value="2"
              active-color="#13ce66"
              inactive-color="gray"
            >
            </el-switch>
          </el-form-item>
          <div class="flex">
            <span>项目进度</span>
            <el-button type="text" class="mg-l-20" @click="add"
              >+ 添加进度</el-button
            >
          </div>
          <draggable
            v-model="basicForm.schedule"
            group="people"
            @change="change"
          >
            <div
              class="processForm"
              v-for="(item, index) in basicForm.schedule"
              :key="index"
            >
              <div v-if="item.isDisabled != 'undefined'">
                <el-form-item label="进度名称" prop="name">
                  <el-input
                    v-model="item.s_name"
                    :disabled="item.isDisabled"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="进度描述">
                  <el-input
                    v-model="item.s_desc"
                    placeholder="请输入"
                    :disabled="item.isDisabled"
                  ></el-input>
                </el-form-item>
                <el-form-item label="进度图片(1-5张)">
                  <el-button
                    v-if="item.s_img.length < 5 && !item.isDisabled"
                    class="avatar-uploader"
                    @click="chooseBanner(1, index)"
                  >
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-button>
                  <div
                    class="cover_image_list flex"
                    v-if="item.s_img.length > 0"
                  >
                    <draggable v-model="item.s_img" group="image">
                      <div class="img" v-for="(jtem, i) in item.s_img" :key="i">
                        <i
                          class="el-icon-delete"
                          v-if="!item.isDisabled"
                          @click.stop="deleteImg(index, i)"
                        ></i>
                        <el-image :src="jtem" fit="cover"></el-image>
                      </div>
                    </draggable>
                  </div>
                </el-form-item>
                <el-form-item label="进度附件">
                  <el-button
                    v-if="!item.isDisabled"
                    type="primary"
                    plain
                    @click="chooseBanner(2, index)"
                  >
                    添加附件
                  </el-button>
                  <div v-if="Object.keys(item.s_ext).length > 0">
                    <i
                      v-if="!item.isDisabled && item.s_ext.name !== ''"
                      class="el-icon-s-order"
                    ></i>
                    <span class="mg-l-10 mg-r-50">{{ item.s_ext.name }}</span>
                    <div
                      style="cursor: pointer"
                      v-if="!item.isDisabled"
                      class="el-icon-close"
                      @click="item.s_ext = {}"
                    ></div>
                  </div>
                </el-form-item>
                <el-form-item class="btn">
                  <el-button
                    type="text"
                    v-if="!item.isDisabled"
                    @click="save(index)"
                    >保存</el-button
                  >
                  <el-button
                    type="text"
                    v-if="item.isDisabled"
                    @click="edit(item, index)"
                    >编辑</el-button
                  >
                  <el-button type="text" @click="del(index)">删除</el-button>
                </el-form-item>
              </div>
            </div>
          </draggable>
        </el-form>
      </div>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @selectImg="selectImg"
        @insertFile="insertFile"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import draggable from "vuedraggable";
export default {
  name: "addProjectProcess",
  components: {
    draggable,
    MaterialLibrary,
  },
  data() {
    return {
      imgType: "",
      currentIndex: "",
      dialogVisible: false,
      fileList: [],
      basicForm: {
        status: 1,
        schedule: [
          {
            s_name: "",
            s_desc: "",
            s_img: [],
            s_ext: {},
            isDisabled: false,
          },
        ],
      },
      rules: {},
    };
  },
  created() {
    if (this.$route.query.uuid) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Schedule/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          let d = res.data.result;
          this.basicForm.schedule = [];
          for (let i in d) {
            this.basicForm[i] = d[i];
          }
          this.basicForm.schedule.map((item) => {
            item.isDisabled = this.$route.query.uuid ? true : false;
            let arr = [];
            item.s_img.map((jtem) => {
              arr.push(jtem.s_img);
            });
            if (item.s_ext == null) {
              item.s_ext = {};
            }
            item.s_img = arr;
          });
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    }
  },

  methods: {
    // 选择banner
    chooseBanner(i, index) {
      this.currentIndex = index;
      if (i == 1) {
        this.imgType = "chooseThumbnail_image";
      } else {
        this.imgType = "chooseFile";
      }
      this.dialogVisible = true;
    },
    // 删除图片
    deleteImg(index, i) {
      this.basicForm.schedule[index].s_img.splice(i, 1);
    },
    // 选择图片
    selectImg(item) {
      this.basicForm.schedule[this.currentIndex].s_img.push(item.url);
      this.dialogVisible = false;
    },
    insertFile(row) {
      console.log(row);
      this.basicForm.schedule[this.currentIndex].s_ext.name = row.name;
      this.basicForm.schedule[this.currentIndex].s_ext.url = row.url;

      this.dialogVisible = false;
    },
    add() {
      let data = {
        s_name: "",
        s_desc: "",
        s_img: [],
        s_ext: {},
        isDisabled: false,
      };
      this.basicForm.schedule.unshift(data);
    },
    edit(item, index) {
      this.$forceUpdate();
      this.basicForm.schedule[index].isDisabled =
        !this.basicForm.schedule[index].isDisabled;
    },
    save(index) {
      let form = this.basicForm.schedule[0];
      if (form.s_img.length > 0) {
        form.s_img = form.s_img.join(",");
      } else {
        form.s_img = "";
      }
      delete form.isDisabled;
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: form,
        url: ` https://time.api.boyalife.cn/v1/cms/Schedule/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.$router.go(-1);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
      // this.$forceUpdate()

      // this.basicForm.schedule[index].isDisabled = !this.basicForm.schedule[index].isDisabled
    },
    del(index) {
      this.basicForm.schedule.splice(index, 1);
    },
    // 监听拖拽
    change(event) {
      console.log("change");
      console.log(event);
      console.log(this.basicForm.schedule);
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.basicForm.schedule.length < 1) {
            this.$message.warning("请至少添加一条项目进度");
            return false;
          }
          this.basicForm.number = this.basicForm.schedule.length;
          this.basicForm.schedule.map((item) => {
            if (item.ext.url !== "") {
              item.ext = item.ext.url;
            }
            if (item.uuid) delete item.uuid;
            if (item.schedules_uuid) delete item.schedules_uuid;
            delete item.isDisabled;
          });
          for (let i in this.basicForm.schedule) {
            if (this.basicForm.schedule[i] == undefined) {
              this.basicForm.schedule[i] = null;
            }
          }
          console.log(this.basicForm.schedule);

          delete this.basicForm.uuid;
          delete this.basicForm.create_time;
          delete this.basicForm.update_time;
          delete this.basicForm.delete_time;
          if (!this.$route.query.uuid) {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Schedule`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Schedule/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addProjectProcess {
  margin-bottom: 80px;
}
.el-button.el-button--text {
  padding: 0 10px;
}
.processForm {
  margin-left: 86px;
  padding: 20px;
  background: rgb(240, 248, 252);
  margin-top: 20px;
  position: relative;
}
.el-form .btn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.cover_image_list > div {
  flex-wrap: wrap;
  margin-top: 20px;
  display: flex;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
.el-input {
  width: 400px;
}
</style>
