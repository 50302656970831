<template>
  <div class="addMedal">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/medalList'">勋章列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageType }}勋章</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="勋章名称" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" placeholder="请输入">
          <el-switch
            v-model="basicInfoForm.status"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            v-model="basicInfoForm.desc"
            type="textarea"
            rows="5"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.cover_image != ''"
              :src="basicInfoForm.cover_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseThumbnail">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：800*800像素</p>
        </el-form-item>
        <el-form-item label="详情图">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest2"
          >
          <img
              v-if="basicInfoForm.detail_image != ''"
              :src="basicInfoForm.detail_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseCover_image">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：750*800像素</p>
        </el-form-item>
        <el-form-item
          label="勋章分类"
          prop="category_uuid"
          placeholder="请输入"
        >
          <el-select
            v-model="basicInfoForm.category_uuid"
            placeholder="请选择"
            v-if="options.length > 0"
          >
            <el-option-group
              v-for="group in options"
              :key="group.uuid"
              :label="group.name"
              :value="group.uuid"
            >
              <el-option
                v-for="item in group.children"
                :key="item.uuid"
                :label="item.name"
                :value="item.uuid"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <template v-if="typeYe != ''">
          <el-form-item label="获取条件" placeholder="请输入">
            <el-radio-group v-model="typeYe" @input="selectType">
              <el-radio :label="'is_sign_in'">连续签到</el-radio>
              <el-radio :label="'is_share'">分享文章</el-radio>
              <el-radio :label="'is_view'">阅读文章</el-radio>
              <el-radio :label="'is_level'">会员等级</el-radio>
              <el-radio :label="'is_item'">预约项目</el-radio>
              <el-radio :label="'is_consume'">累计消费</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="typeYe == 'is_sign_in'"
            :prop="typeYe == 'is_sign_in' ? 'sign_in_day' : ''"
            placeholder="请输入"
          >
            <p class="flex align-center">
              <span>连续签到 ≥</span>
              <el-input
                style="width: 200px; margin-left: 10px"
                v-model="basicInfoForm.sign_in_day"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">天</template></el-input
              >
            </p>
          </el-form-item>
          <el-form-item
            v-if="typeYe == 'is_share'"
            :prop="typeYe == 'is_share' ? 'share_num' : ''"
            placeholder="请输入"
          >
            <p class="flex align-center">
              <span>分享 ≥</span>
              <el-input
                style="width: 200px; margin-left: 10px"
                v-model="basicInfoForm.share_num"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">篇文章</template></el-input
              >
            </p>
          </el-form-item>
          <el-form-item
            v-if="typeYe == 'is_view'"
            :prop="typeYe == 'is_view' ? 'view_num' : ''"
            placeholder="请输入"
          >
            <p class="flex align-center">
              <span>阅读 ≥</span>
              <el-input
                style="width: 200px; margin-left: 10px"
                v-model="basicInfoForm.view_num"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">篇文章</template></el-input
              >
            </p>
          </el-form-item>
          <el-form-item
            v-if="typeYe == 'is_level'"
            :prop="typeYe == 'is_level' ? 'user_level' : ''"
            placeholder="请输入"
          >
            <p class="flex align-center">
              <span>会员等级 ≥</span>
              <!-- <el-input style="width: 200px;margin-left:10px" v-model="basicInfoForm.user_level" class="ipt" placeholder="请输入"><template slot="prepend">级</template></el-input> -->
              <el-select
                class="mg-l-20"
                v-model="basicInfoForm.user_level"
                placeholder="请选择"
              >
                <el-option
                  v-for="(jtem, i) in levelList"
                  :key="i"
                  :label="jtem"
                  :value="i"
                >
                </el-option>
              </el-select>
            </p>
          </el-form-item>
          <el-form-item
            v-if="typeYe == 'is_item'"
            :prop="typeYe == 'is_item' ? 'item_num' : ''"
            placeholder="请输入"
          >
            <p class="flex align-center">
              <span>预约 ≥</span>
              <el-input
                style="width: 200px; margin-left: 10px"
                v-model="basicInfoForm.item_num"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">次项目</template></el-input
              >
            </p>
          </el-form-item>
          <el-form-item
            v-if="typeYe == 'is_consume'"
            :prop="typeYe == 'is_consume' ? 'consume_price' : ''"
            placeholder="请输入"
          >
            <p class="flex align-center">
              <span>累计消费 ≥</span>
              <el-input
                style="width: 200px; margin-left: 10px"
                v-model="basicInfoForm.consume_price"
                class="ipt"
                placeholder="请输入"
                ><template slot="prepend">金额</template></el-input
              >
            </p>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="sumbitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"/>

    </el-dialog>
  </div>
</template>
<script>
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addMedal",
  components: {
    imageDialog,
  },

  data() {
    return {
      imgType: "chooseThumbnail_image",
      levelList: [],
      dialogVisible: false,
      options: [],
      typeYe: "is_sign_in",
      basicInfoForm: {
        cover_image: "",
        detail_image: "",
        name: null,
        desc: "",
        status: 1,
        is_hot: 1,
        fid: 0,
        category_uuid: "",
        is_sign_in: 2,
        is_share: 2,
        is_view: 2,
        is_level: 2,
        is_item: 2,
        is_consume: 2,
        consume_price: "",
        item_num: "",
        user_level: "",
        view_num: "",
        share_num: "",
        sign_in_day: "",
      },
      rules: {
        consume_price: { required: true, message: "不能为空", trigger: "blur" },
        item_num: { required: true, message: "不能为空", trigger: "blur" },
        user_level: { required: true, message: "不能为空", trigger: "blur" },
        view_num: { required: true, message: "不能为空", trigger: "blur" },
        share_num: { required: true, message: "不能为空", trigger: "blur" },
        sign_in_day: { required: true, message: "不能为空", trigger: "blur" },

        name: [{ required: true, message: "分类名称必填", trigger: "blur" }],
        category_uuid: [
          { required: true, message: "分类名称必选", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getCategory();
    this.getLevelList();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      console.log(this.typeYe);
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
    confirmImageDialog (item) {
      if (this.imgType === "chooseThumbnail_image") {
        this.basicInfoForm.cover_image = item[0].url
      } else {
        this.basicInfoForm.detail_image = item[0].url
      }
      this.dialogVisible = false

    },
    httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.cover_image =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.detail_image =  res[0].url;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getInfo() {
      let that = this;
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Medal/${this.$route.query.uuid}`,
      }).then((res) => {
        let params = res.data.result;
        for (let i in params) {
          that.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
        }
        that.basicInfoForm.status = Number(that.basicInfoForm.status);
        if (that.basicInfoForm.is_sign_in == 1) {
          that.typeYe = "is_sign_in";
        }
        if (that.basicInfoForm.is_share == 1) {
          that.typeYe = "is_share";
        }
        if (that.basicInfoForm.is_view == 1) {
          that.typeYe = "is_view";
        }
        if (that.basicInfoForm.is_level == 1) {
          that.typeYe = "is_level";
        }
        if (that.basicInfoForm.is_item == 1) {
          that.typeYe = "is_item";
        }
        if (that.basicInfoForm.is_consume == 1) {
          that.typeYe = "is_consume";
        }
        console.log(that.basicInfoForm.is_share);
        // for( let i in that.basicInfoForm) {
        //   if (i != 'status') {
        //     if (that.basicInfoForm[i] == 1) {
        //       that.typeYe = i + ''
        //     }
        //   }
        // }
        // that.basicInfoForm.status = Number(that.basicInfoForm.status)
        // that.typeYe = that.basicInfoForm.is_sign_in == 1 ?  'is_sign_in' : ''
        // that.typeYe = that.basicInfoForm.is_share == 1 ?  'is_share' : ''
        // that.typeYe = that.basicInfoForm.is_view == 1 ?  'is_view' : ''
        // that.typeYe = that.basicInfoForm.is_level == 1 ?  'is_level' : ''
        // that.typeYe = that.basicInfoForm.is_item == 1 ?  'is_item' : ''
        // that.typeYe = that.basicInfoForm.is_consume == 1 ?  'is_consume' : ''
        console.log(that.typeYe);
      });
    },
    selectType(v) {
      console.log(v);
      if (v == "is_sign_in") {
        this.basicInfoForm.is_sign_in = 1;
      } else {
        this.basicInfoForm.is_sign_in = 2;
      }
      if (v == "is_share") {
        this.basicInfoForm.is_share = 1;
      } else {
        this.basicInfoForm.is_share = 2;
      }
      if (v == "is_view") {
        this.basicInfoForm.is_view = 1;
      } else {
        this.basicInfoForm.is_view = 2;
      }
      if (v == "is_level") {
        this.basicInfoForm.is_level = 1;
      } else {
        this.basicInfoForm.is_level = 2;
      }

      if (v == "is_item") {
        this.basicInfoForm.is_item = 1;
      } else {
        this.basicInfoForm.is_item = 2;
      }
      if (v == "is_consume") {
        this.basicInfoForm.is_consume = 1;
      } else {
        this.basicInfoForm.is_consume = 2;
      }
    },
    getLevelList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择缩略图
    chooseThumbnail() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    chooseCover_image() {
      this.imgType = "chooseCover_image";
      this.dialogVisible = true;
    },
    // 选择封面
    selectImg(item) {
      if (this.imgType === "chooseThumbnail_image") {
        this.basicInfoForm.cover_image = item.url;
      } else {
        this.basicInfoForm.detail_image = item.url;
      }
      this.dialogVisible = false;
    },
    getCategory() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/MedalCategory`,
      }).then((res) => {
        this.options = res.data.result;
        console.log(this.options);
      });
    },
    // 提交表单
    sumbitForm(form) {
      this.basicInfoForm.is_sign_in =
        this.basicInfoForm.is_sign_in == true ? 1 : 2;
      this.basicInfoForm.is_share = this.basicInfoForm.is_share == true ? 1 : 2;
      this.basicInfoForm.is_view = this.basicInfoForm.is_view == true ? 1 : 2;
      this.basicInfoForm.is_level = this.basicInfoForm.is_level == true ? 1 : 2;
      this.basicInfoForm.is_item = this.basicInfoForm.is_item == true ? 1 : 2;
      this.basicInfoForm.is_consume =
        this.basicInfoForm.is_consume == true ? 1 : 2;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            delete this.basicInfoForm.create_time;
            delete this.basicInfoForm.delete_time;
            delete this.basicInfoForm.update_time;
            delete this.basicInfoForm.uuid;
            delete this.basicInfoForm.category;

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Medal/${this.$route.query.uuid}`,
            }).then((res) => {
              this.$message.success("编辑成功！");
              this.$router.go(-1);
            });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Medal`,
            }).then((res) => {
              this.$message.success("新增成功！");
              this.$router.go(-1);
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addMedal {
  margin-bottom: 80px;
}
.addMedal .el-form {
  width: 800px;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
