<template>
  <div class="flex bbox">
    <el-upload
      class="upload-demo"
      action="#"
      :accept="acceptType"
      :show-file-list="false"
      :http-request="httpRequest"
      :before-upload="beforeUpload"
    >
      <el-button type="primary" icon="el-icon-plus" v-if="activeIndex !== ''"
        >添加文件</el-button
      >
    </el-upload>
    <div class="menu">
      <dl>
        <dt class="flex space-between mg-b-20">
          <span>全部分组</span>
          <i class="el-icon-circle-plus-outline" @click="addGroup(submitType)"></i>
        </dt>
        <dd
          :class="{ current: activeIndex === index }"
          v-for="(item, index) in list"
          :key="index"
          @click="changeMenu(item, index)"
        >
          <div class="flex space-between align-center">
            <div class="flex align-center">
              <i class="el-icon-s-order"></i>
              <span>{{ item.name }}</span>
            </div>
            <div class="btn">
              <el-button type="text" @click="editGroup(item)">编辑</el-button>
              <el-button type="text" @click="delFileGroupList(item)">删除</el-button>
            </div>
          </div>
        </dd>
      </dl>
    </div>
    <div class="image mg-l-20">
      <div v-if="activeIndex !== ''">
        <el-form :inline="true" :model="customerGroupForm" class="demo-form-inline">
          <el-form-item label="创建日期">
            <el-date-picker
              v-model="customerGroupForm.time"
              type="daterange"
              align="right"
              @change="getTimes"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字" class="keyword">
            <el-input
              placeholder="请输入内容"
              v-model="customerGroupForm.search_keyword"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getFileList">查询</el-button>
          <el-button size="medium" @click="clearForm">清空</el-button>
        </div>
      </div>
      <div
        style="
          height: 60%;
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(100vh - 423px);
        "
      >
        <div v-if="tableData.data.length > 0">
          <el-table
            ref="multipleTable"
            :data="tableData.data"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              prop="name"
              label="文件名称"
              align="center"
              width="280"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="size" align="center" label="文件大小">
            </el-table-column>
            <el-table-column prop="format" align="center" label="文件格式">
              <template slot-scope="scope"> PDF </template>
            </el-table-column>
            <el-table-column align="center" prop="preview" label="文件预览">
              <template slot-scope="scope">
                <span style="color: blueviolet" @click.stop="viewPdf(scope.row)"
                  >点击预览</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="创建时间"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column label="操作" :align="'center'" width="200" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="showFileDialog(scope.row)">编辑</el-button>
                <el-button type="text" @click="delVideo(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-empty description="暂无文件"></el-empty>
        </div>
      </div>
      <div class="text-center mg-t-20">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableData.current_index"
          :page-sizes="[12, 24, 36, 48, 60]"
          :page-size="Number(tableData.per_page)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="编辑文件信息" :visible.sync="fileDialogVisible" width="30%">
      <div class="flex align-center mg-t-20">
        <span style="width: 100px">文件名称</span>
        <el-input placeholder="请输入" v-model="fileLittleName"> </el-input>
      </div>
      <div class="flex align-center mg-t-20">
        <span style="width: 80px">选择分组</span>
        <el-select
          v-model="editGroup_uuid"
          placeholder="请选择"
          @change="changeGroupUuid"
        >
          <el-option
            v-for="item in list"
            :key="item.uuid"
            :label="item.name"
            :value="item.uuid"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fileDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitVideoInfo">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%">
      <div class="flex align-center mg-t-20">
        <span style="width: 80px">分组名称</span>
        <el-input placeholder="请输入" v-model="fileName"> </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFileGroupList">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="pdfpreview"
      :visible.sync="pdfPreviewDialog"
      :title="pdfTitle"
      width="80%"
      :close-on-click-modal="false"
      style="margin-top: 0 !important"
    >
      <div class="arrow">
        <span @click="changePdfPage(0)" class="turn">上一页</span>
        <p>{{ pdfPage }} / {{ pageCount }}</p>
        <span @click="changePdfPage(1)" class="turn">下一页</span>
      </div>
      <div class="pdf">
        <pdf
          ref="pdf"
          :src="pdfUrl"
          :page="pdfPage"
          @num-pages="pageCount = $event"
          @page-loaded="pdfPage = $event"
          @loaded="loadPdfHandler"
        >
        </pdf>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  name: "",
  components: {
    pdf,
  },
  data() {
    return {
      pdfTitle: "提示",
      pdfPreviewDialog: false,
      pdfPage: null,
      pageCount: "",
      pdfUrl: "",
      acceptType: ".pdf,.xlsx",
      activeIndex: 0,
      dialogVisible: false,
      imgDialogVisible: false,
      list: [],
      submitType: "add",
      group_uuid: "",
      editGroup_uuid: "",
      title: "添加分组",
      customerGroupForm: {
        time: "",
        search_start_time: "",
        search_end_time: "",
        per_page: 12,
        current_index: 1,
        search_keyword: "",
      },
      fileDialogVisible: false,
      fileName: "",
      fileLittleName: "",
      tableData: {
        data: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getFileGroupList();
    this.getTotal();
  },
  mounted() {},
  methods: {
    getTotal(url) {
      this.pdfUrl = pdf.createLoadingTask(url);
      this.pdfUrl.promise.then((pdf) => {
        this.pageCount = pdf._pdfInfo.numPages;
      });
    },
    viewPdf(item) {
      this.pdfPreviewDialog = true;
      this.getTotal(item.url);
      this.pdfTitle = item.name;
    },
    changePdfPage(val) {
      if (val === 0 && this.pdfPage > 1) {
        this.pdfPage--;
      }
      if (val === 1 && this.pdfPage < this.pageCount) {
        this.pdfPage++;
      }
    },
    loadPdfHandler(e) {
      this.pdfPage = 1; // 加载的时候先加载第一页
    },
    // 点击编辑文件弹窗
    showFileDialog(item) {
      console.log(item);
      this.fileDialogVisible = true;
      this.group_uuid = item.group_uuid;
      this.fileLittleName = item.name;
      this.uuid = item.uuid;
      this.editGroup_uuid = item.group_uuid;
    },
    changeGroupUuid(v) {
      this.group_uuid = v;
    },
    beforeUpload(file) {
      console.log(file);
      this.$message.info("稍等片刻，正在上传...");
      if (file.name.split(".")[1].toLowerCase() != "pdf") {
        this.$message.error("文件格式错误~");
        return false; // 终止上传
      }
      this.formData = new FormData();
      this.formData.append("file", file);
      this.formData.append("name", file.name);
      this.formData.append("group_uuid", this.group_uuid);
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 上传图片
    httpRequest(data) {
      this.$api.materialLibrary
        .addFile(this.formData)
        .then((res) => {
          this.$message.success("上传成功！");
          this.getFileList();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 点击更换组
    changeMenu(item, index) {
      console.log(item);
      this.group_uuid = item.uuid;
      this.activeIndex = index;
      this.getFileList();
    },
    // 添加分组
    addGroup() {
      this.dialogVisible = true;
      this.title = "添加分组";
      this.submitType = "add";
    },
    // 获取分组列表
    getFileGroupList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/FileGroup`,
      }).then((res) => {
        this.list = res.data.result;
        this.getFileList(this.list[0].uuid);
      });
    },
    // 新增分组// 编辑分组
    addFileGroupList() {
      if (this.submitType === "add") {
        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            name: this.fileName,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/FileGroup`,
        }).then((res) => {
          this.dialogVisible = false;
          this.$message.success("新增成功！");
          this.getFileGroupList();
        });
      } else {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: {
            name: this.fileName,
          },
          url: ` https://time.api.boyalife.cn/v1/cms/FileGroup/${this.group_uuid}`,
        }).then((res) => {
          this.dialogVisible = false;
          this.$message.success("编辑成功！");
          this.getFileGroupList();
        });
      }
    },
    // 删除分组
    delFileGroupList(item) {
      this.group_uuid = item.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/FileGroup/${this.group_uuid}`,
          }).then((res) => {
            this.$message.success("删除成功！");
            this.getFileGroupList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    // 获取文件列表
    getFileList(group_uuid) {
      if (group_uuid) this.group_uuid = group_uuid;
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/File?group_uuid=${this.group_uuid}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&page_size=${this.customerGroupForm.per_page}&page_index=${this.customerGroupForm.current_page}`,
      }).then((res) => {
        this.tableData = res.data.result;
      });
    },
    // 编辑分组
    editGroup(item) {
      this.dialogVisible = true;
      this.title = "编辑分组";
      this.submitType = "edit";
      this.group_uuid = item.uuid;
      this.fileName = item.name;
    },
    // 编辑文件信息
    submitVideoInfo() {
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          name: this.fileLittleName,
          group_uuid: this.editGroup_uuid,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/File/${this.uuid}`,
      }).then((res) => {
        //执行成功后代码处理
        this.fileDialogVisible = false;
        this.getFileList();
        this.$message.success("编辑成功！");
      });
    },
    // 删除文件
    delVideo(item) {
      this.uuid = item.uuid;
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "text/html;charset=UTF-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/File/${this.uuid}`,
          }).then((res) => {
            //执行成功后代码处理
            this.getFileList();
            this.$message.success("删除成功！");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleCurrentChange(v) {
      this.customerGroupForm.current_index = v;
    },
    handleSizeChange(v) {
      this.customerGroupForm.per_page = v;
    },
    // 清空查询参数
    clearForm() {
      this.customerGroupForm = {
        time: "",
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        per_page: 12,
        current_index: 1,
      };
    },
  },
};
</script>
<style scoped>
.menu {
  width: 20%;
  min-width: 200px;
}
.menu .btn {
  display: none;
}
.menu dl dd {
  cursor: pointer;
  padding: 10px 5px;
}
.menu dl dd:hover,
.menu dl dd.current {
  background: #f6f9fe;
  color: #2561ef;
}
.menu dl dd .el-button {
  padding: 0;
}
.menu dl dd >>> .el-button span {
  font-size: 12px;
}
.menu dl dd:hover .btn {
  display: block;
}
.menu dl dd:hover > div > div:nth-child(1) {
  width: 70%;
}
.image {
  width: 80%;
}
.el-icon-circle-plus-outline {
  color: #2561ef;
  font-size: 16px;
  cursor: pointer;
}
.el-icon-s-order {
  color: #ffc71c;
  font-size: 18px;
  margin-right: 10px;
  margin-top: 2px;
}
.bbox {
  position: relative;
}
.bbox .upload-demo {
  position: absolute;
  right: 0;
  top: -74px;
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.arrow span {
  padding: 10px 20px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  display: inline-block;
  margin: 20px;
  cursor: pointer;
}
/* .pdf {
  max-height: 100vh;
  overflow: auto;
} */
</style>
