<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>积分分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="overview boxLayout">
      <div class="title flex space-between align-center">
        <h3 class="title-t">积分数据概览</h3>
        <div class="flex">
          <el-form
            :inline="true"
            :model="customerGroupForm"
            class="demo-form-inline"
          >
            <el-form-item label="选择时间">
              <el-date-picker
                class="mg-l-20"
                v-model="date1"
                type="daterange"
                align="right"
                unlink-panels
                @change="getTimes"
                :clearable="false"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy/MM/dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-button @click="integralDataExport">导出</el-button>
        </div>
      </div>
      <div class="flex mg-t-20" v-if="Object.keys(integralData).length > 0">
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            可用积分
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ integralData.available_points || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>
              {{ integralData.available_points_updown == 1 ? "+" : "-" }}
              {{ integralData.available_points_compare || 0 }}%
            </p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            冻结中积分
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ integralData.freeze_points || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>
              {{ integralData.freeze_points_updown == 1 ? "+" : "-" }}
              {{ integralData.freeze_points_compare || 0 }}%
            </p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            积分消耗率
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">
            {{ integralData.consume_points_ratio || 0 }}
          </p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>
              {{ integralData.consume_points_ratio_updown == 1 ? "+" : "-" }}
              {{ integralData.consume_points_ratio_compare || 0 }}%
            </p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            累计发放积分
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ integralData.grant_points || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>
              {{ integralData.grant_points_updown == 1 ? "+" : "-" }}
              {{ integralData.grant_points_compare || 0 }}%
            </p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            累积消耗积分
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ integralData.consume_points || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>
              {{ integralData.consume_points_updown == 1 ? "+" : "-" }}
              {{ integralData.consume_points_compare || 0 }}%
            </p>
          </div>
        </div>
        <div class="box pad-20 mg-r-10">
          <h4 class="color-999 mg-b-10">
            过期积分
            <el-tooltip
              class="item"
              effect="dark"
              content="Top Left 提示文字"
              placement="top"
            >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </h4>
          <p class="font-24 mg-b-10">{{ integralData.expired_points || 0 }}</p>
          <div class="flex color-999 space-between">
            <p>环比</p>
            <p>
              {{ integralData.expired_points_updown == 1 ? "+" : "-" }}
              {{ integralData.expired_points_compare || 0 }}%
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
  .toString()
  .padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24);
let timed = start.toLocaleDateString();
export default {
  name: "",
  data() {
    return {
      customerGroupForm: {
        start_time: "",
        end_time: "",
      },
      date1: [],

      integralData: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getIntegralData();
  },
  methods: {
    // 商品概览
    getIntegralData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/score?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`,
      })
        .then((res) => {
          this.integralData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 积分分析导出
    integralDataExport() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/scoreexport?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `积分分析 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.start_time = v[0] + " 00:00:00";
      this.customerGroupForm.end_time = v[1] + " 23:59:59";
      this.getIntegralData();
    },
  },
};
</script>
<style scoped>
.overview .flex {
  flex-wrap: wrap;
}
>>> .el-form-item {
  margin-bottom: 0;
}
.overview .box {
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  min-width: 175px;
}
</style>
