<template>
  <div class="claimRecords" style="padding-bottom: 80px">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/cellList'">细胞列表</el-breadcrumb-item>
      <el-breadcrumb-item>理赔记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getList"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
        <el-button size="medium">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="dins_amt" label="保单金额" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="baby_contract"
          label="宝宝保单"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column label="受保人信息" :align="'center'">
          <template slot-scope="scope">
            <!-- <router-link :to="'/customerInfo'"> -->
            <div class="nickAndAvatar">
              <!-- <el-image :src="scope.row.avatar"></el-image> -->
              <p>
                <span>姓名：{{ scope.row.baby_name }}</span> <br />
                <span>性别：{{ scope.row.baby_gender }}</span>
              </p>
            </div>
            <!-- </router-link> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="deparent_contract"
          label="父母保单"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="status" label="理赔状态" :align="'center'">
          <template slot-scope="scope">
            <span v-if="scope.row.insurancehandle[0].status == 1">待处理</span>
            <span v-if="scope.row.insurancehandle[0].status == 2">处理中</span>
            <span v-if="scope.row.insurancehandle[0].status == 3">已完成</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dinsurance_company"
          label="保单公司"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          prop="deffective_date"
          label="保单时间"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="100"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.insurancehandle[0].status != 3"
              size="small"
              @click="showDiolog(scope.row)"
              >理赔处理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="理赔处理"
      :visible.sync="checkInfoDialogVisible"
      width="30%"
    >
      <el-form :model="form" ref="form" label-width="100px" :rules="rules">
        <el-form-item label="理赔人">
          <span>{{ this.name }}</span>
        </el-form-item>
        <el-form-item label="保单金额">
          <span style="color: rgb(234, 79, 79)">{{ this.money }}</span>
        </el-form-item>
        <el-form-item label="保单公司">
          <span>{{ this.company }}</span>
        </el-form-item>
        <el-form-item label="理赔状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="待处理" :value="1"></el-option>
            <el-option label="处理中" :value="2"></el-option>
            <el-option label="已处理" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="理赔时间">
          <el-date-picker
            v-model="form.handle_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="处理结果">
          <el-input
            type="textarea"
            resize="none"
            rows="6"
            v-model="form.result"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkInfoDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmForm('form')">确 定</el-button>
      </span>
    </el-dialog>
    <div class="operateBtn">
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
export default {
  name: "claimRecords",
  components: {
    checkInfoDialog,
  },
  data() {
    return {
      checkInfoDialogVisible: false,
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
      },
      name: "",
      company: "",
      money: "",
      form: {
        status: "",
        handle_time: "",
        result: "",
        uuid: this.$route.query.uuid,
      },
      rules: {
        status: { required: true, message: "请选择", trigger: "change" },
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    confirmForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.form,
            url: ` https://time.api.boyalife.cn/v1/cms/ItemInsurance/handle`,
          })
            .then((res) => {
              console.log(res);
              this.$message.success("操作成功！");
              this.checkInfoDialogVisible = false;
              this.getList();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
    showDiolog(row) {
      this.checkInfoDialogVisible = true;
      this.name = row.baby_name;
      this.money = row.dins_amt;
      this.company = row.dinsurance_company;
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemInsurance/handlelist/${this.$route.query.uuid}?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    closeDialog(v) {
      this.checkInfoDialogVisible = v;
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-form-item {
  margin-bottom: 5px;
}
</style>
