<template>
  <div class="addMorningPaper">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/morningPaperList'"
        >早报管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}早报</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="早报标题" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="早报文案" prop="content">
          <el-input
            type="textarea"
            resize="none"
            rows="5"
            v-model="basicInfoForm.content"
          ></el-input>
        </el-form-item>
        <el-form-item label="早报背景图" prop="image">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.image.length != 0"
              :src="basicInfoForm.image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner('banner')">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸为640*384像素，大小小于2M</p>
        </el-form-item>
        <el-form-item label="早报海报图" prop="poster">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest2"
          >
          <img
              v-if="basicInfoForm.poster != ''"
              :src="basicInfoForm.poster"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner('poster')">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸为1125*2165像素，大小小于2M</p>
        </el-form-item>
        <el-form-item label="早报期数" prop="publish_time">
          <el-date-picker
            v-model="basicInfoForm.publish_time"
            placeholder="选择期数"
            type="date"
            align="right"
            unlink-panels
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="早报中的资讯" prop="information_uuid">
          <span slot="label"><i style="color: red">*</i>早报中的资讯</span>
          <el-button class="mg-b-20" @click="showDialogTableVisible">
            + 添加资讯</el-button
          >
          <el-table
            ref="multipleTable"
            :data="basicInfoForm.information"
            border
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              width="55px"
              :align="'center'"
              type="index"
            >
            </el-table-column>
            <el-table-column
              label="内容标题"
              :align="'center'"
              prop="information_title"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" :align="'center'" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteInfo(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="投放人群" prop="group_uuid">
          <el-select
            multiple
            collapse-tags
            v-model="basicInfoForm.group_uuid"
            placeholder="请选择"
            @change="getChange"
          >
            <el-option
              v-for="(jtem, i) in userGroupList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="上架时间"
          :prop="basicInfoForm.shelf_type == 2 ? 'shelf_time' : ''"
        >
          <el-radio v-model="basicInfoForm.shelf_type" :label="1"
            >立即上架</el-radio
          >
          <el-radio v-model="basicInfoForm.shelf_type" :label="2"
            >定时上架</el-radio
          >
          <el-date-picker
            v-if="basicInfoForm.shelf_type == 2"
            v-model="basicInfoForm.shelf_time"
            type="datetime"
            placeholder="选择日期时间"
            @change="getTimes"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <!-- 选择资讯弹窗 -->
    <el-dialog title="选择内容" :visible.sync="dialogTableVisible">
      <el-input
        placeholder="请输入要搜索的内容"
        v-model="keyword"
        @input="getInformationList"
      >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
      <el-table
        :data="gridData.data"
        border
        ref="multipleTable"
        class="mg-t-10"
        :row-key="getRowKey"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          property="information_title"
          label="资讯标题"
        ></el-table-column>
      </el-table>
      <pagination
        :total="gridData.total"
        :per_page="gridData.per_page"
        :current_page="gridData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogTableVisible = false"
          >取消</el-button
        >
        <el-button size="mini" type="primary" @click="sure">确定</el-button>
      </span>
    </el-dialog>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="sumbitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />
      
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
import imageDialog from "../../../components/imageDialog.vue";

export default {
  name: "addMorningPaper",
  components: {
    imageDialog,
    pagination,
  },
  data() {
    return {
      keyword: "",
      pageType: "新增",
      dialogTableVisible: false,
      dialogVisible: false,
      imgType: "",
      selectType: '',
      basicInfoForm: {
        name: "",
        content: "",
        image: "",
        poster: "",
        shelf_type: 1,
        information_uuid: [],
        group_uuid: [],
        shelf_time: "",
        information: [],
        publish_time: "",
      },
      userGroupList: [],
      selectTable: [],
      gridData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      rules: {
        name: [{ required: true, message: "请输入早报名称", trigger: "blur" }],
        content: [
          { required: true, message: "请输入早报文案", trigger: "blur" },
        ],
        poster: [
          { required: true, message: "请选择早报海报图", trigger: "blur" },
        ],
        shelf_time: [
          { required: true, message: "请选择上架时间", trigger: "change" },
        ],
      },
      formData: '',
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getUserGroup();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/MorningPaper/${this.$route.query.uuid}`,
      })
        .then((res) => {
          this.basicInfoForm = res.data.result;
          this.basicInfoForm.group_uuid = this.basicInfoForm.group.map(
            (item) => item.uuid
          );
          this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);
          if (this.basicInfoForm.shelf_time == null) {
            this.basicInfoForm.shelf_time = "";
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
      // this.basicInfoForm.information.length > 0 ?this.basicInfoForm.information = JSON.parse(this.basicInfoForm.information) : []
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.poster =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getChange(v) {
      console.log(v);
      this.$forceUpdate();
    },
    showDialogTableVisible() {
      this.dialogTableVisible = true;
      this.getInformationList();
    },
    // 批量操作的id
    getRowKey(row) {
      return row.uuid;
    },
    //勾选
    handleSelectionChange(val) {
      this.selectTable = val;
    },
    deleteInfo(index) {
      this.basicInfoForm.information.splice(index, 1);
      this.basicInfoForm.information_uuid = this.basicInfoForm.information.map(
        (item) => item.uuid
      );
    },
    sure() {
      this.dialogTableVisible = false;
      this.basicInfoForm.information = this.selectTable;
      this.basicInfoForm.information_uuid = this.selectTable.map(
        (item) => item.uuid
      );
    },
    // 选择banner
    chooseBanner(type) {
      this.selectType = type
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择封面
    confirmImageDialog(item) {
      if (this.selectType == 'banner') {
        this.basicInfoForm.image = item[0].url;
      } else {
        this.basicInfoForm.poster = item[0].url;
      }
      this.dialogVisible = false;
    },
    // 选择时间
    getTimes(v) {
      this.basicInfoForm.shelf_time = v;
    },
    getInformationList() {
      this.getData(
        "get",
        {},
        `search_keyword=${this.keyword}&page_size=${this.gridData.per_page}&page_index=${this.gridData.current_page}`,
        "?",
        this.gridData,
        ""
      );
    },
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`,
      })
        .then((res) => {
          this.userGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getData(methods, data, queryStry, mark, tableData, msg) {
      this.axios({
        method: methods, //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data,
        url: ` https://time.api.boyalife.cn/v1/cms/Information${mark}${queryStry}`,
      })
        .then((res) => {
          if (msg !== "") {
            this.$message.success(msg);
          }
          if (methods === "delete" || methods === "post" || methods === "put") {
            this.getInformationList();
          }
          if (tableData !== null) {
            this.gridData = res.data.result;
            if (this.basicInfoForm.information.length > 0) {
              this.selectTable = this.basicInfoForm.information;
              let uuids = this.selectTable.map((i) => i.uuid);
              this.gridData.data.forEach((row) => {
                if (uuids.includes(row.uuid)) {
                  this.$nextTick(() => {
                    this.$refs.multipleTable.toggleRowSelection(row, true);
                  });
                }
              });
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    // 提交表单
    sumbitForm(form) {
      if (this.basicInfoForm.shelf_type == 1) {
        delete this.basicInfoForm.shelf_time;
      }
      if (
        this.basicInfoForm.shelf_type == 2 &&
        (this.basicInfoForm.shelf_time == "" ||
          this.basicInfoForm.shelf_time == null)
      ) {
        this.$message.warning("请选择时间");
        return false;
      }
      this.basicInfoForm.information_uuid = this.basicInfoForm.information.map(
        (item) => item.uuid
      );
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/MorningPaper/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/MorningPaper`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    handleSizeChange(v) {
      this.gridData.per_page = v;
      this.getInformationList();
    },
    handleCurrentChange(v) {
      this.gridData.current_page = v;
      this.getInformationList();
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addMorningPaper {
  margin-bottom: 80px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
