<template>
  <div class="laboratoryList">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>实验室</el-breadcrumb-item>
      <el-breadcrumb-item to="/laboratoryList">实验室管理</el-breadcrumb-item>
      <el-breadcrumb-item>配置列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="dataTable">
      <div class="tabs flex">
        <p class="on" v-if="$route.query.type == 1">样本处理间</p>
        <p class="on" v-else>样本储存间</p>
      </div>
      <div class="text-right mg-b-20">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="add('add')"
          >添加</el-button
        >
      </div>
      <el-table
        class="mg-t-20"
        :data="tableData.data"
        border
        style="width: 100%"
      >
        <el-table-column prop="open_time_begin" label="开始时间" align="center">
        </el-table-column>
        <el-table-column prop="open_time_end" label="结束时间" align="center">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          align="center"
          sortable
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="add('edit',scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleted(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
    <el-dialog
      :title="type == 'add' ? '新增' : '编辑'"
      :visible.sync="mapDialog"
      width="80%"
      @close="cancel"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        class="mg-t-20"
        :model="labForm"
        label-width="120px"
      >
        <el-form-item label="选择类型" prop="type">
          <el-radio-group v-model="labForm.type">
            <el-radio :label="1" v-if="$route.query.type == 1">样本处理间</el-radio>
            <el-radio :label="2" v-else>样本存储间</el-radio>
          </el-radio-group>
        </el-form-item>
         <el-form-item label="开发时间"  >
            <el-time-picker
                class="mg-b-10"
                v-model="timeRange"
                is-range
                format="H点mm分"
                value-format="H:mm"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                :picker-options="{
                  selectableRange: '00:00:00 - 23:59:59'
                }"
                @change="handleChange"
              ></el-time-picker>
            </el-form-item>
             <el-form-item label="实验室视频">
              <el-button class="avatar-uploader video" @click="chooseVideo">
                <video v-if="labForm.video_src !== ''" width="178px" controls>
                  <source :src="labForm.video_src" />
                </video>
                <i
                  class="el-icon-delete"
                  v-if="labForm.video_src !== ''"
                  @click.stop="labForm.video_src = ''"
                ></i>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-button>
              <p class="font-12 color-999">建议尺寸：1080P，500m以内</p>
            </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择视频"
      :visible.sync="showMatreial"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @insertVideoHandler="insertVideoHandler"
      />
    </el-dialog>
  </div>
</template>
<script>
import pagination from "../../components/pagination.vue";
import MaterialLibrary from '../../components/materialLibraryDialog'
export default {
  name: "laboratoryList",
  components: {
    MaterialLibrary,
    pagination
  },
  data() {
    return {
      type: '',
      showMatreial:false,
      labForm: {
        type: Number(this.$route.query.type),
        open_time_begin: '',
        open_time_end: '',
        video_src: '',
        id: '',
        lab_id: this.$route.query.id
      },
      timeRange: '',
      mapDialog: false,
      tabs: ['样本处理间', '样品储存间'],
      currentClass: 0,
      getUrlType: '',
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: "",
      },
      imgType: '',

    };
  },

  created() {
    this.getList(this.currentClass+1);
  },
  methods: {
    cancel () {
      this.mapDialog = false
      this.timeRange = ''
      for (let i in this.labForm) {
        this.labForm[i] = ''
      }
    },
    deleted(uuid) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/LabVideo/${uuid}`,
          })
            .then(() => {
              this.$message.success("删除成功！");
              this.getList(this.currentClass+1);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
    },
    submit (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {

        this.labForm.lab_id = this.$route.query.id
        this.labForm.type = this.$route.query.type

          if (this.type == 'edit') {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.labForm,
              url: ` https://time.api.boyalife.cn/v1/cms/LabVideo/${this.labForm.id}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.mapDialog = false
                this.getList(this.currentClass+1)
                for (let i in this.labForm) {
                  this.labForm[i] = ''
                }
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.labForm,
              url: ` https://time.api.boyalife.cn/v1/cms/LabVideo`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.mapDialog = false
                this.getList(this.currentClass+1)
                for (let i in this.labForm) {
                  this.labForm[i] = ''
                }
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      })
    },
    add (type, row) {
      console.log(row)
      this.mapDialog = true
      this.type = type
      if (type == 'edit') {
        this.labForm.type =Number(row.type)
        this.timeRange = [row.open_time_begin,row.open_time_end]
        this.labForm.open_time_begin = row.open_time_begin
        this.labForm.open_time_end = row.open_time_end
        this.labForm.video_src = row.video_src
        this.labForm.id = row.id

      }
    },
    insertVideoHandler(url) {
      this.labForm.video_src = url;
      this.showMatreial = false;
    },
    chooseVideo() {
      this.imgType = "chooseVideo";
      this.showMatreial = true;
    },
    addClass(index) {
      this.currentClass = index;
      this.getList(this.currentClass+1);
    },
    handleChange(value) {
      this.labForm.open_time_begin = value[0]
      this.labForm.open_time_end = value[1]

      // this.labForm.open_time =  Object.assign(this.open_time)
  },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          lab_id: this.$route.query.id
        },
        url: ` https://time.api.boyalife.cn/v1/cms/LabVideo?lab_id=${this.$route.query.id}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.laboratoryList {
  background-color: #f2f2f2;
}

.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}

.yes-color {
  color: #67c23a;
}

.no-color {
  color: #f56c6c;
}
</style>
