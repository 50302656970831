<template>
  <!-- 添加管理师 -->
  <div class="addManagerMaster">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customerList' }"
        >全部客户</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}管理师</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="头像" prop="avatar">
          <el-button class="avatar-uploader" @click="chooseBanner('avatar')">
            <img
              v-if="basicInfoForm.avatar !== ''"
              :src="basicInfoForm.avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <i
              v-if="basicInfoForm.avatar !== ''"
              class="el-icon-delete"
              @click.stop="basicInfoForm.avatar = ''"
            ></i>
          </el-button>
        </el-form-item>
        <el-form-item label="昵称" prop="nick_name">
          <el-input
            v-model="basicInfoForm.nick_name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input
            v-model="basicInfoForm.mobile"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="basicInfoForm.sex" placeholder="请选择">
            <el-option
              v-for="jtem in sexList"
              :key="jtem.uuid"
              :label="jtem.name"
              :value="jtem.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户标签" prop="label">
          <el-tag v-for="tag in tags" :key="tag.uuid" :type="tag.type">
            {{ tag.name }}
          </el-tag>
          <span
            style="color: cornflowerblue; cursor: pointer"
            @click="labelDialogVisible = true"
          >
            <span v-if="tags.length > 0">+ 编辑标签</span>
            <span v-else>+ 添加标签</span>
          </span>
        </el-form-item>
        <el-form-item label="活码" prop="loose_code">
          <el-button class="avatar-uploader" @click="chooseBanner('code')">
            <img
              v-if="basicInfoForm.loose_code !== ''"
              :src="basicInfoForm.loose_code"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <i
              v-if="basicInfoForm.loose_code !== ''"
              class="el-icon-delete"
              @click.stop="basicInfoForm.loose_code = ''"
            ></i>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @selectImg="selectImg" />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog> -->
    <el-dialog
      title="选择标签"
      :visible.sync="labelDialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <markLabel
        @closeDialog="closeDialog"
        :tags="tags"
        :type="'addCustomer'"
      />
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />
      
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import markLabel from "../../../components/markLabel.vue";
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addManagerMaster",
  components: {
    MaterialLibrary,
    markLabel,
    imageDialog
  },
  data() {
    return {
      tags: [],
      imgType: "",
      chooseType: "",
      pageType: "",
      dialogVisible: false,
      labelDialogVisible: false,
      sexList: [],
      props: {
        label: "name",
        value: "uuid",
        children: "label",
      },
      basicInfoForm: {
        avatar: "",
        nick_name: "",
        mobile: "",
        sex: 1,
        loose_code: "",
      },
      rules: {
        nick_name: { required: true, message: "请输入昵称", trigger: "blur" },
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
      },
    };
  },
  created() {
    this.getSex();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
      console.log(this.basicInfoForm);
      this.basicInfoForm.label = JSON.parse(this.basicInfoForm.label);
      this.tags = this.basicInfoForm.label;
      this.basicInfoForm.sex = Number(this.basicInfoForm.sex);
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    confirmImageDialog(item) {
      if (this.chooseType == 'avatar') {
        this.basicInfoForm.avatar = item[0].url;
      } else {
        this.basicInfoForm.loose_code = item[0].url;
      }
      this.dialogVisible = false;
    },
    closeImageDialog () {
      this.dialogVisible = false
    },
    getSex() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/UserProfile/ade2d2b666f75232466890e075e26616`,
      }).then((res) => {
        let params = res.data.result;
        this.sexList = params.option;
      });
    },
    closeDialog(v) {
      this.tags = JSON.parse(JSON.stringify(v));

      this.labelDialogVisible = false;
    },
    // 选择banner
    chooseBanner(type) {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
      if (type == "avatar") {
        this.chooseType = "avatar";
      } else {
        this.chooseType = "code";
      }
    },
    selectImg(item) {
      if (this.chooseType == "avatar") {
        this.basicInfoForm.avatar = item.url;
      } else {
        this.basicInfoForm.loose_code = item.url;
      }
      this.dialogVisible = false;
    },
    submit(form) {
      if (this.tags.length > 0) {
        this.basicInfoForm.label_uuid = this.tags.map((item) => {
          return item.uuid;
        });
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Manager/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Manager`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.addManagerMaster {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 400px;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
