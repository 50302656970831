<template>
  <!-- 添加客户 -->
  <div class="generalSet">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单设置</el-breadcrumb-item>
      <el-breadcrumb-item>通用设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form ref="form" :model="basicInfoForm" label-width="150px">
        <el-form-item label="库存扣减方式" prop="stock_reduce_type">
          <el-radio v-model="basicInfoForm.stock_reduce_type" :label="1"
            >拍下减库存</el-radio
          >
          <el-radio v-model="basicInfoForm.stock_reduce_type" :label="2"
            >付款减库存</el-radio
          >
        </el-form-item>
        <el-form-item label="订单取消时间" prop="auto_cancel_min">
          拍下订单<el-input
            v-model="basicInfoForm.auto_cancel_min"
            class="ipt"
            placeholder="请输入"
          ></el-input
          >分钟内未付款，自动取消订单
        </el-form-item>
        <el-form-item label="自动确认收货时间" prop="auto_confirm_day">
          发货后<el-input
            v-model="basicInfoForm.auto_confirm_day"
            class="ipt"
            placeholder="请输入"
          ></el-input
          >天，自动确认收货
        </el-form-item>
        <el-form-item label="售后关闭时间" prop="auto_finish_day">
          买家确认收货<el-input
            v-model="basicInfoForm.auto_finish_day"
            class="ipt"
            placeholder="请输入"
          ></el-input
          >天后，不支持售后
        </el-form-item>
        <el-form-item label="自动同意退货申请" prop="auto_confirm">
          <el-switch
            v-model="basicInfoForm.auto_confirm"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="最低消费" prop="is_number_min">
          <el-checkbox v-model="basicInfoForm.is_number_min"
            >每笔订单最低购买</el-checkbox
          >
          <el-input
            v-model="basicInfoForm.number_min"
            class="ipt"
            placeholder="请输入"
          >
            <i slot="suffix"> 件 </i>
          </el-input>
          <br />
          <el-checkbox v-model="basicInfoForm.is_money_min"
            >每笔订单最低购买</el-checkbox
          >
          <el-input
            v-model="basicInfoForm.money_min"
            class="ipt"
            placeholder="请输入"
          >
            <i slot="suffix"> 元 </i>
          </el-input>
        </el-form-item>
        <el-form-item label="订单整单包邮" prop="exempt_postage">
          <el-switch
            v-model="basicInfoForm.exempt_postage"
            :active-value="1"
            :inactive-value="2"
            active-color="#13ce66"
            inactive-color="gray"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "generalSet",
  data() {
    return {
      imageUrl: "",
      isShow: false,
      basicInfoForm: {
        stock_reduce_type: "",
        truenumber: "",
        auto_cancel_min: "",
        truestring: "",
        auto_confirm_day: "",
        truestring: "",
        auto_finish_day: "",
        truestring: "",
        auto_confirm: "",
        truestring: "",
        is_number_min: false,
        truenumber: "",
        number_min: "",
        truenumber: "",
        is_money_min: false,
        truenumber: "",
        money_min: "",
        truestring: "",
        exempt_postage: 2,
      },
    };
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/OrderSetting/1`,
    })
      .then((res) => {
        for (let i in res.data.result) {
          this.basicInfoForm[i] = res.data.result[i];
        }
        this.basicInfoForm.is_money_min =
          this.basicInfoForm.is_money_min == 1 ? true : false;
        this.basicInfoForm.is_number_min =
          this.basicInfoForm.is_number_min == 1 ? true : false;
        this.basicInfoForm.exempt_postage = Number(
          this.basicInfoForm.exempt_postage
        );
        this.basicInfoForm.stock_reduce_type = Number(
          this.basicInfoForm.stock_reduce_type
        );
      })
      .catch((err) => {
        this.$message.success(err);
      });
  },
  methods: {
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.basicInfoForm.is_money_min =
            this.basicInfoForm.is_money_min == true ? 1 : 2;
          this.basicInfoForm.is_number_min =
            this.basicInfoForm.is_number_min == true ? 1 : 2;
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/OrderSetting/1`,
          })
            .then((res) => {
              this.$message.success("保存成功！");
              this.basicInfoForm.is_money_min =
                this.basicInfoForm.is_money_min == 1 ? true : false;
              this.basicInfoForm.is_number_min =
                this.basicInfoForm.is_number_min == 1 ? true : false;
            })
            .catch((err) => {
              this.$message.success(err);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.generalSet {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 100%;
}
>>> .ipt {
  width: 200px;
  margin: 0 10px;
}
>>> .el-checkbox {
  margin-right: 0;
}
</style>
