<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>数据</el-breadcrumb-item>
      <el-breadcrumb-item>留存分析</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="boxLayout">
      <div class="right mg-b-20 flex space-right">
        <el-date-picker
          class="mg-l-20"
          v-model="date1"
          type="daterange"
          align="right"
          unlink-panels
          @change="getTimes"
          :clearable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy/MM/dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button class="mg-l-20" @click="retentionAnalysisExport"
          >导出</el-button
        >
      </div>
      <div class="flex space-between">
        <el-radio-group
          v-model="type"
          @input="getRetentionAnalysisData"
          style="margin-bottom: 30px"
        >
          <el-radio-button label="1">访问留存</el-radio-button>
          <el-radio-button label="2">成交留存</el-radio-button>
        </el-radio-group>
        <el-radio-group
          v-model="range"
          @input="getRetentionAnalysisData"
          style="margin-bottom: 30px"
        >
          <el-radio-button label="1">日</el-radio-button>
          <el-radio-button label="2">周</el-radio-button>
          <el-radio-button label="3">月</el-radio-button>
        </el-radio-group>
      </div>

      <el-table :data="retentionAnalysisData" border style="width: 100%">
        <el-table-column
          fixed
          prop="label"
          :label="type == 1 ? '访问时间' : ' 成交时间'"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="value[0].value"
          :label="type == 1 ? '新访客数' : '成交数'"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="value[1].value" label="1月留存" align="center">
        </el-table-column>

        <el-table-column prop="value[2].value" label="2月留存" align="center">
        </el-table-column>
        <el-table-column prop="value[3].value" label="3月留存" align="center">
        </el-table-column>
        <el-table-column prop="value[4].value" label="4月留存" align="center">
        </el-table-column>
        <el-table-column prop="value[5].value" label="5月留存" align="center">
        </el-table-column>
        <el-table-column prop="value[6].value" label="6月留存" align="center">
        </el-table-column>
        <el-table-column prop="value[7].value" label="7月留存" align="center">
        </el-table-column>
        <el-table-column prop="value[8].value" label="14月留存" align="center">
        </el-table-column>
        <el-table-column prop="value[9].value" label="30月留存" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
let currentTime = new Date();
let year = currentTime.getFullYear();
let month = currentTime.getMonth() + 1;
let day = currentTime.getDate();
let formattedDate = `${year}/${month
  .toString()
  .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

const start = new Date();
start.setTime(start.getTime() - 3600 * 1000 * 24);
let timed = start.toLocaleDateString();
export default {
  name: "retentionAnalysis",
  data() {
    return {
      customerGroupForm: {
        start_time: timed,
        end_time: formattedDate
      },
      type: 1,
      range: 1,
      date1: [timed, formattedDate],
      retentionAnalysisData: [],
      staticData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  created() {
    this.getRetentionAnalysisData();
  },
  methods: {
    changeTab(v) {
      this.type = v;
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.start_time = v[0] + " 00:00:00";
      this.customerGroupForm.end_time = v[1] + " 23:59:59";
      this.getIntegralData();
    },
    // 商品报表导出
    retentionAnalysisExport() {
      this.axios({
        method: "get", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/keepexport?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}&category_uuid=${this.customerGroupForm.category_uuid}&min_price=${this.customerGroupForm.min_price}&max_price=${this.customerGroupForm.max_price}&keywords=${this.customerGroupForm.keywords}&sort_key=${this.customerGroupForm.sort_key}&sort_value=${this.customerGroupForm.sort_value}`
      })
        .then(response => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "留存分析导出数据.xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 留存分析
    getRetentionAnalysisData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Data/keep?start_time=${this.customerGroupForm.start_time}&end_time=${this.customerGroupForm.end_time}&range=${this.range}&type=${this.type}`
      })
        .then(res => {
          this.retentionAnalysisData = res.data.result;
          this.staticData = [];
          this.retentionAnalysisData.map(item => {
            let obj = {};
            obj.date = item.label;
            obj.num = item.value[0].value;
            this.staticData.push(obj);
          });
          console.log(this.staticData);
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    }
  }
};
</script>
<style scoped>
.right {
  justify-content: end;
}
</style>
