<template>
  <div class="integralRecord">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item>成长值记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
      <el-form-item label="关键字">
          <el-input
            style="width: 200px; margin-left: 10px"
            placeholder="请输入姓名或者手机号"
            v-model="customerGroupForm.search_keyword"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="收支类型">
          <el-select
            v-model="customerGroupForm.status"
            placeholder="请选择"
          >
            <el-option label="收入" :value="1"></el-option>
            <el-option label="支出" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成长值类型">
          <el-select
            v-model="customerGroupForm.scene"
            placeholder="请选择"
          >
            <el-option label="手工增减" :value="1"></el-option>
            <el-option label="消费获得" :value="2"></el-option>
            <el-option label="完成任务" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期搜索">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            :clearable="false"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
       
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="check">查询</el-button>
        <el-button size="medium" @click="clear">清空</el-button>
        <el-button size="medium" @click="exportExcel">导出</el-button>
      </div>
    </div>
    <div class="dataTable">
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="remark" label="内容" :align="'center'"></el-table-column>
        <el-table-column label="用户信息" :align="'center'">
          <template slot-scope="scope">
            <router-link :to="'/customerInfo?uuid=' + scope.row.user_uuid">
              <div class="nickAndAvatar">
                <el-image v-if="scope.row.avatar != null" :src="scope.row.avatar"></el-image>
                <p>
                  <span v-if="scope.row.nick_name != null">{{ scope.row.nick_name }}</span> <br />
                  <span>{{ scope.row.mobile }}</span>
                </p>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="growth_value"
          label="成长值"
          :align="'center'"
        >
        </el-table-column>
        <el-table-column prop="scene" label="权益金类型" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="收支类型" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == '1'">收入</span>
            <span style="color: red" v-else>支出</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="下单时间" :align="'center'">
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "integralRecord",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        status: "",
        search_type: "",
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
        scene: ""
      },
      info: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getData();
  },
  methods: {
    check() {
      this.getList();
      this.getData();
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.check();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    // 导出
    exportExcel() {
      this.axios({
        method: "post", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGrowth/export?scene=${this.customerGroupForm.scene}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&status=${this.customerGroupForm.status}&search_type=${this.customerGroupForm.search_type}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `成长值记录 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGrowth?scene=${this.customerGroupForm.scene}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&status=${this.customerGroupForm.status}&search_type=${this.customerGroupForm.search_type}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGrowth/count?scene=${this.customerGroupForm.scene}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_keyword=${this.customerGroupForm.search_keyword}&status=${this.customerGroupForm.status}&search_type=${this.customerGroupForm.search_type}`,
      })
        .then((res) => {
          this.info = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
</style>
