<template>
  <div class="massMessage">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/noticeList'">通知公告</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageType }}通知</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="标题" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="收件人">
          <span slot="label"><span style="color: red">*</span>收件人</span>
          <el-tag
            :key="tag.uuid"
            v-for="tag in customerList"
            :disable-transitions="false"
          >
            {{ tag.nick_name }}
          </el-tag>
          <el-button
            class="mg-b-20"
            size="mini"
            @click="selectCustomerDialogVisible = true"
          >
            + 添加收件人</el-button
          >
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <span slot="label"><span style="color: red">*</span>内容</span>
          <wangEditorTool ref="editors" :content="basicInfoForm.content" />
        </el-form-item>
        <el-form-item label="添加附件">
          <el-button type="primary" plain @click="chooseFile">
            添加附件
          </el-button>
          <div>
            <div v-for="(item, index) in fileList" :key="index">
              <i class="el-icon-s-order"></i>
              <span class="mg-l-10 mg-r-50">{{ item.file_name }}</span>
              <i class="el-icon-close" @click="delItem(index)"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="发布" prop="shelf_type">
          <el-radio v-model="basicInfoForm.shelf_type" :label="1"
            >立即发布</el-radio
          >
          <el-radio v-model="basicInfoForm.shelf_type" :label="2"
            >定时发布</el-radio
          >
          <el-date-picker
            v-if="basicInfoForm.shelf_type == 2"
            v-model="basicInfoForm.shelf_time"
            type="datetime"
            placeholder="选择时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <!-- 选择收件人弹窗 -->
    <selectCustomer
      :customer="basicInfoForm.user"
      :dialogVisible="selectCustomerDialogVisible"
      @confirmCustomer="confirmCustomer"
      @cancel="closeSelectCustomer"
    />
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="sumbitForm('form')"
        >发布</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择附件"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @insertFile="insertFile" />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import wangEditorTool from "../../../components/wangEditorTool.vue";
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import selectCustomer from "../../../components/selectCustomer.vue";
export default {
  name: "massMessage",
  components: {
    wangEditorTool,
    MaterialLibrary,
    selectCustomer,
  },
  data() {
    return {
      imgType: "",
      selectCustomerDialogVisible: false,
      dialogTableVisible: false,
      dialogVisible: false,
      pageType: "发布",
      fileList: [],
      customerList: [],
      basicInfoForm: {
        name: "",
        shelf_time: "",
        content: "",
        shelf_type: 1,
        attachments: "",
        user_uuids: [],
      },
      dynamicTags: ["小明", "小白", "小黑"],
      gridData: [
        {
          name: "小花",
        },
        {
          name: "小龚",
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入通知名称", trigger: "blur" }],
        // content: [
        //   { required: true, message: '请输入早报文案', trigger: 'blur' },
        // ],
        // // image: [
        // //   { required: true, message: '请选择早报banner', trigger: 'blur' },
        // // ],
        // shelf_time: [
        //   { required: true, message: '请选择上架时间', trigger: 'blur' },
        // ],
      },
    };
  },
  created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      let params = this.$route.query;
      for (let i in params) {
        this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
      }
      this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);

      this.basicInfoForm.attachment = JSON.parse(this.basicInfoForm.attachment);
      this.basicInfoForm.user = JSON.parse(this.basicInfoForm.user);
      this.customerList = JSON.parse(JSON.stringify(this.basicInfoForm.user));
      this.fileList = JSON.parse(JSON.stringify(this.basicInfoForm.attachment));
      this.fileList.map((item) => {
        item.url = item.attachment;
      });
    } else {
      this.pageType = "发布";
    }
  },
  methods: {
    closeSelectCustomer(v) {
      this.selectCustomerDialogVisible = v;
    },
    confirmCustomer(v) {
      this.selectCustomerDialogVisible = false;
      this.customerList = v;
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    // 选择附件
    chooseFile() {
      this.imgType = "chooseFile";
      this.dialogVisible = true;
    },
    insertFile(row) {
      console.log(row);
      row.file_name = row.name;
      this.fileList.push(row);
      this.dialogVisible = false;
    },
    delItem(index) {
      this.fileList.splice(index, 1);
    },
    // 提交群发消息
    sumbitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.customerList.length > 0) {
            this.basicInfoForm.user_uuids = this.customerList.map(
              (item) => item.uuid
            );
          } else {
            this.$message.warning("请至少选择一位收件人");
            return false;
          }
          if (
            this.$refs.editors.html == "" ||
            this.$refs.editors.html == "<p><br></p>"
          ) {
            this.$message.warning("请输入内容");
            return false;
          } else {
            this.basicInfoForm.content = this.$refs.editors.html;
          }
          if (this.fileList.length > 0) {
            this.basicInfoForm.attachments = this.fileList.map(
              (item) => item.url
            );
          }
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Notice/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Notice`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.massMessage {
  margin-bottom: 80px;
}
.massMessage .el-tag {
  margin-right: 10px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-icon-close {
  cursor: pointer;
}
</style>
