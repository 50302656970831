<template>
  <!-- 客户详情 -->
  <div class="customerInfo">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customerList' }"
        >全部客户</el-breadcrumb-item
      >
      <el-breadcrumb-item>客户详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 基础信息 -->
    <div class="basicInfo" v-if="Object.keys(userInfo).length > 0">
      <h3 class="title-t">基础信息</h3>
      <div class="top mg-t-20">
        <div class="nick">
          <el-image
            :src="
              userInfo.avatar == null || userInfo.avatar == ''
                ? defaultImg
                : userInfo.avatar
            "
          ></el-image>
          <span>{{ userInfo.nick_name }}</span>
          <span class="level font-12" v-if="userInfo.level != null">{{
            userInfo.level.name
          }}</span>
          <el-button
            type="text"
            class="font-12"
            style="font-size: 12px; margin-left: 20px"
            @click="
              $router.push({
                name: 'addCustomer',
                query: { uuid: userInfo.uuid }
              })
            "
            >编辑</el-button
          >
        </div>
        <div class="remark">
          <div class="flex align-center" style="width: 100%">
            <p>备注：</p>
            <el-tooltip
              class="item"
              effect="dark"
              v-if="remark"
              :content="remark"
              placement="top"
            >
              <p class="p">{{ remark }}</p>
            </el-tooltip>
            <el-button
              type="text"
              class="font-14"
              @click="addRemark"
              v-if="!remark"
              >添加备注</el-button
            >

            <el-button type="text" class="font-14" @click="addRemark" v-else
              >编辑</el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom">
        <div>
          <p>会员ID： 0001</p>
          <p>external_userid： 9999001</p>
          <p>unionID：999999112</p>
          <p>注销状态：{{ userInfo.is_cancel == 1 ? "是" : "否" }}</p>
          <p>
            角色身份：
            <span v-if="userInfo.user_role == 1">游客</span>
            <span v-else-if="userInfo.user_role == 2">会员</span>
            <span v-else>推广员</span>
          </p>
        </div>
        <div>
          <p>手机号码： {{ userInfo.mobile == null ? "" : userInfo.mobile }}</p>
          <p>性别： {{ userInfo.sex }}</p>
          <p>生日： {{ userInfo.birthday == null ? "" : userInfo.birthday }}</p>
          <p v-if="userInfo.interest != '' && userInfo.interest != null">
            兴趣爱好：{{ userInfo.interest }}
          </p>
          <p
            v-if="userInfo.manager_name != '' && userInfo.manager_name != null"
          >
            所属销售：{{ userInfo.manager_name }}
          </p>
        </div>
        <div>
          <p>职业： {{ userInfo.job == null ? "" : userInfo.job }}</p>
          <p>
            地区：
            {{ userInfo.province == null ? "" : userInfo.province.area_name
            }}{{ userInfo.city == null ? "" : userInfo.city.area_name
            }}{{ userInfo.area == null ? "" : userInfo.area.area_name }}
          </p>
          <p
            v-if="
              userInfo.confinement_date != null &&
                userInfo.confinement_date.search('0') != 0
            "
          >
            预产期： {{ userInfo.confinement_date }}
          </p>
          <p
            v-if="
              userInfo.antenatal_hospital != '' &&
                userInfo.antenatal_hospital != null
            "
          >
            预产医院： {{ userInfo.antenatal_hospital }}
          </p>
        </div>
        <div>
          <p>注册时间： {{ userInfo.create_time }}</p>
          <p>客户来源： 小程序注册</p>
          <p>最近登录时间：{{ userInfo.last_login_time }}</p>
          <p>最近购买时间：{{ userInfo.consume_time }}</p>
        </div>
      </div>
    </div>
    <!-- 客户标签 -->
    <div class="customerLevel mg-t-20">
      <h3 class="title-t">客户标签</h3>
      <div class="tag mg-t-20 mg-l-20">
        <el-tag type="success" v-for="(item, index) in labelList" :key="index">
          {{ item.name }}
        </el-tag>
        <el-button class="mg-l-10" type="text" size="mini" @click="markLabels"
          >添加标签</el-button
        >
      </div>
    </div>
    <!-- 客户资产 -->
    <div class="clientAssets mg-t-20" v-if="Object.keys(assets).length > 0">
      <h3 class="title-t">客户资产</h3>
      <div class="flex">
        <div class="box">
          <div class="name color-666 mg-b-20">客户等级</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.user_level != null ? assets.user_level.name : "暂无" }}
          </div>
          <!--<div class="btn">
            <el-button type="text" @click="check(1)" v-if=" assets.user_level != null">查看权益</el-button>
            <el-button type="text" @click="showLevelDialog">设置等级</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">可用life币</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.user_life }}
          </div>
          <!--<div class="btn">
            <el-button type="text" @click="check(3)">life币记录</el-button>
            <el-button type="text" @click="check(4)">赠送life币</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">成长值</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.user_growth }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(5)">成长值记录</el-button>
            <el-button type="text" @click="check(6)">赠送成长值</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">储值卡余额</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.balance_money }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="$alert('跳转页面未做')"
              >查看明细</el-button
            >
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">储值卡本金</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.total_money }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="$alert('跳转页面未做')"
              >查看明细</el-button
            >
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">储值卡赠送</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.give_money }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="$alert('跳转页面未做')"
              >查看明细</el-button
            >
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">待使用优惠券</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.coupon_dsy }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(7)">查看</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">已使用优惠券</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.coupon_ysy }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(7)">查看</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">已过期优惠券</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.coupon_ygq }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(7)">查看</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">已作废优惠券</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.coupon_yzf }}
          </div>
          <!--  <div class="btn">
            <el-button type="text" @click="check(7)">查看</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">待还欠款</div>
          <div class="num font-22 color-333 mg-b-10">{{ assets.dh }}</div>
          <!--  <div class="btn">
            <el-button type="text" @click="$alert('公共组件待完成')"
              >缴费记录</el-button
            >
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">已还欠款</div>
          <div class="num font-22 color-333 mg-b-10">{{ assets.yh }}</div>
          <!--<div class="btn">
            <el-button type="text" @click="$alert('公共组件待完成')"
              >缴费记录</el-button
            >
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">可用积分</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.user_score }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(8)">积分记录</el-button>
            <el-button type="text" @click="check(9)">赠送积分</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">总积分</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.user_total_score }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(8)">积分记录</el-button>
          </div>-->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">冻结中积分</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.user_lock_score }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(8)">积分记录</el-button>
          </div> -->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">已过期积分</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.expire_score }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(8)">积分记录</el-button>
          </div> -->
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">已使用积分</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ assets.used_score }}
          </div>
          <!-- <div class="btn">
            <el-button type="text" @click="check(8)">积分记录</el-button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 荣誉墙 -->
    <div class="honorWall mg-t-20" v-if="medalRecord.length > 0">
      <h3 class="title-t">荣誉墙</h3>
      <el-row :gutter="20">
        <el-col :span="3" v-for="item in medalRecord" :key="item">
          <div class="grid-content text-center">
            <el-image class="pic" :src="item.medal.cover_image"></el-image>
            <p>{{ item.medal.name }}</p>
            <p>{{ item.medal.create_time }}</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 交易统计 -->
    <div
      class="transactionStatistics mg-t-20"
      v-if="Object.keys(orderData).length > 0"
    >
      <h3 class="title-t">交易统计</h3>
      <div class="flex">
        <div class="box">
          <div class="name color-666 mg-b-20">项目订单数</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.item.order_num }}
          </div>
          <div class="btn">
            <el-button type="text" @click="$router.push('/projectOrder?uuid='+$route.query.uuid)">查看订单记录</el-button>
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">项目消费金额（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.item.consume_price }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">项目客单价（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.item.per_price }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">项目退款订单数</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.item.refund_num }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">项目退款金额（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.item.refund_price }}
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="box">
          <div class="name color-666 mg-b-20">商品订单数</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.goods.order_num }}
          </div>
          <div class="btn">
            <el-button type="text" @click="$router.push('/commodityOrder?uuid='+$route.query.uuid)">查看订单记录</el-button>
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">商品消费金额（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.goods.consume_price }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">商品客单价（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.goods.per_price }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">商品退款订单数</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.goods.refund_num }}
          </div>
        </div>
        <div class="box">
          <div class="name color-666 mg-b-20">商品退款金额（元）</div>
          <div class="num font-22 color-333 mg-b-10">
            {{ orderData.goods.refund_price }}
          </div>
        </div>
      </div>
    </div>
    <!-- 收货地址 -->
    <div class="address boxLayout mg-t-20" v-if="addressList.data.length > 0">
      <h3 class="title-t">
        收货地址 <el-button size="mini" class="export">导出数据</el-button>
      </h3>
      <el-row :gutter="20">
        <el-col :span="8" v-for="item in addressList.data" :key="item.uuid">
          <div class="grid-content">
            <p v-if="item.is_default == 1">默认地址</p>
            <p><span>收货人:</span> {{ item.name }}</p>
            <p><span>联系电话:</span>{{ item.mobile }}</p>
            <p><span>收货地址: </span>{{ item.address }}</p>
          </div>
        </el-col>
      </el-row>
      <pagination
        :total="addressList.total"
        :per_page="addressList.per_page"
        :current_page="addressList.current_page"
        :pageSize="[3]"
        @handleCurrentChange="handleCurrentChangeAddress"
        @handleSizeChange="handleSizeChangeAddress"
      />
    </div>
    <!-- 邀请记录 -->
    <div class="transactionStatistics mg-t-20">
      <h3 class="title-t">邀请记录</h3>
      <div class="text-right">
        <el-button class="">导出</el-button>
      </div>
      <el-table :data="shareRecordList.data" style="width: 100%">
        <el-table-column prop="invitee.uuid" label="用户ID" align="center">
        </el-table-column>
        <el-table-column
          prop="invitee.nick_name"
          label="用户名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="invitee.mobile" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="invitee.sex" label="性别" align="center">
        </el-table-column>
        <el-table-column
          prop="invitee.create_time"
          label="邀请时间"
          align="center"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="shareRecordList.total"
        :per_page="shareRecordList.per_page"
        :current_page="shareRecordList.current_page"
        @handleCurrentChange="handleCurrentChangeAddress"
        @handleSizeChange="handleSizeChangeAddress"
      />
    </div>
    <div class="operateBtn">
      <el-button
        size="mini"
        v-if="userInfo.is_freezed == 2 || userInfo.is_freezed == 0"
        type="danger"
        @click="blockCustomer"
        >冻结客户</el-button
      >
      <el-button
        size="mini"
        v-if="userInfo.is_freezed == 1"
        type="success"
        @click="blockCustomer"
        >解冻</el-button
      >

      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <BigImg
      :visible="photoVisible"
      :url="bigImgUrl"
      @closeClick="
        () => {
          photoVisible = false;
        }
      "
    ></BigImg>
    <checkInfoDialog
      :cancelBtnText="cancelBtnText"
      :visible="checkInfoDialogVisible"
      :title="title"
      :showCancelBtn="showCancelBtn"
      :showConfirmBtn="showConfirmBtn"
      @closeDialog="closeDialog"
    >
      <el-form :model="dialogForm" label-width="100px">
        <!-- 查看权益 -->
        <div class="dialogContent" v-if="dialogNumber === 1">
          <div class="flex">
            <p class="mg-r-20 color-666">客户等级</p>
            <p>L1</p>
          </div>
          <div class="flex mg-t-20">
            <p class="mg-r-20 color-666">等级权益</p>
            <p>包邮；2%积分；9.9折</p>
          </div>
        </div>
        <!-- 设置等级 -->
        <div class="setLevel" v-if="dialogNumber === 2">
          <el-form-item label="客户等级">
            <el-select v-model="dialogForm.setLevel" placeholder="请选择">
              <el-option label="L1" value="shanghai"></el-option>
              <el-option label="L2" value="beijing"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- life币记录 -->
        <div class="pointRecord" v-if="dialogNumber === 3">
          <el-table :data="lifeCornTableData" border style="width: 100%">
            <el-table-column prop="number" label="life币数量" align="center">
            </el-table-column>
            <el-table-column prop="reason" label="操作原因" align="center">
            </el-table-column>
            <el-table-column prop="people" label="操作人" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="操作时间"
              align="center"
              width="160"
            >
            </el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 赠送life -->
        <div class="giveCoin" v-if="dialogNumber === 4">
          <el-form-item label="life币数量">
            <el-input v-model="dialogForm.num" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="dialogForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 成长值记录 -->
        <div class="pointRecord" v-if="dialogNumber === 5">
          <el-table :data="lifeCornTableData" border style="width: 100%">
            <el-table-column prop="number" label="成长值数量" align="center">
            </el-table-column>
            <el-table-column prop="reason" label="操作原因" align="center">
            </el-table-column>
            <el-table-column prop="people" label="操作人" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="操作时间"
              align="center"
              width="160"
            >
            </el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 赠送成长值 -->
        <div class="giveCoin" v-if="dialogNumber === 6">
          <el-form-item label="成长值数量">
            <el-input
              v-model="dialogForm.growthValue"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="dialogForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 优惠券记录 -->
        <div class="givecon" v-if="dialogNumber === 7">
          <el-table :data="gridData" border>
            <el-table-column
              property="name"
              label="优惠券名称"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              property="status"
              label="状态"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <span style="color: #2ed477">{{ scope.row.status }}</span>
              </template>
            </el-table-column>
            <el-table-column
              property="content"
              label="优惠券内容"
              align="center"
            >
              <template slot-scope="scope">
                <p>￥{{ scope.row.money }}</p>
                <p>满{{ scope.row.bigMoney }}元可用</p>
              </template>
            </el-table-column>
            <el-table-column
              property="range"
              label="适用范围"
              align="center"
            ></el-table-column>
            <el-table-column
              property="date"
              label="有效期"
              align="center"
            ></el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 积分记录 -->
        <div class="pointRecord" v-if="dialogNumber === 8">
          <el-table :data="lifeCornTableData" border style="width: 100%">
            <el-table-column prop="number" label="积分数量" align="center">
            </el-table-column>
            <el-table-column prop="reason" label="操作原因" align="center">
            </el-table-column>
            <el-table-column prop="people" label="操作人" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
            </el-table-column>
            <el-table-column
              prop="date"
              label="操作时间"
              align="center"
              width="160"
            >
            </el-table-column>
          </el-table>
          <div class="text-center mg-t-20">
            <!-- @size-change="handleSizeChange" -->
            <!-- @current-change="handleCurrentChange" -->
            <el-pagination
              :current-page="1"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="400"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 赠送积分 -->
        <div class="giveCoin" v-if="dialogNumber === 9">
          <el-form-item label="积分币数量">
            <el-input v-model="dialogForm.num" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="dialogForm.remark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </checkInfoDialog>
    <el-dialog
      title="选择标签"
      :visible.sync="dialogTableVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <markLabel :uuid="uuid" :tags="tags" @closeDialog="closeLabelDialog" />
    </el-dialog>

    <!-- 设置等级 -->
    <el-dialog
      title="设置等级"
      :visible.sync="dialogLevelVisible"
      inline
      width="30%"
    >
      <el-form :model="customerForm" class="mg-t-20">
        <el-form-item label="客户等级">
          <el-select v-model="customerForm.level" placeholder="请选择">
            <el-option
              v-for="(jtem, i) in levelList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogLevelVisible = false">取 消</el-button>
        <el-button type="primary" @click="setLevel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BigImg from "@/components/previwerImg.vue";
import markLabel from "../../../components/markLabel.vue";
import checkInfoDialog from "../../../components/checkInfoDialog.vue";
import pagination from "../../../components/pagination.vue";
export default {
  name: "customerInfo",
  components: {
    BigImg,
    checkInfoDialog,
    markLabel,
    pagination
  },
  data() {
    return {
      defaultImg:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201512%2F06%2F20151206205342_rsM3R.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1691462488&t=369088ef6d16d0369431142f03e254ab",
      dialogLevelVisible: false,
      levelList: [],
      customerForm: {
        level: ""
      },
      remark: "",
      orderData: {},
      dialogTableVisible: false,
      showCancelBtn: true,
      cancelBtnText: "关闭",
      showConfirmBtn: false,
      tags: [],
      uuid: "",
      title: "",
      dialogForm: {},
      checkInfoDialogVisible: false,
      dialogNumber: null,
      photoVisible: false,
      bigImgUrl: "",
      currentPage4: 4,
      labelList: [],
      userInfo: {},
      assets: {},
      medalRecord: [],
      addressList: {
        data: [],
        per_page: 3,
        current_page: 1,
        total: 0
      },
      shareRecordList: {
        data: [],
        per_page: 3,
        current_page: 1,
        total: 0
      },
      tableData: [],
      lifeCornTableData: [
        {
          date: "2016-05-02 12:00:00",
          number: "22",
          reason: "购买商品",
          people: "admin",
          remark: "-"
        }
      ],
      gridData: [
        {
          name: "优惠券",
          status: "待使用",
          money: "2",
          bigMoney: "20",
          range: "全店可用",
          date: "永久有效"
        },
        {
          name: "优惠券",
          status: "已使用",
          money: "2",
          bigMoney: "20",
          range: "全店可用",
          date: "永久有效"
        },
        {
          name: "优惠券",
          status: "已过期",
          money: "2",
          bigMoney: "20",
          range: "全店可用",
          date: "永久有效"
        },
        {
          name: "优惠券",
          status: "已作废",
          money: "2",
          bigMoney: "20",
          range: "全店可用",
          date: "永久有效"
        }
      ]
    };
  },
  created() {
    this.getInfo();
    this.getAddress();
    this.getShareRecord();
    this.getAsset();
    this.getMedalRecord();
    this.getOrderData();
  },
  methods: {
    // 获取等级
    getLevel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLevelSelect`
      })
        .then(res => {
          this.levelList = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    showLevelDialog() {
      this.dialogLevelVisible = true;
      this.getLevel();
    },
    setLevel() {
      if (this.level == "") {
        this.$message.warning("请选择等级！");
        return false;
      }
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data: {
          level: this.customerForm.level,
          uuids: [this.$route.query.uuid]
        },
        url: ` https://time.api.boyalife.cn/v1/cms/User/level_branch`
      })
        .then(res => {
          this.$message.success("操作成功！");
          this.dialogLevelVisible = false;
          this.getInfo();
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
      this.dialogLevelVisible = true;
    },
    // 打标签
    markLabels() {
      this.dialogTableVisible = true;
      this.uuid = this.$route.query.uuid;
      this.tags = this.labelList;
    },
    // 关闭标签弹窗
    closeLabelDialog(v) {
      this.dialogTableVisible = false;
      this.labelList = v;
    },
    showBigImage(url) {
      //点击图片函数，点击后，把photoVisible设置成true
      if (url != "") {
        this.photoVisible = true;
        this.bigImgUrl = url;
      }
    },
    //  获取订单统计
    getOrderData() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/User/orderdata?user_uuid=${this.$route.query.uuid}`
      })
        .then(res => {
          this.orderData = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    //  获取客户资产
    getAsset() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data: {
          user_uuid: this.$route.query.uuid
        },
        url: ` https://time.api.boyalife.cn/v1/cms/user/getAsset`
      })
        .then(res => {
          this.assets = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    //  获取荣誉墙
    getMedalRecord() {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        data: {
          user_uuid: this.$route.query.uuid
        },
        url: ` https://time.api.boyalife.cn/v1/cms/user/medalRecord`
      })
        .then(res => {
          this.medalRecord = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    //   https://time.api.boyalife.cn/v1/cms/User/{$uuid}
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/User/${this.$route.query.uuid}`
      })
        .then(res => {
          this.userInfo = res.data.result;
          this.labelList = JSON.parse(JSON.stringify(this.userInfo.label));
          this.remark = this.userInfo.remark;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取收货地址
    getAddress() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },

        url: ` https://time.api.boyalife.cn/v1/cms/UserDetailAddress?user_uuid=${this.$route.query.uuid}&page_index=${this.addressList.current_page}&page_size=${this.addressList.per_page}`
      })
        .then(res => {
          this.addressList = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 获取邀请记录
    getShareRecord() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserDetailShare?user_uuid=${this.$route.query.uuid}`
      })
        .then(res => {
          this.shareRecordList = res.data.result;
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    },
    //  添加备注
    addRemark() {
      const msg = this.remark !== "" ? "编辑" : "添加";
      this.$prompt("备注信息", msg + "备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputValue: this.remark,
        inputPlaceholder: "请输入",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "请输入备注"
      })
        .then(({ value }) => {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`
            },
            data: {
              remark: value
            },
            url: ` https://time.api.boyalife.cn/v1/cms/UserRemark/${this.$route.query.uuid}`
          })
            .then(res => {
              this.$message.success("操作成功！");
              this.remark = value;
            })
            .catch(err => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入"
          });
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleSizeChangeAddress(val) {
      this.addressList.per_page = val;
    },
    handleCurrentChangeAddress(val) {
      this.addressList.current_page = val;
    },
    // 查看
    check(n) {
      this.checkInfoDialogVisible = true;
      this.dialogNumber = n;
      switch (n) {
        case 1:
          this.title = "客户权益";
          this.cancelBtnText = "关闭";
          break;
        case 2:
          this.title = "设置客户等级";
          this.cancelBtnText = "取消";
          this.showConfirmBtn = true;
          break;
        case 3:
          this.title = "life币记录";
          this.cancelBtnText = "关闭";
          break;
        case 4:
          this.title = "赠送life币";
          this.cancelBtnText = "关闭";
          this.showConfirmBtn = true;
          break;
        case 5:
          this.title = "成长值记录";
          this.cancelBtnText = "关闭";
          break;
        case 6:
          this.title = "赠送成长值";
          this.cancelBtnText = "关闭";
          this.showConfirmBtn = true;
          break;
        case 7:
          this.title = "优惠券";
          this.cancelBtnText = "关闭";
          break;
        case 8:
          this.title = "积分记录";
          this.cancelBtnText = "关闭";
          break;
        case 9:
          this.title = "赠送积分";
          this.cancelBtnText = "关闭";
          this.showConfirmBtn = true;
          break;
      }
    },
    closeDialog(v) {
      this.checkInfoDialogVisible = v;
      this.showConfirmBtn = v;
    },
    pass() {
      this.$confirm("确定审核通过该用户的注销审核吗?", "审核通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    refuse() {
      this.$prompt("确定不通过该用户的注销审核吗？", "审核不通过确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        inputPlaceholder: "请输入不通过理由",
        inputType: "textarea",
        inputPattern: /[a-zA-Z0-9_\u4e00-\u9fa5]+/,
        inputErrorMessage: "理由不能为空"
      })
        .then(({ value }) => {
          this.$message({
            type: "success",
            message: "你拒绝通过的理由是: " + value
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入"
          });
        });
    },
    blockCustomer() {
      let status = "";
      let html = "";
      if (this.userInfo.is_freezed == 1) {
        html = "是否解冻客户，解冻后客户可以购买商品。";
        status = 2;
      } else {
        status = 1;
        html = "冻结后客户将无法购买商品，确定要冻结此客户吗?";
      }
      this.$confirm(html, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`
            },
            data: {
              status: status
            },
            url: ` https://time.api.boyalife.cn/v1/cms/User/freezed/${this.$route.query.uuid}`
          })
            .then(res => {
              this.$message.success("操作成功！");
              this.userInfo.is_freezed = status;
            })
            .catch(err => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消冻结"
          });
        });
      //  https://time.api.boyalife.cn/v1/cms/User/freezed_branch
    }
  }
};
</script>
<style scoped>
.customerInfo {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo,
.realNameAuthentication,
.customerLevel,
.clientAssets,
.honorWall,
.transactionStatistics {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.basicInfo .remark .p {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top {
  display: flex;
  justify-content: space-between;
}
.top .el-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.top .nick {
  display: flex;
  align-items: center;
}
.top > div {
  width: calc(25% - 13px);
}
.remark {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.remark div .el-button {
  padding: 0;
}
.top .nick .level {
  border: 1px solid #ffc71c;
  color: #ffc71c;
  display: inline-block;
  padding: 2px 5px;
  margin: 0 10px;
  border-radius: 4px;
}
.bottom {
  margin-left: 60px;
  display: flex;
}
.bottom div {
  width: 25%;
  color: #666;
}

/* 实名审核 */
.realNameAuthentication .box {
  margin-left: 40px;
  margin-top: 20px;
}
.realNameAuthentication .box p {
  margin-top: 5px;
}
.realNameAuthentication p.img span {
  width: 80px;
  height: 80px;
  display: inline-block;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  position: relative;
}
.realNameAuthentication p.img span i {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.realNameAuthentication p.img span .el-image__inner {
  max-width: 100%;
}
/* 客户资产 */
.clientAssets .flex,
.transactionStatistics .flex {
  flex-flow: wrap;
  margin-left: 40px;
}
.clientAssets .flex .box,
.transactionStatistics .flex .box {
  padding-top: 30px;
  width: 16.5%;
}

.transactionStatistics .el-table {
  margin-top: 20px;
}
.pagination {
  margin-left: 40px;
  margin-top: 20px;
  text-align: center;
}
>>> .el-dialog__body {
  border-top: 1px solid #f2f2f2;
}
>>> .el-dialog {
  width: 800px !important;
}
.giveCoin {
  width: 500px;
}
.honorWall .pic {
  width: 133px;
  height: 133px;
  border-radius: 10px;
}
.honorWall .grid-content {
  padding: 20px 0;
}
.address p {
  line-height: 26px;
}
.address h3 {
  position: relative;
}
.address .export {
  position: absolute;
  right: 0;
  top: 0;
}
.el-tag {
  margin-bottom: 5px;
  margin-right: 5px;
}
.address >>> .el-select {
  display: none !important;
}
</style>
