<template>
  <div class="readList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>通知公告</el-breadcrumb-item>
      <el-breadcrumb-item>通知列表</el-breadcrumb-item>
      <el-breadcrumb-item>已读名单</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="user.nick_name" label="用户" :align="'center'">
        </el-table-column>
        <el-table-column prop="create_time" label="阅读时间" :align="'center'">
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "readList",
  components: {
    pagination,
  },
  data() {
    return {
      customerGroupForm: {
        status: 1,
      },
      tabs: ["已读名单", "未读名单"],
      currentClass: 0,
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    addClass(index) {
      this.currentClass = index;
      this.customerGroupForm.status = index + 1;
      this.getList();
    },
    // 列表
    getList(status) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/NoticeReadList?notice_uuid=${this.$route.query.uuid}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&status=${this.customerGroupForm.status}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList(this.currentClass + 1);
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList(this.currentClass + 1);
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
>>> .el-input-group {
  width: 400px;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.readList .el-table .product .el-image {
  width: 60px;
  height: auto;
  border-radius: 0 !important;
}
.dataTable {
  padding-top: 80px;
}
.dataTable .tabs {
  top: 20px;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
</style>
