<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>细胞</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/cellList'">细胞列表</el-breadcrumb-item>
      <el-breadcrumb-item>合同档案</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t">合同档案</h3>
      <div class="arrow">
        <span @click="changePdfPage(0)" class="turn">上一页</span>
        <p>{{ pdfPage }} / {{ pageCount }}</p>
        <span @click="changePdfPage(1)" class="turn">下一页</span>
      </div>
      <div class="pdf">
        <pdf
          ref="pdf"
          :src="pdfUrl"
          :page="pdfPage"
          @num-pages="pageCount = $event"
          @page-loaded="pdfPage = $event"
          @loaded="loadPdfHandler"
        >
        </pdf>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";

export default {
  name: "contractArchives",
  components: {
    pdf,
  },
  data() {
    return {
      pdfPage: null,
      pageCount: "",
      pdfUrl: "",
    };
  },
  created() {
    this.getInfo();
  },

  methods: {
    getTotal(url) {
      this.pdfUrl = pdf.createLoadingTask(url);
      this.pdfUrl.promise.then((pdf) => {
        this.pageCount = pdf._pdfInfo.numPages;
      });
    },
    // viewPdf (item) {
    //   this.pdfPreviewDialog =true
    //   this.getTotal(item.url)
    //   this.pdfTitle = item.name
    // },
    changePdfPage(val) {
      if (val === 0 && this.pdfPage > 1) {
        this.pdfPage--;
      }
      if (val === 1 && this.pdfPage < this.pageCount) {
        this.pdfPage++;
      }
    },
    loadPdfHandler(e) {
      this.pdfPage = 1; // 加载的时候先加载第一页
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ItemOrder/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let url = res.data.result;
          console.log(url);

          this.getTotal(url.contract.all_pdf_url);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
  },
};
</script>
<style scoped>
.arrow {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.arrow span {
  padding: 10px 20px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  display: inline-block;
  margin: 20px;
  cursor: pointer;
}
</style>
