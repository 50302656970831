<template>
  <div class="menuSet">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="boxLayout mg-t-20">
      <div class="flex space-between">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item>
            <el-input
              placeholder="请输入要搜索的内容"
              @input="getMenu"
              v-model="customerGroupForm.search_keyword"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="text-right mg-b-20">
          <el-button type="primary" @click="dialogVisible = true"
            >导出菜单</el-button
          >
          <el-button type="primary" @click="add('levelOne')">新建</el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type="index" label="URI" :align="'center'" width="180">
        </el-table-column>
        <el-table-column prop="menu_name" label="菜单名" width="180">
        </el-table-column>
        <el-table-column
          prop="type"
          label="菜单等级"
          :align="'center'"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="create_time" :align="'center'" label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="400"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.fid == 0"
              @click="add('levelTwo', scope.row)"
              >添加下级分类</el-button
            >
            <template>
              <el-button
                type="text"
                v-if="scope.row.fid != 0"
                @click="edit('levelTwo', scope.row)"
                >编辑</el-button
              >
              <el-button type="text" v-else @click="edit('levelOne', scope.row)"
                >编辑</el-button
              >
            </template>
            <el-button type="text" @click="deleted(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        row-key="id"
        border
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
          type="index"
          label="URI"
          sortable
          :align="'center'"
          width="180">
        </el-table-column>
        <el-table-column
          prop="menu_name"
          label="菜单名"
          sortable
          width="180">
        </el-table-column>
        <el-table-column
          prop="type"
          label="菜单等级"
          sortable
          :align="'center'"
          width="180">
        </el-table-column>
        <el-table-column
          prop="create_time"
          sortable
          :align="'center'"
          label="创建时间">
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          fixed="right"
          width="400"
          >
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.fid == 0" @click="addLowerlevel(scope.row)">添加下级分类</el-button>
            <template>
              <el-button type="text" v-if="scope.row.fid != 0" @click="showDialog(scope.row)">编辑</el-button>
              <el-button type="text" v-else @click="toEdit(scope.row)">编辑</el-button>
            </template>
            <el-button type="text" @click="deleted(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table> -->
      <el-dialog
        title="类别"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form
          :model="form"
          label-width="80px"
          class="mg-t-20"
          ref="form"
          :rules="formRules"
        >
          <el-form-item label="菜单名称" prop="menu_name">
            <el-input v-model="form.menu_name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="菜单URL" prop="menu_url">
            <el-input v-model="form.menu_url" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="上级类别" v-if="addType == 'levelTwo'">
            <el-select v-model="form.fid" placeholder="请选择">
              <el-option
                placeholder="请选择"
                v-for="item in tableData"
                :key="item.id"
                :label="item.menu_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submit('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "menuSet",
  data() {
    return {
      hasChildren: true,
      title: "新增菜单",
      editType: "",
      addType: "",
      type: "",
      idl: "",
      dialogFormVisible: false,
      customerGroupForm: {
        search_keyword: "",
      },
      form: {
        menu_name: "",
        menu_url: "",
        type: "",
        fid: "",
      },
      formRules: {
        menu_name: { required: true, message: "菜单名称必填", trigger: "blur" },
        menu_url: { required: true, message: "菜单URL必填", trigger: "blur" },
      },
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      tableData: [],
    };
  },
  created() {
    this.getMenu();
  },
  methods: {
    add(type, row) {
      this.addType = type;
      this.editType = "add";

      if (type == "levelTwo" && row !== undefined) {
        this.form.fid = row.id;
        this.form.type = 2;
      } else {
        this.form.type = 1;
      }
      this.dialogFormVisible = true;
    },
    edit(type, row) {
      this.idl = row.id;
      this.dialogFormVisible = true;
      this.uuid = row.uuid;
      this.editType = "edit";
      this.addType = type;
      this.form.menu_name = row.menu_name;
      this.form.menu_url = row.menu_url;
      if (type == "levelOne") {
        this.form.fid = "0";
        this.form.type = 1;
      } else {
        this.form.fid = row.fid;
        this.form.type = 2;
      }
    },
    cancel() {
      this.form.menu_name = "";
      this.form.menu_url = "";
      this.form.fid = "";
      this.dialogFormVisible = false;
    },
    getMenu() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthMenu?search_keyword=${this.customerGroupForm.search_keyword}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    submit(form) {
      if (this.addType == "levelOne") {
        this.form.fid = "0";
      } else {
        this.form.fid = this.form.fid;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.editType == "add") {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthMenu`,
            })
              .then(() => {
                this.$message.success("操作成功!");
                this.dialogFormVisible = false;
                this.getMenu();
                for (let i in this.form) {
                  this.form[i] = "";
                }
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.form,
              url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthMenu/${this.idl}`,
            })
              .then(() => {
                this.$message.success("操作成功!");
                this.dialogFormVisible = false;
                this.getMenu();
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    deleted(row) {
      console.log(row);
      this.$confirm("删除后无法恢复，确定删除当前员工吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/AdminAuthMenu/${row.id}`,
          })
            .then(() => {
              this.$message.success("操作成功!");
              this.getMenu();
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    closeDialog(v) {
      this.dialogVisible = v;
    },
  },
};
</script>
<style scoped>
>>> .el-table .el-button {
}
</style>
