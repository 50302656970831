<template>
  <div class="customerImport" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>批量操作</el-breadcrumb-item>
      <el-breadcrumb-item>客户导入</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare">
      <div class="boxLayout search-container">
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="导入日期">
            <el-date-picker
              v-model="customerGroupForm.dataTime"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关键字" class="search_keyword">
            <el-input
              placeholder="请输入内容"
              v-model="customerGroupForm.search_keyword"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getList"
            >查询</el-button
          >
          <el-button size="medium" @click="clear">清空</el-button>
        </div>
      </div>

      <div class="dataTable">
        <div class="text-right">
          <el-upload
            class="upload-demo"
            action="#"
            accept=".xlsx"
            :show-file-list="false"
            :http-request="httpRequest"
            :before-upload="beforeUpload"
          >
            <el-button type="primary">导入客户</el-button>
          </el-upload>
          <a
            href="https://time.api.boyalife.cn/excel/%E5%AE%A2%E6%88%B7%E5%AF%BC%E5%85%A5.xlsx"
            style="color: #409eff; font-size: 12px"
            class="mg-l-20"
            >下载导入模板</a
          >
        </div>
        <el-table :data="tableData.data" style="width: 100%" border>
          <el-table-column
            prop="file.name"
            label="导入文件"
            width="300"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column prop="total_num" label="导入总数" align="center">
          </el-table-column>
          <el-table-column prop="success_num" label="导入成功数" align="center">
          </el-table-column>
          <el-table-column prop="error_num" label="导入失败数" align="center">
          </el-table-column>
          <el-table-column prop="status" label="导入状态" align="center">
            <template slot-scope="scope">
              <span style="color: #2ed477" v-if="scope.row.status == 1"
                >导入成功</span
              >
              <span v-else>导入失败</span>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            prop="create_time"
            label="导入时间"
            sortable
            align="center"
          >
          </el-table-column>
          <el-table-column prop="admin.name" label="操作人" align="center">
          </el-table-column>

          <el-table-column
            label="操作"
            :align="'center'"
            fixed="right"
            width="120"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="
                  downloadExcel(scope.row.file.file_url, scope.row.file.name)
                "
                >下载数据</el-button
              >
              <!-- <a :href="scope.row.file.file_url" :download="scope.row.file.name" style="color: #409EFF;font-size: 12px" class="mg-l-20">下载数据</a> -->
            </template>
          </el-table-column>
        </el-table>

        <pagination
          :total="tableData.total"
          :per_page="tableData.per_page"
          :current_page="tableData.current_page"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "customerImport",
  components: {
    pagination,
  },
  data() {
    return {
      tabs: ["报名审核", "签到记录"],
      currentClass: 0,
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_keyword: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    downloadExcel(url, name) {
      console.log(url);
      let url2 = url.split("://");
      let url3 = url2.join("s://");
      let currentTime = new Date();
      let year = currentTime.getFullYear();
      let month = currentTime.getMonth() + 1;
      let day = currentTime.getDate();
      let formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}`;
      fetch(url3).then((res) => {
        res.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = name + "_" + formattedDate;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });
      // let a = document.createElement("a");
      // a.href = url3;
      // a.download = name;
      // a.style.display = "none";
      // document.body.appendChild(a);
      // a.click();
      // a.remove();
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getList();
    },
    getList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserImport/index?search_keyword=${this.customerGroupForm.search_keyword}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    beforeUpload(file) {
      console.log(file);
      this.load = true;
      this.$message.info("稍等片刻，正在上传...");
      if (file.name.split(".")[1].toLowerCase() != "xlsx") {
        this.$message.error("文件格式错误~");
        return false; // 终止上传
      }
      this.formData = new FormData();
      this.formData.append("file", file);
    },
    httpRequest(data) {
      this.axios({
        method: "post", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.formData,
        url: ` https://time.api.boyalife.cn/v1/cms/user/import`,
      })
        .then((res) => {
          this.$message.success("保存成功！");
          this.load = false;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    addClass(index) {
      this.currentClass = index;
    },
    onCopy() {
      this.$copyText("3333").then(
        (e) => {
          this.$message.success("复制成功!");
        },
        (e) => {
          this.$message.success("复制失败!");
        }
      );
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getList();
    },
  },
};
</script>
<style scoped>
.dataTable {
  box-shadow: none;
  border-radius: 0;
  margin-top: 50px;
}
.dataTable .tabs {
  position: static;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on {
  color: #409eff;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
.upload-demo {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 20px;
}
</style>
