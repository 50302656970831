<template>
  <el-container class="index-con">
    <el-aside class="asideShow">
      <leftnav :allmenu="allmenu"></leftnav>
    </el-aside>
    <el-container>
      <el-header class="index-header text-center">
        <div class="index-main">
          <!-- <el-badge :value="1" class="item  mg-r-20">
            <i class="el-icon-message-solid" style="font-size: 20px;" @click="$router.push('/notifications')"></i>
          </el-badge> -->
          <span class="avatar mg-r-10"
            ><el-avatar
              :size="30"
              :src="
                storage.get('__avatar') == null
                  ? defaultImg
                  : storage.get('__avatar') == null
              "
            ></el-avatar
          ></span>
          <span class="nickName mg-r-20">{{ storage.get("__name") }}</span>
          <div class="quite">
            <el-button type="text" @click="loginOut">退出</el-button>
          </div>
        </div>
      </el-header>
      <div class="content">
        <router-view></router-view>
      </div>
    </el-container>
  </el-container>
</template>
<script>
// 导入组件
import leftnav from "../components/leftnav.vue";
export default {
  name: "index",
  data() {
    return {
      showclass: "asideshow",
      showtype: false,
      allmenu: [],
      defaultImg:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201512%2F06%2F20151206205342_rsM3R.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1691462488&t=369088ef6d16d0369431142f03e254ab"
    };
  },
  // 注册组件
  components: {
    leftnav
  },
  mounted() {},
  methods: {
    loginOut() {
      this.$store
        .dispatch("auth/doLogout")
        .then(() => {
          this.$message.success("退出成功！");
          this.$router.replace({ name: "login" });
        })
        .catch(err => {
          this.$message.error(err.response.data.error.message);
        });
    }
  },
  created() {
    // 监听
    // this.$root.Bus.$on('toggle', value => {
    //   if (value) {
    //     this.showclass = 'asideshow'
    //   } else {
    //     setTimeout(() => {
    //       this.showclass = 'aside'
    //     }, 300)
    //   }
    // })
    this.axios({
        method: 'get',//请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get('__token'),
          "Authorization": `Bearer ${this.storage.get('__token')}`
        },
        // url: `https://time.api.boyalife.cn/v1/cms/AdminMenu`,
        url: `https://time.api.boyalife.cn/v1/cms/AdminAuthMenu`,
      }).then(res => {
        this.allmenu = res.data.result
        const whiteRouter = [
          "/login",
          "/addSeckill",
          "/seckillData",
          "/addFullReduction",
          "/customerInfo",
          "/promoterInfo",
          "/promoterData",
          "/promoterLevelSet",
          "/addAdvertise",
          "/addBanner",
          "/addInformationClassification",
          "/addInformation",
          "/information",
          "/informationData",
          "/addMorningPaper",
          "/massMessage",
          "/readList",
          "/wordList",
          "/cellInfo",
          "/commodityAddAttribute",
          "/inventoryRecords",
          "/addPhysicalCommodity",
          "/addVirtualGoods",
          "/addCard",
          "/virtualInventoryRecords",
          "/addGroup",
          "/addLabel",
          "/labelGroup",
          "/addCustomer",
          "/customerInfo",
          "/addMaterial",
          "/assetAllocation",
          "/assetDetails",
          "/assetsDetailReport",
          "/dynamicTime",
          "/particulars",
          "/sampleDynamics",
          "/storageLocation",
          "/addManagerMaster",
          "/addMemberBenefits",
          "/addMemberLevel",
          "/addDatum",
          "/addLaboratory",
          "/authentication",
          "/list",
          "/activityCategory",
          "/activityData",
          "/activityInfo",
          "/addNewActivity",
          "/attendanceRecord",
          "/addCoupon",
          "/couponData",
          "/transferRecord",
          "/addMedal",
          "/medalCategory",
          "/detailsOfStoredValue",
          "/setExchangeRate",
          "/afterSaleManageInfo",
          "/orderInfo",
          "/addFreightTemplate",
          "/projectOrderInfo",
          "/addReturnAddress",
          "/serviceOrderInfo",
          "/virtualOrderInfo",
          "/addAttribute",
          "/addProject",
          "/promoterData",
          "/promoterInfo",
          "/promoterLevelSet",
          "/addStore",
          "/addSupplier",
          "/addDepartment",
          "/addMenu",
          "/laboratoryConfig",
          "/addMessageConfig",
          "/editProtocol",
          "/addRole",
          "/addSignature",
          "/smsConfiguration",
          "/builtNewTemplateDialog",
          "/smsPushDialog",
          "/addAccount",
          "/addGift",
          "/addHonors",
          "/addRecommonPosition"
        ];
        this.allmenu.map(item => {
          if (item.children.length < 1) {
            whiteRouter.push(item.menu_url);
          } else {
            item.children.map(jtem => {
              whiteRouter.push(jtem.menu_url);
            });
          }
        });

        if (whiteRouter.includes(this.$route.path)) {
          // console.log(window.location.href)
          this.$router.replace(this.$route.fullPath);
        } else {
          // this.$message.error('您暂无权限进入该页面')
          setTimeout(() => {
            this.$router.replace({
              name: res.data.result[0].menu_url.split("/")[1]
            });
          }, 500);
        }
      })
      .catch(err => {
        this.$message.error(err);
      });
  },

  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  beforeMount() {
    // 弹出登录成功
    // this.$message({
    //   message: '登录成功',
    //   type: 'success'
    // })
  }
};
</script>
<style scoped>
.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.aside {
  width: 64px !important;
  height: 100%;
  background-color: #334157;
  margin: 0px;
}
.index-con >>> .el-aside {
  height: 100vh;
  overflow: auto;
  background-color: #000c17;
  margin: 0px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;
}
.index-main {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 60px;
}
.asideShow {
  width: 250px !important;
}
.avatar {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgb(185, 185, 185);
  text-align: center;
  line-height: 30px;
}
.index-header {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 1245px;
  margin-left: 250px;
}
.index-header .quite {
  display: inline-block;
}
.index-header .quite button {
  color: #333;
  font-size: 12px;
}
.content {
  margin-left: 250px;
  background: #f2f2f2;
  min-width: 1245px;
}
.content > div {
  padding: 10px;
}
.el-icon-message-solid {
  cursor: pointer;
}
</style>
