<template>
  <div class="transactionFlow" style="background: #f0f2f5">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务</el-breadcrumb-item>
      <el-breadcrumb-item>财务流水</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 商城订单 项目订单 快捷操作 会员数据概况 -->
    <div class="topSquare boxLayout" v-if="Object.keys(statistics).length > 0">
      <h3 class="title-t mg-b-30">交易统计</h3>
      <div class="analysis flex space-around">
        <div class="text-center">
          <h6 class="font-12 color-999">销售总额</h6>
          <p class="font-30">{{ statistics.total_sales || 0 }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">有效订单数</h6>
          <p class="font-30">{{ statistics.valid_order_amount ||0 }}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">有效订单总额</h6>
          <p class="font-30">{{ statistics.valid_order_number || 0}}</p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">结算中订单数</h6>
          <p class="font-30">
          {{ statistics.settlement_order_amount || 0}}
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">结算中订单总额</h6>
          <p class="font-30">
           {{ statistics.settlement_order_number || 0 }}
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">推广订单数</h6>
          <p class="font-30">
           {{ statistics.extension_order_amount || 0 }}
          </p>
        </div>
        <div class="text-center">
          <h6 class="font-12 color-999">推广订单总额</h6>
          <p class="font-30">
            <span class="font-12">￥</span
            >{{ statistics.extension_order_number || 0 }}
          </p>
        </div>
      </div>
    </div>
    <div class="boxLayout mg-t-20">
      <div class="title">
        <h3 class="title-t mg-b-30">收支明细</h3>
        <el-form
          :inline="true"
          :model="customerGroupForm"
          class="demo-form-inline"
        >
          <el-form-item label="创建日期">
            <el-date-picker
              v-model="customerGroupForm.dataTime"
              type="daterange"
              align="right"
              unlink-panels
              @change="getTimes"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy/MM/dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="交易金额">
            <rangeInput
              @input="rangeNumber"
              :placeholderMin="'最低金额'"
              :placeholderMax="'最高金额'"
            />
          </el-form-item>
        </el-form>
        <div class="btn mg-t-10">
          <el-button type="primary" size="medium" @click="getInfo"
            >查询</el-button
          >
          <el-button size="medium" @click="clear">清空</el-button>
          <el-button size="medium" @click="exportExcel">导出</el-button>
        </div>
        <el-table
          class="mg-t-20"
          ref="multipleTable"
          :data="tableData.data"
          border
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            label="交易时间"
            prop="update_time"
            :align="'center'"
          >
          </el-table-column>
          <el-table-column
            label="收支类型"
            :align="'center'"
            prop="in_out_type"
            width="160"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">收入</span>
              <span v-else>支出</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="money"
            label="交易金额（元）"
            :align="'center'"
            sortable
          >
            <template slot-scope="scope">
              <span style="color: #2ed477">+{{ scope.row.money }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="账单类型" :align="'center'">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">订单收入</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="detail"
            label="交易明细"
            :align="'center'"
            show-overflow-tooltip
            width="300"
          >
          </el-table-column>
          <el-table-column
            prop="order_sn"
            label="订单编号"
            :align="'center'"
            width="150"
          >
          </el-table-column>
        </el-table>
        <pagination
          :total="tableData.total"
          :per_page="tableData.per_page"
          :current_page="tableData.current_page"
          @handleCurrentChange="handleCurrentChange"
          @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import rangeInput from "../../../components/rangeInput.vue";
import pagination from "../../../components/pagination.vue";
export default {
  name: "transactionFlow",
  components: { rangeInput, pagination },
  data() {
    return {
      statistics: {},
      customerGroupForm: {
        search_start_time: "",
        search_end_time: "",
        search_start_money: "",
        search_end_money: "",
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              console.log(start.getTime());
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getInfo();
    this.getStatistics();
  },
  methods: {
    rangeNumber(v) {
      this.customerGroupForm.search_start_money = v.min;
      this.customerGroupForm.search_end_money = v.max;
    },
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    clear() {
      this.customerGroupForm = {
        search_start_time: "",
        search_end_time: "",
        search_start_money: "",
        search_end_money: "",
      };
      this.getInfo();
    },
    // 导出
    exportExcel() {
      this.axios({
        method: "post", //请求方法
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Transaction/export?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_start_money=${this.customerGroupForm.search_start_money}&search_end_money=${this.customerGroupForm.search_end_money}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((response) => {
          const blob = new Blob([response.data]);
          // 创建a标签进行下载
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          let formattedDate = `${year}/${month
            .toString()
            .padStart(2, "0")}/${day.toString().padStart(2, "0")}`;

          link.download = `交易流水记录 - ${formattedDate}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$message.success("导出成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Transaction?search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}&search_start_money=${this.customerGroupForm.search_start_money}&search_end_money=${this.customerGroupForm.search_end_money}&page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getStatistics() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Transaction/statistics/1`,
      })
        .then((res) => {
          console.log(res);
          this.statistics = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getStatistics();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getStatistics();
    },
  },
};
</script>
