<template>
  <div class="bannerList">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item>banner</el-breadcrumb-item>
      <el-breadcrumb-item>banner列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-container">
      <el-form
        :inline="true"
        :model="customerGroupForm"
        class="demo-form-inline"
      >
        <el-form-item label="位置类型">
          <el-select
            v-model="customerGroupForm.search_position"
            placeholder="请选择"
          >
            <el-option
              v-for="item in positionList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="customerGroupForm.dataTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getTimes"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="关键字" class="keyword">
          <el-input
            placeholder="请输入内容"
            v-model="customerGroupForm.search_keyword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn mg-t-10">
        <el-button type="primary" size="medium" @click="getBanner(status)"
          >查询</el-button
        >
        <el-button size="medium" @click="clearForm">清空</el-button>
      </div>
    </div>
    <div class="dataTable">
      <div class="tabs flex">
        <p
          v-for="(item, index) in tabs"
          :key="index"
          @click="addClass(index)"
          :class="{ on: currentClass === index }"
        >
          {{ item }}
        </p>
      </div>
      <div class="text-right mg-b-20">
        <el-button type="primary" @click="$router.push('/addBanner')"
          >添加Banner</el-button
        >
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="banner图" :align="'center'">
          <template slot-scope="scope">
            <div class="product">
              <el-image
                class="bannerImg"
                fit="scale-down "
                :src="scope.row.image"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="标题"
          :align="'center'"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="url_type"
          label="跳转类型"
          :align="'center'"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.url_type == 1"> 链接</span>
            <span class="text" v-else-if="scope.row.url_type == 2"
              >富文本
            </span>
            <span v-else-if="scope.row.url_type == 3"> 小程序页面</span>
            <span v-else-if="scope.row.url_type == 4"> 视频</span>
            <span v-else-if="scope.row.url_type == 5"> 商品</span>
            <span v-else-if="scope.row.url_type == 6"> 项目</span>
            <span v-else-if="scope.row.url_type == 7"> 客服</span>
            <span v-else-if="scope.row.url_type == 8"> 秒杀</span>
            <span v-else-if="scope.row.url_type == 9"> 外部小程序</span>
            <span v-else-if="scope.row.url_type == 10"> 资讯</span>
            <span v-else-if="scope.row.url_type == 11"> 专题</span>

            <span v-else>项目</span>
          </template>
        </el-table-column>
        <el-table-column prop="position_name" label="位置" :align="'center'">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column
          label="操作"
          :align="'center'"
          width="200"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="changeStatus(scope.row, 'down')"
              >下架</el-button
            >
            <el-button type="text" v-else @click="changeStatus(scope.row, 'up')"
              >上架</el-button
            >
            <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteBanner(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
    </div>
  </div>
</template>
<script>
import pagination from "../../../components/pagination.vue";
export default {
  name: "bannerList",
  components: {
    pagination,
  },
  data() {
    return {
      tabs: ["上架", "下架"],
      currentClass: 0,
      status: 1,
      // 免责声明
      disclaimers: "",
      customerGroupForm: {
        search_keyword: "",
        search_start_time: "",
        search_end_time: "",
        search_position: "",
        search_status: 1,
      },
      positionList: [
        {
          label: "首页顶部轮播",
          value: 1,
        },
        {
          label: "首页热门项目",
          value: 2,
        },
        {
          label: "首页活动推荐",
          value: 3,
        },
        {
          label: "项目商城轮播",
          value: 4,
        },
        {
          label: "积分商城轮播",
          value: 5,
        },
        {
          label: "新人卡券banner",
          value: 6,
        },
        {
          label: "广告弹窗",
          value: 7,
        },
        {
          label: "商城资源位",
          value: 8,
        },
        {
          label: "商城弹窗",
          value: 9,
        },
        {
          label: "支付成功资源位",
          value: 10,
        },
        {
          label: "每日签到资源位",
          value: 11,
        },
        {
          label: "推广员介绍页",
          value: 12,
        },
        {
          label: "服务流程介绍资源位",
          value: 13,
        },
        {
          label: "任务中心资源位",
          value: 14,
        },
        {
          label: "邀请好友资源",
          value: 15,
        },
        {
          label: "30秒了解资产配置",
          value: 16,
        },
        {
          label: "了解细胞配置",
          value: 17,
        },
        {
          label: "免疫细胞优惠",
          value: 18,
        },
        {
          label: "大家都在看",
          value: 19,
        },
      ],
      labelOptions: [
        {
          label: "启用",
          value: "ok",
        },
        {
          label: "禁用",
          value: "no",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 1 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 7 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                start.getTime() -
                  3600 * 1000 * 24 * 30 -
                  start.getHours() * 60 * 60 * 1000 -
                  start.getMinutes() * 60 * 1000 -
                  start.getSeconds() * 1000 -
                  start.getMilliseconds()
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
    };
  },
  created() {
    this.getBanner(this.status);
  },
  methods: {
    // 选择时间
    getTimes(v) {
      this.customerGroupForm.search_start_time = v[0] + " 00:00:00";
      this.customerGroupForm.search_end_time = v[1] + " 23:59:59";
    },
    addClass(index) {
      this.currentClass = index;
      this.customerGroupForm.search_status = index + 1;
      this.getBanner(this.status);
    },
    clearForm() {
      for (let i in this.customerGroupForm) {
        this.customerGroupForm[i] = "";
      }
      this.getBanner();
    },
    // 编辑跳转
    toEdit(row) {
      row.group = JSON.stringify(row.group);
      this.$router.push({
        name: "addBanner",
        query: { ...row },
      });
    },
    // 列表
    getBanner(status) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Banner?search_status=${this.customerGroupForm.search_status}&page_size=${this.tableData.per_page}&page_index=${this.tableData.current_page}&search_keyword=${this.customerGroupForm.search_keyword}&search_position=${this.customerGroupForm.search_position}&search_start_time=${this.customerGroupForm.search_start_time}&search_end_time=${this.customerGroupForm.search_end_time}`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 上下架
    changeStatus(row, str) {
      this.status = str === "down" ? 2 : 1;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status: this.status,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Banner/status/${row.uuid}`,
      })
        .then((res) => {
          let msg = str === "down" ? "下架成功" : "上架成功";
          this.$message.success(msg);
          let status2 = str === "down" ? 1 : 2;

          this.getBanner(status2);
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    // 删除BANNER
    deleteBanner(row) {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/Banner/${row.uuid}`,
          })
            .then((res) => {
              this.$message.success("删除成功！");
              this.getBanner(this.currentClass + 1);
            })
            .catch((err) => {
              this.$message.success(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getBanner();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getBanner();
    },
  },
};
</script>
<style scoped>
.customerGroupList {
  background: #f2f2f2;
}
.search-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 12px !important;
}
.bannerImg {
  width: 100%;
  height: 90px;
}
>>> .el-table .el-image {
  width: 100% !important;
  height: 90px !important;
}
>>> .el-input-group {
  width: 400px;
}
.el-table .el-image {
  width: auto;
  height: auto;
}
.bannerList .el-table .product .el-image {
  border-radius: 0 !important;
}
>>> .el-button--text {
  padding: 5px 0px;
}
>>> .el-button--text span {
  font-size: 12px;
}
>>> .el-dialog__body {
  padding: 20px;
}
>>> .el-table .el-button {
  width: 40px;
}
.dataTable {
  position: relative;
}
.dataTable .tabs {
  position: absolute;
  top: 35px;
  left: 20px;
}
.dataTable .tabs p {
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}
.dataTable .tabs p:after {
  content: "";
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
}
.dataTable .tabs p.on:after {
  background: #409eff;
}
>>> .text {
  line-height: 50px;
  max-height: 50px;
  width: 100%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
>>> .text img {
  max-width: 50px;
}
</style>
