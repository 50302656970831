<template>
  <el-dialog
    title="新建模板"
    :visible.sync="visible"
    width="30%"
    @closed="handleClose"
  >
    <el-form
      ref="form"
      class="mg-t-20"
      :model="basicInfoForm"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="模板名称" prop="TemplateName">
        <el-input
          v-model="basicInfoForm.TemplateName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板内容" prop="arr">
        <div class="box">
          <div class="addNew" @click="add">
            <i class="el-icon-circle-plus-outline"></i> 插入变量
          </div>
          <el-input
            v-for="(item, index) in basicInfoForm.arr"
            :key="index"
            v-model="item.value"
          ></el-input>
        </div>
        <p class="font-12 color-999">
          0-500字，70个字/条，超出70字时按67字/条计费
        </p>
        <p class="bg font-12 color-999">
          教育、医疗、酒类、引导加微信短信容易被运营商屏蔽…
          <el-button size="mini" type="text">了解更多屏蔽规则</el-button>
        </p>
      </el-form-item>
      <el-form-item label="申请说明" prop="Remark">
        <el-input
          type="textarea"
          v-model="basicInfoForm.Remark"
          maxlength="100"
          placeholder="请输入短信模板申请说明"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板类型" prop="TemplateType">
        <el-radio-group v-model="basicInfoForm.TemplateType">
          <el-radio :label="0">验证码</el-radio>
          <el-radio :label="1">短信通知</el-radio>
          <el-radio :label="2">推广短信</el-radio>
          <el-radio :label="3">国际/港澳台消息</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit('form')">提交审核</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "builtNewTemplateDialog",
  props: {
    builtNewTemplateDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    builtNewTemplateDialogVisible(n) {
      if (!n) {
        this.active = 0;
      }
      this.visible = n;
    },
  },
  data() {
    return {
      visible: false,
      basicInfoForm: {
        TemplateName: "",
        arr: [],
        Remark: "",
        TemplateContent: "",
        TemplateType: 0,
      },
      rules: {
        TemplateName: { required: true, message: "请输入", trigger: "blur" },
        Remark: { required: true, message: "请输入", trigger: "blur" },
        arr: { required: true, message: "请插入比啊变量", trigger: "blur" },
        TemplateType: { required: true, message: "请选择", trigger: "change" },
      },
    };
  },
  created() {
    console.log(this.basicInfoForm);
  },
  methods: {
    handleClose() {
      this.visible = false;
      this.$emit("closeDialog", this.visible);
    },
    add() {
      let v = {
        value: "${变量名}",
      };
      this.basicInfoForm.arr.push(v);
    },
    submit(form) {
      this.basicInfoForm.TemplateContent = JSON.parse(
        JSON.stringify(
          this.basicInfoForm.arr.map((item) => item.value).join(",")
        )
      );
      delete this.basicInfoForm.arr;
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.axios({
            method: "post", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: this.basicInfoForm,
            url: ` https://time.api.boyalife.cn/v1/cms/SmsTemplate`,
          })
            .then((res) => {
              this.$message.success("新增成功！");
              this.visible = false;
              this.basicInfoForm.TemplateContent = "";
              this.basicInfoForm.arr = [];
            })
            .catch((err) => {
              this.visible = false;
              this.$message.error(err.response.data.error.message);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
>>> .el-dialog {
  min-width: 600px;
}
>>> .el-form {
  width: 100% !important;
}
>>> .el-form-item {
  margin-bottom: 20px !important;
}
.box {
  border: 1px solid rgb(222, 222, 222);
  min-height: 200px;
}
.box .addNew {
  padding: 5px;
  background: rgb(217, 246, 253);
  cursor: pointer;
}
.bg {
  background: rgb(217, 246, 253);
}
.bg .el-button {
  display: inline-block;
}
.bg >>> .el-button span {
  font-size: 12px;
}
.arial {
  margin-bottom: 10px;
}
.arial p:first-child {
  width: 150px;
}
.arial p:last-child {
  width: 200px;
}
.msg {
  width: 300px;
  margin: 0 auto;
}
.msg span {
  width: 120px;
  display: inline-block;
  text-align: right;
}
</style>
