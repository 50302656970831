<template>
  <!-- 添加客户 -->
  <div class="addCustomer">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>客户</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/customerList' }"
        >全部客户</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ pageType }}客户</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicInfo">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="头像">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="acceptType"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
            v-loading="loading"
          >
          <img
              v-if="basicInfoForm.avatar !== ''"
              :src="basicInfoForm.avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
        
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
        </el-form-item>
        <el-form-item label="昵称" prop="nick_name">
          <el-input
            v-model="basicInfoForm.nick_name"
            placeholder="请选输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            v-model="basicInfoForm.mobile"
            placeholder="请选输入"
          ></el-input>
        </el-form-item>
        <el-form-item label=" 生日">
          <el-date-picker
            v-model="basicInfoForm.birthday"
            type="date"
            @change="getDate"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input
            v-model="basicInfoForm.age"
            placeholder="请选输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="basicInfoForm.sex" placeholder="请选择">
            <el-option
              v-for="jtem in sexList"
              :key="jtem.uuid"
              :label="jtem.name"
              :value="jtem.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="职业">
          <el-select v-model="basicInfoForm.job" placeholder="请选择">
            <el-option label="老师" value="shanghai"></el-option>
            <el-option label="工程师" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="所在地区" prop="province_id">
          <el-cascader
            :options="cityList"
            v-model="address"
            @change="getCityValue"
            :props="props"
            collapse-tags
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="标签">
          <el-tag v-for="tag in tags" :key="tag.uuid" :type="tag.type">
            {{ tag.name }}
          </el-tag>
          <span
            style="color: cornflowerblue; cursor: pointer"
            @click="labelDialogVisible = true"
          >
            <span v-if="tags.length > 0">+ 编辑标签</span>
            <span v-else>+ 添加标签</span>
          </span>
        </el-form-item>
        <el-form-item label="客户等级">
          <el-select v-model="basicInfoForm.level" placeholder="请选择">
            <el-option
              v-for="(jtem, i) in levelList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="兴趣爱好">
          <el-select v-model="basicInfoForm.interest" placeholder="请选择">
            <el-option label="爱好" value="shanghai"></el-option>
            <el-option label="爱好" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产检医院">
          <el-input
            v-model="basicInfoForm.antenatal_hospital"
            placeholder="请选输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="预产期">
          <el-date-picker
            v-model="basicInfoForm.confinement_date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="身份">
          <el-select v-model="basicInfoForm.user_role" placeholder="请选择">
            <el-option label="游客" :value="1"></el-option>
            <el-option label="会员" :value="2"></el-option>
            <el-option label="推广员" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属销售">
          <el-select v-model="basicInfoForm.manager_uuid" placeholder="请选择">
            <el-option
              v-for="(jtem, i) in saleList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <!-- <MaterialLibrary :type="imgType" @selectImg="selectImg" /> -->
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"/>
    </el-dialog>
    <el-dialog
      title="选择标签"
      :visible.sync="labelDialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <markLabel
        @closeDialog="closeDialog"
        :tags="tags"
        :type="'addCustomer'"
      />
    </el-dialog>
  </div>
</template>
<script>
import markLabel from "../../../components/markLabel.vue";
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addCustomer",
  components: {
    MaterialLibrary,
    markLabel,
    imageDialog
  },
  data() {
    return {
      imgType: "",
      pageType: "",
      tags: [],
      cityList: [],
      levelList: [],
      saleList: [],
      sexList: [],
      formData: '',
      props: {
        label: "area_name",
        value: "area_id",
      },
      address: [],
      labelDialogVisible: false,
      dialogVisible: false,
      basicInfoForm: {
        avatar: "",
        nick_name: "",
        mobile: "",
        sex: "",
        age: "",
        level: "",
        user_label: [],
        birthday: "",
        job: "",
        antenatal_hospital: "",
        province_id: "",
        city_id: "",
        area_id: "",
        interest: "",
        confinement_date: "",
        user_role: "",
        manager_uuid: "",
      },
      rules: {
        nick_name: { required: true, message: "请输入昵称", trigger: "blur" },
        mobile: { required: true, message: "请输入手机号", trigger: "blur" },
        province_id: {
          required: true,
          message: "请选择地址",
          trigger: "change",
        },
      },
    };
  },
  created() {
    this.getCity();
    this.getSale();
    this.getLevel();
    this.getSex();
    if (Object.keys(this.$route.query).length !== 0) {
      this.getInfo();
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
    getDate(v) {
      console.log(v);
    },
    getSex() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: this.basicInfoForm,
        url: ` https://time.api.boyalife.cn/v1/cms/UserProfile/ade2d2b666f75232466890e075e26616`,
      }).then((res) => {
        let params = res.data.result;
        this.sexList = params.option;
      });
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/User/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          console.log(params);

          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          this.address[0] = this.basicInfoForm.province.area_id;
          this.address[1] = this.basicInfoForm.city.area_id;
          this.address[2] = this.basicInfoForm.area.area_id;
          this.basicInfoForm.birthday =
            new Date(this.basicInfoForm.birthday) || "";
          this.tags = this.basicInfoForm.label;
          this.basicInfoForm.user_role = Number(this.basicInfoForm.user_role);
          this.basicInfoForm.level = this.basicInfoForm.level.uuid;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getCityValue(v) {
      this.basicInfoForm.province_id = v[0];
      this.basicInfoForm.city_id = v[1];
      this.basicInfoForm.area_id = v[2];
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    confirmImageDialog(item) {
      this.basicInfoForm.avatar = item[0].url;
      this.dialogVisible = false;
    },
    closeDialog(v) {
      this.tags = JSON.parse(JSON.stringify(v));
      this.labelDialogVisible = false;
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          console.log(res);
          this.basicInfoForm.avatar =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    submit(formName) {
      if (this.tags.length > 0) {
        this.basicInfoForm.label = this.tags.map((item) => {
          return item.uuid;
        });
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.query.uuid) {
            delete this.basicInfoForm.area;
            delete this.basicInfoForm.city;
            delete this.basicInfoForm.uuid;
            delete this.basicInfoForm.province;
            delete this.basicInfoForm.create_time;
            delete this.basicInfoForm.update_time;

            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/User/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/User`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          }
        } else {
          return false;
        }
      });
    },
    // 销售
    getLevel() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserLevelSelect`,
      })
        .then((res) => {
          this.levelList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 销售
    getSale() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ExtendSelect`,
      })
        .then((res) => {
          this.saleList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 地区
    getCity() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Region`,
      })
        .then((res) => {
          this.cityList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    handleChange(v) {},
  },
};
</script>
<style scoped>
.addCustomer {
  background: #f2f2f2;
  margin-bottom: 80px;
}
.basicInfo {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form {
  width: 400px;
}
.el-tag {
  margin-right: 10px;
  margin-bottom: 5px;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

</style>
